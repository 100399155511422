<nav *ngIf="hasCurrentGame" fxLayout.gt-sm='row wrap' fxLayoutAlign.gt-sm='center center' fxLayout='column'
     fxLayoutGap='10px'>
  <!-- Leaderboard -->
  <app-leaderboard
          *ngIf='previewLeaderboard && !currentGameMetadata?.servicesEnabled'
          [revealed]="leaderboardRevealed"
          [showLeaderboard]="revealLeaderboard"
          [hideLeaderboard]="hideLeaderboard"
          [hideLeaderboardPreview]="toggleLeaderboardPreview"
  ></app-leaderboard>
  <app-leaderboard-service
          *ngIf='previewLeaderboard && currentGameMetadata?.servicesEnabled'
          [revealed]="leaderboardRevealed"
          [showLeaderboard]="revealLeaderboard"
          [hideLeaderboard]="hideLeaderboard"
          [hideLeaderboardPreview]="toggleLeaderboardPreview">
  </app-leaderboard-service>
  <!-- If showtime -->

  <!-- Otherwise show admin section -->
  <div *ngIf='!previewLeaderboard'>
    <mat-card>
      <mat-card-title>{{currentGameMetadata.name}}</mat-card-title>
      <mat-card-subtitle>
        <!-- Game Status -->
        <mat-chip-list>
          <mat-chip>PIN:{{currentGameMetadata.pin.toUpperCase()}}</mat-chip>
          <mat-chip *ngIf='currentGameState.blindtestControl.status === 0' i18n="@@game-gameStatusReady">Prêt</mat-chip>
          <mat-chip *ngIf="!playerCount"><span i18n="@@game-noPlayer">0 Joueurs</span></mat-chip>
          <mat-chip *ngIf="playerCount && playerCount != 1">{{playerCount}} <span i18n="@@game-playerCount">Joueurs</span></mat-chip>
          <mat-chip *ngIf="playerCount === 1"><span i18n="@@game-onePlayer">1 Joueur</span></mat-chip>
          <mat-chip color="primary" *ngIf='currentGameState.blindtestControl.status === 1' i18n="@@game-gameStatusOnGoing">En cours</mat-chip>
          <mat-chip color="accent" *ngIf='currentGameState.blindtestControl.status === 2' i18n="@@game-gameStatusOnPaused">En pause</mat-chip>
          <mat-chip color="accent" *ngIf='currentGameState.blindtestControl.isRunning()'>
            <span i18n="@@game-gameIsRunning">Manche</span>&nbsp;
            {{currentGameState.blindtestControl.current + 1}} / {{currentGameState.blindtestControl.setCount}}
          </mat-chip>
          <mat-chip *ngIf='currentGameState.blindtestControl.status === 3' i18n="@@game-gameStatusFinish">Terminé</mat-chip>
        </mat-chip-list>
      </mat-card-subtitle>
      <mat-card-content>
        <!-- Game Controls when game is Ready -->
        <button mat-raised-button color="accent" *ngIf='currentGameState.blindtestControl.status === 0'
                (click)="startGame()" i18n="@@game-startingGame">Lancer le Jeu
        </button>
        <!-- Game Controls when game is OnGoing -->
        <button mat-raised-button color="primary" *ngIf='currentGameState.blindtestControl.isRunning()'
                routerLink="bonus" i18n="@@game-gameBonus">Bonus
        </button>
        <button mat-raised-button color="primary" *ngIf='currentGameState.blindtestControl.isRunning()'
                routerLink="malus" i18n="@@game-gameMalus">Malus
        </button>
        <button mat-raised-button color="primary" *ngIf='currentGameState.blindtestControl.isRunning() && hasJokersInGame' (click)="toggleJokers()">
          <span *ngIf="!jokersEnabled" i18n="@@game-activateJockers">Activ.</span>
          <span *ngIf="jokersEnabled" i18n="@@game-disableJockers">Désact.</span>
          <span i18n="@@game-gameJokers">Jokers</span>
        </button>
        <button mat-raised-button *ngIf="currentShowtimeSettings?.metadata?.active" color="primary" (click)="toggleShowtimeControl()">
          <span *ngIf="!controlsEnabled" i18n="@@game-enableControls">Activ.</span>
          <span *ngIf="controlsEnabled" i18n="@@game-disableControls">Désact.</span>
          <span i18n="@@game-controlRégie">Ctrl Régie</span>
        </button>
        <button mat-raised-button color="primary" *ngIf='currentGameState.blindtestControl.status > 0' (click)="toggleLeaderboardPreview()" i18n="@@game-leaderboard">
          Scores
        </button>
        <br/>
        <button mat-raised-button color="warn" *ngIf='currentGameState.blindtestControl.isRunning()' (click)="unsetGame()" i18n="@@game-cancelGame">
          Annuler la partie
        </button>
        <button mat-raised-button color="accent" *ngIf='currentGameState.blindtestControl.isRunning()'
                (click)="resetSong()" i18n="@@game-resetGame">Réinitialiser
        </button>
      </mat-card-content>
    </mat-card>
    <app-showtime-controls *ngIf="currentShowtimeSettings?.metadata?.active && controlsEnabled" [currentGameMetadata]="currentGameMetadata"
                           [currentShowtimeSettings]="currentShowtimeSettings" [currentGameControls]="currentGameState"></app-showtime-controls>
    <mat-card *ngIf="currentGameState.setControl && currentGameState.sectionControl && !isGameFinished()">
      <mat-card-title> <span i18n="@@game-setNumber">Manche </span>&nbsp;{{currentGameState.blindtestControl.current + 1}} - {{currentGameState.setControl.songCount}}&nbsp;<span i18n="@@game-songssetNumber">chansons</span></mat-card-title>
      <mat-card-subtitle>
        <mat-chip-list>
          <mat-chip color="primary" *ngIf="currentGameState.setControl.status === 0" i18n="@@game-gameSetStatusReady">Prêt</mat-chip>
          <mat-chip color="accent" *ngIf="currentGameState.setControl.isRunning()" i18n="@@game-setStatusOnGoing">Jeu en cours</mat-chip>
          <mat-chip color="accent" *ngIf="currentGameState.setControl.isRunning()">
            <span i18n="@@game-setRunning">Chanson</span>&nbsp;{{currentGameState.setControl.current + 1}} /
            {{currentGameState.setControl.songCount}}
          </mat-chip>
          <mat-chip *ngIf="currentGameState.setControl.status === 3" i18n="@@game-setControlStatusFinish">Terminé</mat-chip>
        </mat-chip-list>
      </mat-card-subtitle>
      <mat-card-content>
        <h3> <span i18n="@@game-theme">Thème:</span> {{getCurrentSection().label}}</h3>
        <h3 *ngIf="isLastSongOfSection() && !isLastSongOfSet()" class="danger" i18n="@@game-lastThemeSong">C'est la dernière chanson du thème</h3>
        <h3 *ngIf="isLastSongOfSet()" class="danger" i18n="@@game-lastSongOfTheRound">C'est la dernière chanson de la manche</h3>
        <div *ngIf='currentGameState.blindtestControl.isRunning()'>
          <button
            mat-raised-button
            color="accent"
            *ngIf="currentGameState.setControl.status === 0"
            (click)="startSet(currentGameState.blindtestControl.current)">
            <span i18n="@@game-launchSet">Lancer le jeu</span>
          </button>
          <h3 *ngIf="currentSong">{{currentSong.artist}} - {{currentSong.title}}</h3>
          <h4 *ngIf="currentSong && getCurrentSection().type === 1">{{currentSong.lyrics}}</h4>
          <h4><span i18n="@@game-submissionCount">Réponses reçues :</span> {{getSubmissionCount()}}</h4>
          <h4><span i18n="@@game-submissionReceived">Réponses en traitement : </span> {{getRemainingItemsToReview}}</h4>
          <h4><span i18n="@@game-submissionToCorrect">Réponses à corriger : </span> {{getUnsolvedPendingReviewCount()}}</h4>
          <button mat-raised-button color="accent" *ngIf="shouldShowControls()" (click)="lockSong()" [disabled]="shouldDisableLock()" i18n="@@game-lockingSong">Verrouiller</button>
          <button mat-raised-button color="accent" *ngIf="shouldShowControls()" (click)="revealSong()"
                  [disabled]="shouldDisableReveal()" i18n="@@game-revealResult">Révéler</button>
          <button mat-raised-button color="accent" *ngIf="shouldShowControls() && !isLastSongOfGame()" (click)="nextSong()"
                  [disabled]="shouldDisableNext()" i18n="@@game-nextSong">Suivant</button>
          <button mat-raised-button color="primary"
                  [disabled]="shouldDisableNext()"
                  *ngIf='shouldShowControls() && isLastSongOfGame() && (currentGameState.blindtestControl.status > 0)' (click)="toggleLeaderboardPreview()" i18n="@@game-showScores">
            Afficher les scores
          </button>
          <button mat-raised-button color="accent" *ngIf="shouldShowControls() && isLastSongOfGame() && hasFeedbackForm()" (click)="finishGame()"
                  [disabled]="shouldDisableNext()" i18n="@@game-finishGameLaunchSurvey">Lancer Sondage & Terminer</button>
          <button mat-raised-button color="accent" *ngIf="shouldShowControls() && isLastSongOfGame() && !hasFeedbackForm()" (click)="finishGame()"
                  [disabled]="shouldDisableNext()" i18n="@@game-finishGame">Terminer le jeu</button>
          <!-- Fin de manche -->
          <button mat-raised-button color="primary"
                  *ngIf='currentGameState.setControl.status === 3 && currentGameState.blindtestControl.setCount > (currentGameState.blindtestControl.current + 1)' (click)="toggleLeaderboardPreview()" i18n="@@game-showScores">
            Afficher les scores
          </button>
          <button mat-raised-button color="accent"
                  *ngIf="currentGameState.setControl.status === 3 && currentGameState.blindtestControl.setCount > (currentGameState.blindtestControl.current + 1)"
                  (click)="startSet(currentGameState.blindtestControl.current + 1)" i18n="@@game-nextRound">Manche Suivante
          </button>
        </div>
      </mat-card-content>
    </mat-card>
    <app-review-widget [currentSong]="currentSong"></app-review-widget>
    <app-admin-stats-widget *ngIf="shouldDisplayStats()"></app-admin-stats-widget>
    <mat-card *ngIf="isGameFinished()">
      <button mat-raised-button color="primary" (click)="exportScoresheet()" i18n="@@game-exportScoresheet">Exporter les Scores</button>
    </mat-card>
  </div>
</nav>
