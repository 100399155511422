<div *ngIf="blindtest.sets" (click)="toggleWrap()" class="ptr">
    <div *ngFor="let set of blindtest.sets; let i = index">
        <div class="app" *ngIf="!expanded"> + <ng-container i18n="@@blindtestDetailsSets-indexNumber">Set N°</ng-container> {{i + 1}}</div>
        <div class="app" *ngIf="expanded"> - <ng-container i18n="@@blindtestDetailsSets-indexNumber">Set N°</ng-container> {{i + 1}}</div>
        <div class="roles" *ngIf="expanded">
            <div *ngFor="let section of set.sections">
                <span class="section-lbl">{{section.label}}</span>
                <span class="section-size">{{section.endIndex - section.startIndex}} <span
                        i18n="@@blindtestWidget-setLabelSongs">chansons</span></span>
            </div>
        </div>
    </div>
</div>
