import {BlindtestImportDescriptor} from '@frogconnexion/blinding-common';


export class ImportFileProcess {
  loading: boolean;
  valid: boolean;
  uploading: boolean;
  done: boolean;
  file: File;
  index: number;
  content: BlindtestImportDescriptor;
  errors: {
    wrongFileType?: any,
    wrongSchema?: any,
    unknownError?: any,
    apiError?: any
  };

  constructor(file: File, index: number) {
    this.file = file;
    this.index = index;
    this.errors = {
    };
  }
}
