import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {path: 'org/:organization/library', loadChildren: () => import ('./library.module').then(m => m.LibraryModule)},
];

@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class LibraryRoutingModule { }
