import {LibraryFilter} from "../service/library-datasource";

export class SearchState {
  filter: LibraryFilter;
  totalCount: number;
  fetchingInitialData: boolean;
  fetchingAdditionalData: boolean;

  constructor(filter: LibraryFilter = null, totalCount: number = -1, fetchingInitialData: boolean = false, fetchingAdditionalData: boolean = false) {
    this.filter = filter;
    this.totalCount = totalCount;
    this.fetchingInitialData = fetchingInitialData;
    this.fetchingAdditionalData = fetchingAdditionalData;
  }
}
