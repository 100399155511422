<mat-card>
    <mat-card-subtitle>
        <mat-chip-list>
            <mat-chip color="primary" *ngIf="currentShowtimeSettings?.controls?.currentlyOnScreen"><span i18n="@@showtimeControls-screen"> Écran :</span> <span class="overflow"> {{currentShowtimeSettings?.controls?.currentlyOnScreen}}</span></mat-chip>
            <mat-chip color="primary"> <span i18n="@@showtimeControls-countdown">Compte à rebours : </span>{{countdown[0] | number : '2.0'}}:{{countdown[1] | number : '2.0'}}</mat-chip>
        </mat-chip-list>
    </mat-card-subtitle>
    <mat-card-content>
        <h3 i18n="@@showtimeControls-title">Changer le compte à rebours</h3>
        <div class="btn-group countdown">
            <button mat-raised-button color="accent" (click)="changeShowtime(-60)">-1min</button>
            <button mat-raised-button color="accent" (click)="changeShowtime(-10)">-10s</button>
            <button mat-raised-button color="accent" (click)="changeShowtime(0)" i18n="@@showtimeControls-showtimeNow">Maintenant</button>
            <button mat-raised-button color="accent" (click)="changeShowtime(10)">+10s</button>
            <button mat-raised-button color="accent" (click)="changeShowtime(60)">+1min</button>
        </div>
        <h3 i18n="@@showtimeControls-title">Vidéo</h3>
        <div class="btn-group feed">
            <button mat-raised-button [color]="currentlyOnScreen === 'black' ? 'primary' : 'accent'" (click)="setShowtimeScreen('black')" i18n="@@showtimeControls-showtimeScreenBlack">Noir</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'feed' ? 'primary' : 'accent'" (click)="setShowtimeScreen('feed')" i18n="@@showtimeControls-showtimeScreenCamera">Caméra</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'countdown' ? 'primary' : 'accent'" (click)="setShowtimeScreen('countdown')" i18n="@@showtimeControls-showtimeScreenCountdown">Compte à rebours</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'pause' ? 'primary' : 'accent'" (click)="setShowtimeScreen('pause')" i18n="@@showtimeControls-showtimePausedScreen">Pause</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'ending' ? 'primary' : 'accent'" (click)="setShowtimeScreen('ending')" i18n="@@showtimeControls-showtimeScreenEnding">Fin</button>
        </div>
        <div class="btn-group feed">
            <button mat-raised-button [color]="currentlyOnScreen === 'intro' ? 'primary' : 'accent'" (click)="setShowtimeScreen('intro')" i18n="@@showtimeControls-showtimeScreenIntroduction">Générique</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'connect' ? 'primary' : 'accent'" (click)="setShowtimeScreen('connect')" i18n="@@showtimeControls-showtimeScreenConnected">Morpheus</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'join-the-game' ? 'primary' : 'accent'" (click)="setShowtimeScreen('join-the-game')" i18n="@@showtimeControls-showtimeScreenJoinGame">QR-Code</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'rules' ? 'primary' : 'accent'" (click)="setShowtimeScreen('rules')" i18n="@@showtimeControls-showtimeScreenRules">Règles</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'jokers' ? 'primary' : 'accent'" (click)="setShowtimeScreen('jokers')" i18n="@@showtimeControls-showtimeScreenJokers">Jokers</button>
        </div>
        <br/>
        <!--
        <app-live-preview-widget [totalDurationInSeconds]="totalDurationInSeconds" [label]="currentlyOnScreen"></app-live-preview-widget>
        <h3 i18n="@@showtimeControls-title">Audio</h3>
        <b>Main Out :</b><span *ngIf="isSoundActive" class="green">Activé</span><span *ngIf="!isSoundActive" class="red">Désactivé</span>
        <b i18n="@@showtimeControls-audioBacktrack">Backtrack :</b><span *ngIf="!backtrackMuted" class="green" i18n="@@showtimeControls-audioBacktrackActivate">Activé</span><span *ngIf="backtrackMuted" class="red" i18n="@@showtimeControls-audioBacktrackDeactivate">Désactivé</span>
        <br/><br/>
        <div>
            <button mat-raised-button color="primary" *ngIf="!backtrackMuted" (click)="setBacktrackMuted(true)" i18n="@@showtimeControls-backtrackMute">Muet</button>
            <button mat-raised-button color="primary" *ngIf="backtrackMuted" (click)="setBacktrackMuted(false)" i18n="@@showtimeControls-backtrackUnmute">Réactiver</button>
            <mat-slider [min]="0" [max]="85" (valueChange)="setBacktrackVolume($event)"></mat-slider>
        </div>
        -->
    </mat-card-content>
</mat-card>
