<h1 mat-dialog-title i18n="@@dialog-title">Validation nécessaire</h1>
<div mat-dialog-content>
  <span *ngIf="i18nLabel === 'delete-media'" i18n="@@dialog-subtitleDeleteMedia">
    Vous allez supprimer définitivement ce média. Êtes-vous sûr ?
  </span>
    <span *ngIf="i18nLabel === 'finish-game'" i18n="@@dialog-subtitleFinishGame">
    Vous allez terminer le blind-test pour tous les utilisateurs.
  </span>
    <span *ngIf="i18nLabel === 'deactivate-game'" i18n="@@dialog-subtitleDeactivateGame">
    Désactiver la partie implique que le PIN et le jeu ne seront plus accessible par les joueurs.
  </span>
    <span *ngIf="i18nLabel === 'cancel-game'" i18n="@@dialog-subtitleCancelGame">
        ATTENTION : Uniquement pour les tests et démos !
        <br/>
        Lorsque vous annulez la partie, tout sera effacé. Vous ne pourrez pas exporter les scores ni avoir les statistiques de jeu.

    </span>
    <span *ngIf="i18nLabel === 'force-lock'" i18n="@@dialog-subtitleForceLock">
        Vous n'avez pas corrigé toutes les réponses. Voulez-vous quand même forcer le verrouillage ?
    </span>
    <span *ngIf="i18nLabel === 'force-reset'" i18n="@@dialog-subtitleForceReset">
        Vous allez remettre à zéro tous les résultats de la chanson courante.
    </span>

</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial i18n="@@dialog-no">Non!</button>
    <button mat-button [mat-dialog-close]="true" i18n="@@dialog-yes">Ok!</button>
</div>
