<mat-card>
  <mat-card-subtitle i18n="@@statsWidget-locked">
    Les réponses sont maintenant verrouillées.
  </mat-card-subtitle>
  <mat-card-content>
    <div class="loading">
      <mat-spinner *ngIf="computingStats" [diameter]="40"></mat-spinner>
      <img src="assets/imgs/valid.png" alt="valid" *ngIf="!computingStats"/>
      <span i18n="@@statsWidget-statisticsCalculation">Calcul des statistiques</span>
    </div>
    <div class="loading">
      <mat-spinner *ngIf="computingResults" [diameter]="40"></mat-spinner>
      <img src="assets/imgs/valid.png" alt="valid" *ngIf="!computingResults"/>
     <span i18n="@@statsWidget-updateStats"> Mise à jour des résultats</span>
    </div>
    <div *ngIf="!computingStats">
      <p *ngIf="noOneAnswered()" i18n="@@statsWidget-nobodyAnswered">Personne n'a répondu...<br/>Vous allez bien ? Doit-on commencer à s'inquiéter ?<br/> Si c'est le cas, faites-le nous savoir...</p>
      <p *ngIf="!noOneAnswered()"><b>{{stats?.responsePercent}}%</b><span i18n="@@statsWidget-homeAnswered">des joueurs ont répondu</span></p>
      <p *ngIf="noOneSucceededEvenPartially()" i18n="@@statsWidget-allWrong">... personne n'arrive à trouver la bonne réponse... Rien. Nada. Walou. Que dalle.</p>
      <p *ngIf="fewSucceeded()"><span i18n="@@statsWidget-onlyFew">... seulement </span> {{stats?.successPercent + stats?.partialSuccessPercent}} <span i18n="@@statsWidget-fewSucceeded">% réussi à marquer.</span></p>
      <p *ngIf="allSucceeded()" i18n="@@statsWidget-allSucceeded">... tout le monde a réussi au moins partiellement!!!</p>
      <app-special-mentions
              [fastest]="stats?.fastestPlayer"
              [fastestResult]="stats?.fastestPlayerResult"
              [slowest]="stats?.slowestPlayer"
              [slowestResult]="stats?.slowestPlayerResult"
              [soleLoser]="stats?.soleLoser"
              [soleWinner]="stats?.soleWinner">
      </app-special-mentions>
    </div>
    <canvas #statsElement width="400" height="400"></canvas>
  </mat-card-content>
</mat-card>
