import {Injectable} from '@angular/core';
import {ScoreService} from '../score/score.service';
import {PlayerService} from '../player/player.service';
import {GameService} from '../game/game.service';
import {Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {StatsForSong} from '@frogconnexion/blinding-common';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  private _currentStatsForSongObservable: Observable<StatsForSong>;

  constructor(private _gameService: GameService, private _scoreService: ScoreService, private _playerService: PlayerService) {
    // Current Stats observable
    this._currentStatsForSongObservable = this._gameService.currentGameControls().pipe(map(g => g.songControl.stats));
  }

  currentStats(): Observable<StatsForSong> {
    return this._currentStatsForSongObservable;
  }
}
