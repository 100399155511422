import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GameComponent} from './game.component';
import {AdminAuthGuard} from '../../core/auth/service/guards/admin-auth.guard';
import {BonusComponent} from './bonus/bonus.component';
import {MalusComponent} from './malus/malus.component';
import {MaterialModule} from '../../core/material/material.module';
import {CoreModule} from '../../core/core.module';
import {RouterModule} from '@angular/router';
import {AdminStatsWidgetComponent} from './stats-widget/stats-widget.component';
import {ReviewWidgetComponent} from './review-widget/review-widget.component';
import {SubmitReviewWidgetComponent} from './submit-review-widget/submit-review-widget.component';
import {GameControlsService} from '../../core/services/gamecontrols/gamecontrols.service';
import {ReviewService} from '../../core/services/review/review.service';
import {OrganizationGuard} from '../../core/services/organization/guards/organization.guard';
import {ShowtimeControlsComponent} from './showtime-controls/showtime-controls.component';
import {LivePreviewWidgetComponent} from './live-preview-widget/live-preview-widget.component';
import {VideoControlComponent} from './video-control/video-control.component';
import {SpecialMentionsComponent} from './stats-widget/special-mentions/special-mentions.component';
import {PlayerMentionWidgetComponent} from './stats-widget/special-mentions/player-mention-widget/player-mention-widget.component';

const routes = [
    {path: 'org/:organization/game', component: GameComponent, canActivate: [OrganizationGuard, AdminAuthGuard]},
    {path: 'org/:organization/video-control', component: VideoControlComponent, canActivate: [OrganizationGuard, AdminAuthGuard]},
    {path: 'org/:organization/game/bonus', component: BonusComponent, canActivate: [OrganizationGuard, AdminAuthGuard]},
    {path: 'org/:organization/game/malus', component: MalusComponent, canActivate: [OrganizationGuard, AdminAuthGuard]}
];

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        CoreModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        GameComponent,
        VideoControlComponent,
        AdminStatsWidgetComponent,
        ReviewWidgetComponent,
        SubmitReviewWidgetComponent,
        BonusComponent,
        MalusComponent,
        ShowtimeControlsComponent,
        LivePreviewWidgetComponent,
        SpecialMentionsComponent,
        PlayerMentionWidgetComponent
    ],
    providers: [
        GameControlsService,
        ReviewService
    ]
})
export class GameModule {
}
