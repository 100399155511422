import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SectionImportDescriptor, SetOfBlindtestImportDescriptor, SongImportDescriptor, SongType} from '@frogconnexion/blinding-common';


@Component({
  selector: 'app-set-edit-widget',
  templateUrl: './set-edit-widget.component.html',
  styleUrls: ['./set-edit-widget.component.scss']
})
export class SetEditWidgetComponent implements OnInit {

  @Input()
  setFormGroup: UntypedFormGroup;
  @Input()
  set: SetOfBlindtestImportDescriptor;
  @Input()
  index: number;
  states: {finished: boolean}[];

  constructor() {
    this.onSectionDelete = this.onSectionDelete.bind(this);
    this.onSectionStateChange = this.onSectionStateChange.bind(this);
    this.states = new Array<{finished: boolean}>();
  }

  ngOnInit() {
    this.set.sections.forEach(s => {
      this.states.push({finished: s.label != null && s.songs && s.songs.length > 0});
    });
    this.setFormGroup.addControl('set', new UntypedFormControl(this.isReady, [Validators.requiredTrue]));
  }

  newSection() {
    this.set.sections.push(new SectionImportDescriptor(SongType.NORMAL, null, null, []));
    this.states.push({finished: false});
    this.updateFormControl();
  }

  onSectionStateChange(index: number, finished: boolean) {
    this.states[index].finished = finished;
    this.updateFormControl();
  }

  get isReady() {
    return this.states.filter(s => !s.finished).length === 0;
  }

  updateFormControl() {
    this.setFormGroup.get('set').setValue(this.isReady);
  }

  onSectionDelete(index: number) {
    this.set.sections.splice(index, 1);
    this.states.splice(index, 1);
    this.updateFormControl();
  }

}
