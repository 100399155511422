import {Component, OnDestroy, OnInit} from '@angular/core';
import {BlindtestService} from '../../../core/services/blindtest/blindtest.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GameService} from '../../../core/services/game/game.service';
import {Subscription} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {Blindtest, GameControls, SetOfBlindtest} from '@frogconnexion/blinding-common';


@Component({
  selector: 'app-blindtest-details',
  templateUrl: './blindtest-details.component.html',
  styleUrls: ['./blindtest-details.component.scss']
})
export class BlindTestDetailsComponent implements OnInit, OnDestroy {

  blindtest: Blindtest;
  blindSets: SetOfBlindtest[];
  currentGameState: GameControls;
  isCurrentGame: boolean;
  hasCurrentGame: boolean;
  private _blindTestSubscription: Subscription;

  constructor(private _blindTestService: BlindtestService,
              private _gameService: GameService, private _router: Router, private _route: ActivatedRoute) {
  }

  ngOnInit() {
    this._blindTestSubscription = this._gameService.currentGameControls()
      .pipe(mergeMap(gs => {
        this.hasCurrentGame = !!(gs);
        this.currentGameState = gs;
        return this._blindTestService.get(this._route.snapshot.paramMap.get('id'));
      }))
      .subscribe(bt => {
        this.blindtest = bt;
        this.blindSets = bt.sets || [];
        this.isCurrentGame = (bt && this.hasCurrentGame && bt.id === this.blindtest.id);
      });
  }

  ngOnDestroy() {
    this._blindTestSubscription.unsubscribe();
  }

}
