import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {LibraryDataSource, LibraryFilter} from '../../../library/service/library-datasource';
import {SearchState} from '../../../library/models/search-state';
import {Subscription} from 'rxjs';
import {LibraryService} from '../../../library/service/library.service';
import {NavigationService} from '../../../../core/services/navigation/navigation.service';
import {unsubscribe} from '../../../../core/handler/subscription-handler';
import {LoggerService} from '../../../../core/services/logger/logger.service';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Song, SongImportDescriptor, SongType} from '@frogconnexion/blinding-common';

export interface DialogData {
  type: number;
}


@Component({
  selector: 'bt-item-selection-dialog',
  templateUrl: './item-selection-dialog.component.html',
  styleUrls: ['./item-selection-dialog.component.scss']
})
export class ItemSelectionDialogComponent implements OnInit, OnDestroy {

  searchString: string;
  ds: LibraryDataSource;
  ss: SearchState;
  type: SongType;
  private searchStateSubscription: Subscription;

  constructor(private _libraryService: LibraryService,
              private _navigationService: NavigationService,
              private _logger: LoggerService,
              private _dialogRef: MatDialogRef<ItemSelectionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.searchString = '';
    this.ds = new LibraryDataSource(this._libraryService, this._logger);
    this.ss = new SearchState();
    this.type = data.type;
    this.onItemSelected = this.onItemSelected.bind(this);
    this.ds.setFilter(new LibraryFilter(null, this.type));
  }

  ngOnInit() {
    this.searchStateSubscription = this.ds.searchState().subscribe(ss => {
      this.ss = ss;
    });
  }

  onFilterChanged() {
    const filter = { search: this.searchString, type: this.type};
    this.ds.onFilterChanged(filter);
  }

  onItemSelected(item: Song) {
    this._dialogRef.close(SongImportDescriptor.fromSong(item));
  }


  ngOnDestroy(): void {
    unsubscribe(this.searchStateSubscription);
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

}
