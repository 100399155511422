<div class="header">
  <img src='/assets/imgs/song-normal.png' class="section-type" alt='Section type' *ngIf="section.type === 0"/>
  <img src='/assets/imgs/song-lyrics.png' class="section-type" alt='Section type' *ngIf="section.type === 1"/>
  <div class="header-title">
    <h4>{{section.label}}</h4>
    &nbsp;&nbsp;
    <mat-hint *ngIf="section.songs.length !== 1">{{section.songs.length}} <span i18n="@@sectionReviewWidget-itemsSongLength">éléments</span></mat-hint>
    <mat-hint *ngIf="section.songs.length === 1">{{section.songs.length}} <span i18n="@@sectionReviewWidget-itemSongLength">élément</span></mat-hint>
  </div>

</div>
<div class="content">
  <div class="item" *ngFor="let song of section.songs">
    <bt-item-widget [item]="song" [compact]="true"
                    [showImage]="false"></bt-item-widget>
  </div>
</div>
