import {Component, Input, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Song} from '@frogconnexion/blinding-common';

@Component({
  selector: 'bt-list-items-widget',
  templateUrl: './list-items-widget.component.html',
  styleUrls: ['./list-items-widget.component.scss']
})
export class ListItemsWidgetComponent implements OnInit {

  @Input()
  songs: Song[];

  constructor() {
    this.removeSong = this.removeSong.bind(this);
  }

  ngOnInit() {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.songs, event.previousIndex, event.currentIndex);
  }


  removeSong(item: any) {
    const index = this.songs.indexOf(item);
    this.songs.splice(index, 1);
  }

}
