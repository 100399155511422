<div class="stepper-container" *ngIf="edit">
  <mat-vertical-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="firstFormGroup" label="Nouvelle Section" i18n-label="@@sectionEditWidget-newSection" #firstStep>
      <form [formGroup]="firstFormGroup" (ngSubmit)="onSectionStructureSubmit()">
        <div class="form-group">
          <mat-form-field class="blindtest-section-lbl-input" appearance="outline">
            <mat-label i18n="@@sectionEditWidget-nameSection">Nom de la Section</mat-label>
            <input formControlName="label" matInput
                   [(ngModel)]="section.label"
                   placeholder="P*** je la connais" i18n-placeholder="@@sectionEditWidget-sectionNamePlaceHolder"
                   [errorStateMatcher]="matcher"/>
            <mat-error *ngFor="let validation of validationMessages['label']">
              <mat-error class="error-message" *ngIf="firstFormGroup.get('label').hasError(validation.type)">
                <mat-error class="error-message" *ngIf="validation.message === 'champ.requis'"
                           i18n="@@sectionEditWidget-requiredField">Champ requis</mat-error>
                <mat-error class="error-message" *ngIf="validation.message === 'champ.invalide'"
                           i18n="@@sectionEditWidget-invalidField">Merci d\'entrer une valeur valide (plus de 3 lettres)</mat-error>
              <mat-error class="error-message" *ngIf="validation.message === 'champ.min.invalide'"
                         i18n="@@sectionEditWidget-requiredMinOneField">Merci d\'ajouter au moins un élément</mat-error>
            </mat-error>
            </mat-error>
          </mat-form-field>
          <div class="form-item">
            <mat-hint class="item-hint" i18n="@@sectionEditWidget-responseType">Type de réponse</mat-hint>
            <mat-radio-group formControlName="type">
              <mat-radio-button class="example-margin" [value]="0" i18n="@@sectionEditWidget-responseTypeNormal">
                Normale
              </mat-radio-button>
              &nbsp;&nbsp;
              <mat-radio-button class="example-margin" [value]="1" i18n="@@sectionEditWidget-responseTypeLyrics">
                Phrase
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <bt-input-widget
          [form]="firstFormGroup"
          controlName="altlabelartist"
          label="Intitulé du champ Artiste" i18n-label="@@sectionEditWidget-artistFieldTitle"
          [initialValue]="section.altLabels?.artist"
          placeholder="Nom de l'Artiste" i18n-placeholder="@@sectionEditWidget-nameArtistPlaceholder"
          *ngIf="type.value === 0">
        </bt-input-widget>
        <bt-input-widget
          [form]="firstFormGroup"
          controlName="altlabeltitle"
          label="Intitulé du champ Titre" i18n-label="@@sectionEditWidget-fieldTitle"
          [initialValue]="section.altLabels?.title"
          placeholder="Titre de la chanson" i18n-placeholder="@@sectionEditWidget-titleSongPlaceholder"
          *ngIf="type.value === 0">
        </bt-input-widget>
        <bt-input-widget
          [form]="firstFormGroup"
          controlName="altlabellyrics"
          label="Intitulé du champ Phrase" i18n-label="@@sectionEditWidget-fieldNameSentence"
          [initialValue]="section.altLabels?.lyrics"
          placeholder="Paroles" i18n-placeholder="@@sectionEditWidget-speechPlaceholder"
          *ngIf="type.value === 1">
        </bt-input-widget>
        <div>
          <button mat-raised-button matStepperNext color="primary" style="margin-top: 17px" i18n="@@sectionEditWidget-nextStep">Suivant</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" i18n-label="@@sectionEditWidget-sectionContent" label="Contenu">
      <mat-hint *ngIf="section.songs?.length !== 1"><span i18n="@@sectionEditWidget-sectionSongsLength"> La section contient </span>{{section.songs?.length}} <span i18n="@@sectionEditWidget-sectionSongsLengthItems"> éléments</span></mat-hint>
      <mat-hint *ngIf="section.songs?.length === 1"><span i18n="@@sectionEditWidget-sectionSongsLengthContain">La section contient </span> {{section.songs?.length}} <span i18n="@@sectionEditWidget-sectionSongsLengthItem"> élément </span></mat-hint>
      <form [formGroup]="secondFormGroup">
        <bt-list-items-widget [songs]="section.songs"></bt-list-items-widget>
        <div>
          <button mat-raised-button color="primary" (click)="newSong()" i18n="@@sectionEditWidget-addItem">Ajouter un élément</button>
          <button mat-raised-button color="primary" matStepperNext [disabled]="section.songs.length === 0"
                  (click)="finishEdition()" i18n="@@sectionEditWidget-saveSection">Enregistrer
          </button>
        </div>
      </form>
    </mat-step>
  </mat-vertical-stepper>
  <mat-icon class="delete-btn" (click)="onSectionDelete(index)" i18n="@@sectionEditWidget-cancelDelete">cancel</mat-icon>
</div>

<div *ngIf="!edit" class="finished-section-container" (click)="editAgain()">
  <bt-section-review-widget [section]="section"></bt-section-review-widget>
</div>
