<h2 mat-dialog-title i18n="@@blindtestImportDialog-title">Import en cours</h2>
<div mat-dialog-content>
<div>
    <div *ngIf="il?.processes.length > 0">
        <span i18n="@@importExport-file">Fichiers :</span>
        <div *ngFor="let item of il?.processes">- {{item.file.name}}</div>
    </div>
</div>
<div *ngFor="let item of il?.processes">
    <div *ngIf="item?.loading" i18n="@@importExport-uploadFile">Chargement du fichier...</div>
    <div *ngIf="item?.uploading" i18n="@@importExport-sendingFile">Envoi du fichier...</div>
    <div class="alert alert-danger" *ngIf="item?.errors.wrongFileType" i18n="@@importExport-wrongFileType">Seuls les fichiers JSON sont acceptés.</div>
    <div class="alert alert-danger" *ngIf="item?.errors.wrongSchema" i18n="@@importExport-wrongSchema">La structure principale n'est pas la bonne.
    </div>
    <div class="alert alert-danger" *ngIf="item?.errors.unknownError"><span i18n="@@importExport-unknownError">Une erreur est survenue : </span>{{item.errors.unknownError}}
    </div>
    <div class="alert alert-danger" *ngIf="item?.errors.apiError"><span i18n="@@importExport-serverError">Erreur serveur : </span>{{item.errors.apiError}}
    </div>
    <div *ngIf="item?.done" i18n="@@importExport-fileImportedSuccessfully">Fichier importé avec succès</div>
</div>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null" [disabled]="!il?.completed" i18n="@@blindtestImportDialog-close">Fermer</button>
</div>
