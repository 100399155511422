import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {InputErrorStateMatcher} from '../blindtest-edit-page.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ItemSelectionDialogComponent} from '../item-selection-dialog/item-selection-dialog.component';

import {MatStep, MatStepper} from '@angular/material/stepper';
import {MatDialog} from '@angular/material/dialog';
import {AltLabels, SectionImportDescriptor, Song, SongType} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-section-edit-widget',
  templateUrl: './section-edit-widget.component.html',
  styleUrls: ['./section-edit-widget.component.scss']
})
export class SectionEditWidgetComponent implements OnInit {


  matcher = new InputErrorStateMatcher();
  validationMessages = {
    'artist': [
      {type: 'required', message: 'artiste.nom.required'},
      {type: 'minlength', message: 'artiste.nom.invalide'}
    ],
    'label': [
      {type: 'required', message: 'champ.requis'},
      {type: 'minlength', message: 'champ.invalide'},
      {type: 'min', message: 'champ.min.invalide'}
    ]
  };
  @Input()
  index: number;
  @Input()
  section: SectionImportDescriptor;
  @Input()
  onSectionDelete: Function;
  @Input()
  onSectionStateChange: Function;
  @ViewChild('firstStep', {static: false})
  step: MatStep;
  @ViewChild('stepper', {static: false})
  stepper: MatStepper;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  edit: boolean;

  constructor(public dialog: MatDialog) {
  }

  get type() {
    return this.firstFormGroup.get('type');
  }

  get label() {
    return this.firstFormGroup.get('label');
  }

  get typeNotChanged() {
    return this.firstFormGroup.get('typeNotChanged');
  }

  ngOnInit() {
    this.firstFormGroup = new UntypedFormGroup({
      'label': new UntypedFormControl(this.section.label, [Validators.required, Validators.minLength(3)]),
      'type': new UntypedFormControl(this.section.type, [Validators.required]),
      'typeNotChanged': new UntypedFormControl(true, [Validators.requiredTrue]),
    });
    this.secondFormGroup = new UntypedFormGroup({
      'songCount': new UntypedFormControl(this.section.songs.length, [Validators.min(1)])
    });
    this.type.statusChanges.subscribe(() => {
      this.typeNotChanged.setValue(this.type.value === this.section.type);
    });
    this.edit = !(this.firstFormGroup.valid && this.secondFormGroup.valid);
  }

  newSong() {
    const songs = this.section.songs;
    if (!songs) {
      this.section.songs = [];
    }
    const dialogRef = this.dialog.open(ItemSelectionDialogComponent, {
      minHeight: '90vh',
      maxHeight: '90vh',
      minWidth: '90vw',
      maxWidth: '90vw',
      data: {
        type: this.section.type
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        songs.push(result);
      }
    });
  }

  finishEdition() {
    this.edit = false;
    this.onSectionStateChange(this.index, true);
  }

  editAgain() {
    this.edit = true;
    this.onSectionStateChange(this.index, false);
  }

  getValueOrNull(value: string): string {
    return (value && value.trim().length > 0) ? value.trim() : null;
  }

  onSectionStructureSubmit() {
    let artist;
    let title;
    let lyrics;
    let hasValues = false;
    switch (this.type.value) {
      case SongType.NORMAL:
        artist = this.getValueOrNull(this.firstFormGroup.get('altlabelartist').value);
        title = this.getValueOrNull(this.firstFormGroup.get('altlabeltitle').value);
        hasValues = artist && title;
        break;
      case SongType.LYRICS:
        lyrics = this.getValueOrNull(this.firstFormGroup.get('altlabellyrics').value);
        if (lyrics) {
          hasValues = true;
        }
        break;
    }
    // If type has changed, we need to remove the songs of the current section if it had any
    if (this.section.type !== this.type.value) {
      this.section.songs.splice(0, this.section.songs.length);
      this.section.type = this.type.value;
      this.typeNotChanged.setValue(true);
      this.stepper.next();
    }
    // If no value is present in the altlabels, we can set altLabels to null
    if (!hasValues && this.section.altLabels) {
      this.section.altLabels = null;
      return;
    }
    // Otherwise, let's apply changes
    if (!this.section.altLabels) {
      this.section.altLabels = new AltLabels(null, null, null);
    }
    this.section.altLabels.lyrics = lyrics ? lyrics.trim() : null;
    this.section.altLabels.artist = artist ? artist.trim() : null;
    this.section.altLabels.title = title ? title.trim() : null;
  }
}
