<mat-card class="current">
  <mat-card-header>
    <img mat-card-avatar [src]="currentGameMetadata?.branding ? currentGameMetadata.branding.logoUrl : '/assets/imgs/logo.png'" alt='BlindTest Avatar'/>
    <mat-card-title>{{currentGameMetadata?.name || ""}}</mat-card-title>
    <mat-card-subtitle>
      <span>{{getGameplay()}} - </span>
      <span *ngIf="getSetCount() > 1">{{blindtest?.sets?.length || "0"}} <span i18n="@@blindtestWidget-set">sets - </span> {{getItemsCount()}} <span i18n="@@blindtestWidget-songs">chansons </span></span>
    </mat-card-subtitle>
    <mat-card-subtitle *ngIf="getSetCount() === 1"><span i18n="@@blindtestWidget-subtitleOneSet">1 set  - </span>{{getItemsCount()}} <span i18n="@@blindtestWidget-subtitleSongs">chansons</span></mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="blindtest?.sets">
    <span *ngFor="let set of blindtest.sets; let i = index">
      <div class="set-lbl"><span i18n="@@blindtestWidget-setLabelNum">Manche </span>&nbsp;{{i+1}}</div>
      <div *ngFor="let section of set?.sections">
        <span class="section-lbl">{{section.label}}</span>
        <span class="section-size">{{section.endIndex - section.startIndex}} <span i18n="@@blindtestWidget-setLabelSongs">chansons</span></span>
      </div>
    </span>
  </mat-card-content>
  <mat-card-actions *ngIf="!disabling">
    <button mat-raised-button color="primary" routerLink='../game'>
      <mat-icon>sports_esports</mat-icon>
      <ng-container i18n="@@blindtestWidget-gameControl">Controle du Jeu</ng-container>
    </button>

    <a mat-raised-button color="primary" class="hide-mobile" [href]="showtimeBaseUrl" target="_blank">
      <mat-icon>movie_filter</mat-icon>
      <ng-container i18n="@@blindtestWidget-showtimeAccess">Accès Showtime</ng-container>
    </a>

    <button mat-raised-button color="primary" (click)="exportScoresheet()" *ngIf='isFinished'>
      <mat-icon>file_download</mat-icon>
      <ng-container i18n="@@blindtestWidget-exportScores">Exporter les Scores</ng-container>
    </button>
    <button mat-raised-button [routerLink]="'../blindtests/' + blindtest.id" *ngIf='!isFinished'>
      <mat-icon>info</mat-icon>
      <ng-container i18n="@@blindtestWidget-details">Détails</ng-container>
    </button>
    <button mat-raised-button color="warn" (click)="openDisableDialog()">
      <mat-icon>disabled_by_default</mat-icon>
      <ng-container i18n="@@blindtestWidget-disableDialog">Désactiver</ng-container>
    </button>
  </mat-card-actions>
  <mat-card-actions *ngIf="disabling">
    <dot-spinner></dot-spinner>
  </mat-card-actions>
  <mat-card-footer *ngIf='currentGameState'>
    <mat-chip-list>
      <mat-chip color="primary" *ngIf="currentGameState.blindtestControl?.status === 0" selected i18n="@@blindtestWidget-readyStatus">Prêt</mat-chip>
      <mat-chip color="primary" *ngIf='currentGameState.blindtestControl?.isRunning()' selected i18n="@@blindtestWidget-onGoingStatus">En cours</mat-chip>
      <mat-chip *ngIf='currentGameState.blindtestControl?.status === 3' i18n="@@blindtestWidget-finishedStatus">Terminé</mat-chip>
    </mat-chip-list>
  </mat-card-footer>
  <div [hidden]='!error' class='alert alert-danger'>
    {{ error }}
  </div>
</mat-card>
