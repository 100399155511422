import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, ReplaySubject, Subscription} from 'rxjs';
import {LibraryService} from './library.service';
import {SearchState} from '../models/search-state';
import {LoggerService} from '../../../core/services/logger/logger.service';
import {Song, SongType} from '@frogconnexion/blinding-common';

/**
 * Here's how it works.
 * I have implemented a SearchState. This reflects the current state of the search.
 * When we first load the page, we are "fetching initial data" (thus we will emit a first SearchState with fetchingInitialData = true).
 * Then, you will be able to see your first page. A second SearchState will be emitted with the new results, the total count, and some data.
 * Also, the raw data will be emitted throught the DataSource subject (dataStream).
 *
 * When we scroll down without changing any filter, we will be loading additional data. Thus triggering new published search states.
 *
 * When we change the search filter, we will be resetting to the initial state (as in the beginning of this doc ^^).
 */

const MIN_FILTER_LENGTH = 3;

export class LibraryFilter {
  search: string;
  type: SongType;

  constructor(search?: string, type?: SongType) {
    this.search = search;
    this.type = type;
  }

}

export class LibraryDataSource extends DataSource<Song | undefined> {

  private _totalCount = 0;
  private _filter: LibraryFilter = null;
  private _pageSize = 20;
  private _cachedData = Array.from<Song>({length: this._totalCount});
  private _fetchedPages = new Set<number>();
  private _dataStream = new BehaviorSubject<(Song | undefined)[]>(this._cachedData);
  private _searchStream = new ReplaySubject<SearchState>();
  private _dataSubscription = new Subscription();
  private _querySubscription = new Subscription();

  constructor(private _libraryService: LibraryService,
              private _logger: LoggerService) {
    super();
  }

  setFilter(filter: LibraryFilter) {
    this._filter = filter;
  }

  onFilterChanged(filter: LibraryFilter) {

    if (this.shouldBeIgnored(filter) && this.shouldBeIgnored(this._filter)) {
      return;
    }
    const newFilter = new LibraryFilter();
    if (!this.shouldBeIgnored(filter)) {
      if (filter.search) {
        newFilter.search = filter.search.trim().toLowerCase();
      }
      if (filter.type != null) {
        newFilter.type = filter.type;
      }
    }
    this.init(newFilter);
  }

  connect(collectionViewer: CollectionViewer): Observable<(Song | undefined)[]> {
    this._dataSubscription.add(collectionViewer.viewChange.subscribe(range => {
      const startPage = this.getPageForIndex(range.start);
      const endPage = this.getPageForIndex(range.end - 1);
      for (let i = startPage; i <= endPage; i++) {
        this.fetchPage(i, this._filter, false);
      }
    }));
    // Initial fetch
    this.fetchPage(0, this._filter, true);
    return this._dataStream;
  }

  init(filter): void {
    // Cancel all subscriptions as filters require brand new data
    this._querySubscription.unsubscribe();
    this._querySubscription = new Subscription();
    // Clear cache
    this._fetchedPages.clear();
    this._cachedData = Array.from<Song>({length: 0});
    // Publish empty results
    this._dataStream.next(this._cachedData);
    // Fetch new page
    this.fetchPage(0, filter, true);
  }

  disconnect(): void {
    this._dataSubscription.unsubscribe();
  }

  searchState(): Observable<SearchState | undefined> {
    return this._searchStream;
  }

  private shouldBeIgnored(filter: LibraryFilter) {
    if (!filter) {
      return true;
    }
    const ignoreSearchString = !(filter.search && filter.search.trim().length >= MIN_FILTER_LENGTH);
    const ignoreType = (filter.type === null);
    return ignoreType && ignoreSearchString;
  }

  private getPageForIndex(index: number): number {
    return Math.floor(Math.min(index, this._totalCount - 1) / this._pageSize);
  }

  private fetchPage(page: number, filter: LibraryFilter, isFirst: boolean) {
    this._logger.debug('Will fetch page ' + page + ' with filter ' + JSON.stringify(filter));
    if (this._fetchedPages.has(page)) {
      this._logger.debug('Already has page ' + page);
      return;
    }
    if (isFirst) {
      // First fetch
      this._searchStream.next(new SearchState(filter, -1, true));
    } else {
      // Loading additional pages of the same search
      this._searchStream.next(new SearchState(filter, this._totalCount, false, true));
    }

    this._fetchedPages.add(page);

    this._querySubscription.add(this._libraryService.getItems(filter, page, this._pageSize).subscribe(results => {
      this._logger.debug(`${results.totalCount} results found`);
      if (isFirst) {
        // If results have a different query than previously, we need to clear previous results
        this._totalCount = results.totalCount;
        this._filter = results.filter;
        this._cachedData = Array.from<Song>({length: this._totalCount});
      }
      // Replace in cache
      this._cachedData.splice(page * this._pageSize, this._pageSize, ...results.items);
      // Update data stream
      this._dataStream.next(this._cachedData);
      // Update search stream
      this._searchStream.next(new SearchState(results.filter, results.totalCount));
    }));
  }


}
