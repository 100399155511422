import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthUser, Organization} from '@frogconnexion/core-common';
import {Blindtest, GameControls, GameMetadata} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-blindtest-card-list',
  templateUrl: './blindtest-card-list.component.html',
  styleUrls: ['./blindtest-card-list.component.css']
})
export class BlindtestCardListComponent implements OnInit {

  @Input() user: AuthUser;
  @Input() organization: Organization;
  @Input() blindtestList: Blindtest[];
  @Input() currentGameControls: GameControls;
  @Input() currentGameMetadata: GameMetadata;
  @Output() refreshDatabase = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  isCurrent(bt: Blindtest) {
    return this.currentGameMetadata && (bt.id === this.currentGameMetadata.blindtestId);
  }

}
