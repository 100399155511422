import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {ValidationDialogComponent} from '../../../core/components/dialog/validation-dialog/validation-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {LibraryService} from '../../library/service/library.service';
import {Subscription} from 'rxjs';
import {BlindtestService} from '../../../core/services/blindtest/blindtest.service';
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {NavigationService} from '../../../core/services/navigation/navigation.service';
import {MatStepper} from '@angular/material/stepper';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatDialog} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {BlindtestImportDescriptor, SectionImportDescriptor, SetOfBlindtestImportDescriptor, SongType} from '@frogconnexion/blinding-common';

/** Error when invalid control is dirty, touched, or submitted. */
export class InputErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-blindtest-edit-page',
  templateUrl: './blindtest-edit-page.component.html',
  styleUrls: ['./blindtest-edit-page.component.scss']
})
export class BlindtestEditPageComponent implements OnInit, OnDestroy {

  private _removeDialogSubscription: Subscription;
  blindtest: BlindtestImportDescriptor;
  @ViewChild('stepper', {static: false})
  stepper: MatStepper;
  @ViewChild('name', {static: false})
  nameField: ElementRef;
  validationMessages = {
    name: [
      {type: 'required', message: 'nom.required.'},
      {type: 'minlength', message: 'nom.invalide'}
    ],
    description: [
      {type: 'minlength', message: 'description.invalide'}
    ],
  };

  metaFormGroup: UntypedFormGroup;
  setFormGroups: UntypedFormGroup[];
  private _blindtestSubscription: Subscription;

  matcher = new InputErrorStateMatcher();
  edit: boolean;

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _location: Location,
              private _libraryService: LibraryService,
              private _blindtestService: BlindtestService,
              private _navigationService: NavigationService,
              private _dialog: MatDialog) {
  }

  ngOnDestroy(): void {
        unsubscribe(this._removeDialogSubscription, this._blindtestSubscription);
    }

  ngOnInit() {
    this.blindtest = new BlindtestImportDescriptor(null, null, [], [], false, '');

    this.metaFormGroup = new UntypedFormGroup({
      'name': new UntypedFormControl(this.blindtest.name, [Validators.required, Validators.minLength(3)]),
      'description': new UntypedFormControl(this.blindtest.description, [Validators.minLength(3)]),
    });
    this.setFormGroups = [];

    this.edit = this._route.snapshot.paramMap.has('id');
    this._navigationService.updateNavHeader(this.edit ? 'quiz.edit' : 'quiz.create');

    if (this.edit) {
      this._blindtestSubscription = this._blindtestService.get(this._route.snapshot.paramMap.get('id')).subscribe(bt => {
        bt.sets.forEach(() => {
          this.setFormGroups.push(new UntypedFormGroup({}));
        });
        this.blindtest = BlindtestImportDescriptor.fromBlindtest(bt);
        this.formName.setValue(this.blindtest.name);
        this.formDescription.setValue(this.blindtest.description);
      });
    } else {
      // Default is 2 sets
      if (!this.blindtest.sets || this.blindtest.sets.length === 0) {
        this.blindtest.sets = [];
        for (let i = 0; i < 2; i++) {
          this.setFormGroups.push(new UntypedFormGroup({}));
          this.blindtest.sets.push(new SetOfBlindtestImportDescriptor([]));
        }
      }
      setTimeout(() => {
        this.nameField.nativeElement.focus();
      }, 500);
    }
  }

  get defaultBlindtestName() {
    return this.toTitleCase(new Date().toISOString().substr(0, 10)) + ' - Apple';
  }

  get formName() {
    return this.metaFormGroup.get('name');
  }

  get formDescription() {
    return this.metaFormGroup.get('description');
  }

  onSetCountChanged(element) {
    const newSetCount = element.target.value;

    if (!this.blindtest.sets) {
      this.blindtest.sets = [];
    }
    const previousSetCount = this.blindtest.sets.length;

    if (newSetCount > previousSetCount) {
      for (let i = previousSetCount; i < newSetCount; i++) {
        this.setFormGroups.push(new UntypedFormGroup({}));
        this.blindtest.sets.push(new SetOfBlindtestImportDescriptor([new SectionImportDescriptor(SongType.NORMAL, null, null, [])]));
      }
    } else if (newSetCount < previousSetCount) {
      this.setFormGroups.splice(newSetCount, previousSetCount - newSetCount);
      this.blindtest.sets.splice(newSetCount, previousSetCount - newSetCount);
    }
  }

  onDeleteClicked() {
    unsubscribe(this._removeDialogSubscription);
    const dialogRef = this._dialog.open(ValidationDialogComponent, {
      width: '300px',
      data: {action: 'delete-media'}
    });
    this._removeDialogSubscription = dialogRef.afterClosed().subscribe(activate => {
      if (activate) {
        this._blindtestService.delete(this.blindtest.id).subscribe(() => {
          this._location.back();
        });
      }
    });
  }

  onSubmit() {
    this.blindtest.name = this.formName.value;
    this.blindtest.description = this.formDescription.value;
  }


  saveBlindtest() {
    const result = BlindtestImportDescriptor.toBlindtest(this.blindtest);
    this._blindtestService.save(result).subscribe(() => {
      this._location.back();
    });
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

}
