<mat-card>
  <button mat-icon-button class="floating-delete-btn" *ngIf="!isDemoUser()" (click)="onDeleteClicked()" color="warn"><mat-icon>delete</mat-icon></button>
  <mat-card-header>
    <img mat-card-avatar [src]="'/assets/imgs/logo.png'" alt='BlindTest Avatar'/>
    <mat-card-title>{{blindtest.name}}</mat-card-title>
    <mat-card-subtitle>
      <span *ngIf="getSetCount() > 1">{{blindtest.sets.length}} <span i18n="@@blindtestWidget-set">sets - </span> {{getItemsCount()}} <span i18n="@@blindtestWidget-songs">chansons </span></span>
    </mat-card-subtitle>
    <mat-card-subtitle *ngIf="getSetCount() === 1"><span i18n="@@blindtestWidget-subtitleOneSet">1 set  - </span>{{getItemsCount()}} <span i18n="@@blindtestWidget-subtitleSongs">chansons</span></mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="blindtest.sets">
    <span *ngFor="let set of blindtest.sets; let i = index">
      <div class="set-lbl"><span i18n="@@blindtestWidget-setLabelNum">Manche</span>&nbsp;{{i+1}}</div>
      <div *ngFor="let section of set.sections">
        <span class="section-lbl">{{section.label}}</span>
        <span class="section-size">{{section.endIndex - section.startIndex}} <span i18n="@@blindtestWidget-setLabelSongs">chansons</span></span>
      </div>
    </span>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button [color]="!currentGameState ? 'accent' : ''" [disabled]="!!currentGameState" (click)="activateNow()" class="action-btn">
      <mat-icon [ngClass]="{'light-icon': !blindtest.archived, 'disabled-icon': blindtest.archived || isDemoUser() || !!currentGameState}">toggle_on</mat-icon>
      <ng-container i18n="@@blindtestWidget-activate">Activer</ng-container>
    </button>
    <button mat-raised-button color="primary" [routerLink]="'../blindtests/' + blindtest.id"  class="action-btn">
      <mat-icon [ngClass]="{'light-icon': !blindtest.archived, 'disabled-icon': blindtest.archived || isDemoUser() || !!currentGameState}">info</mat-icon>
      <ng-container i18n="@@blindtestWidget-details">Détails</ng-container>
    </button>
    <button mat-raised-button color="primary" [routerLink]="'../blindtests/edit/' + blindtest.id" [disabled]="isDemoUser()" class="action-btn">
      <mat-icon [ngClass]="{'light-icon': !blindtest.archived, 'disabled-icon': blindtest.archived || isDemoUser() || !!currentGameState}">edit</mat-icon>
      <ng-container i18n="@@blindtestWidget-edit">Éditer</ng-container>
    </button>
    <button mat-raised-button color="primary" (click)="duplicate()" [disabled]="isDemoUser()" class="action-btn">
      <mat-icon [ngClass]="{'light-icon': !blindtest.archived, 'disabled-icon': blindtest.archived || isDemoUser() || !!currentGameState}">file_copy</mat-icon>
      <ng-container i18n="@@blindtestWidget-duplicate">Dupliquer</ng-container>
    </button>
  </mat-card-actions>
  <div [hidden]='!error' class='alert alert-danger'>
    {{ error }}
  </div>
</mat-card>
