<br/>
<mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" i18n-placeholder="@@dashboardBlindtestTable-searchPlaceholder" placeholder="Ex. 'Blindtest Apple'" #input>
</mat-form-field>
<mat-slide-toggle class='switch' (change)="onToggle($event)">
    <div i18n="@@dashboardBlindtestTable-showArchived">Afficher les Blindtests archivés</div>
</mat-slide-toggle>
<br/>
<div class="table-container">
    <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@dashboardBlindtestTable-fieldName">Nom</th>
            <td mat-cell *matCellDef="let row" (click)="goDetails(row.id)" class="hover-pointer">{{row.name}}</td>
        </ng-container>
        <ng-container matColumnDef="set">
            <th mat-header-cell class="col-content" *matHeaderCellDef mat-sort-header i18n="@@dashboardBlindtestTable-fieldContent">Contenu</th>
            <td mat-cell *matCellDef="let row" class="header-set">
                <app-blindtest-details-sets [blindtest]="row"></app-blindtest-details-sets>
            </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell class="col-description" *matHeaderCellDef mat-sort-header i18n="@@dashboardBlindtestTable-fieldDescription">Description</th>
            <td mat-cell *matCellDef="let row" class="header-description">{{row.description}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@dashboardBlindtestTable-fieldStatus">Statut</th>
            <td mat-cell *matCellDef="let row" class="header-description">
                <ng-container *ngIf="isCurrent(row) && !isFinished()" i18n="@@dashboardBlindtestTable-statusOngoing">En cours</ng-container>
                <ng-container *ngIf="isCurrent(row) && isFinished()" i18n="@@dashboardBlindtestTable-statusFinished">Terminé</ng-container>
                <ng-container *ngIf="row.archived" i18n="@@dashboardBlindtestTable-statusArchived">Archivé</ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row" class="header-icons">
                <button [ngClass]="{disabled: row.archived || hasActiveGame}" [disabled]="row.archived || hasActiveGame" mat-icon-button i18n-matTooltip="@@dashboardBlindtestTable-tooltipActivate" matTooltip="Activer ce Blindtest" (click)='activateBlindtest(row)'>
                    <mat-icon [ngClass]="{'accent-icon': !row.archived, 'disabled-icon': row.archived || hasActiveGame}">toggle_on</mat-icon>
                </button>
                <button [ngClass]="{disabled: row.archived}" [disabled]="row.archived || isDemoUser()" mat-icon-button i18n-matTooltip="@@dashboardBlindtestTable-tooltipExport" matTooltip="Exporter le Blindtest" (click)="launchExport(row.id)">
                    <mat-icon [ngClass]="{'light-icon': !row.archived, 'disabled-icon': row.archived}"> vertical_align_bottom</mat-icon>
                </button>
                <button [ngClass]="{disabled: row.archived || hasActiveGame}" [disabled]="row.archived || isDemoUser() || hasActiveGame" mat-icon-button i18n-matTooltip="@@dashboardBlindtestTable-tooltipEdit" matTooltip="Éditer le contenu" [routerLink]="'../blindtests/edit/' + row.id">
                    <mat-icon [ngClass]="{'light-icon': !row.archived, 'disabled-icon': row.archived || isDemoUser() || hasActiveGame}">edit</mat-icon>
                </button>
                <button mat-icon-button i18n-matTooltip="@@dashboardBlindtestTable-tooltipDuplicate" matTooltip="Dupliquer ce Blindtest" (click)="duplicateBlindtest(row)">
                    <mat-icon class="light-icon">file_copy</mat-icon>
                </button>
                <button [ngClass]="{disabled: isDemoUser() || isCurrent(row)}" [disabled]="isDemoUser() || isCurrent(row)" mat-icon-button i18n-matTooltip="@@dashboardBlindtestTable-tooltipDelete" matTooltip="Supprimer ce Blindtest" (click)="onDeleteClicked(row.id)">
                    <mat-icon [ngClass]="{'light-icon': !(isDemoUser() || isCurrent(row)), 'disabled-icon': isDemoUser() || isCurrent(row)}">delete</mat-icon>
                </button>
                <button mat-icon-button aria-label="Actions" [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)='openDisableDialog()' *ngIf='isCurrent(row)' i18n="@@dashboardBlindtestTable-btnDeactivate">Désactiver</button>
                    <button mat-menu-item [routerLink]="'../blindtests/' + row.id" i18n="@@dashboardBlindtestTable-btnDetails">Détails</button>
                    <button mat-menu-item (click)='archiver(row)' *ngIf="!row.archived" i18n="@@dashboardBlindtestTable-btnArchive" [disabled]="isCurrent(row)">Archiver</button>
                    <button mat-menu-item (click)='archiver(row)' *ngIf="row.archived" i18n="@@dashboardBlindtestTable-btnUnarchive">Désarchiver</button>
                </mat-menu>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container *ngIf="dataSource?.data?.length > 0">
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </ng-container>
    </table>
    <ng-container *ngIf="dataSource?.data?.length === 0 || dataSource?.filteredData?.length === 0">
        <div class="empty-table">Vous n'avez pas de blindtests</div>
    </ng-container>
    <mat-paginator [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
</div>
