import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import {AuthService} from '../service/auth.service';
import {LoggerService} from '../../services/logger/logger.service';
import {OrganizationService} from '../../services/organization/organization.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  email = '';
  password = '';
  error: any;
  hide: boolean;

  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl = new UntypedFormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();

  constructor(private authService: AuthService,
              private router: Router,
              private loggerService: LoggerService,
              private organizationService: OrganizationService) {}

  ngOnInit() {
  }

  onSubmit() {
    const org = this.organizationService.getOrganizationTagSnapshot();
    this.authService.emailSignIn(this.email, this.password)
    .then(
      (user) => {
        this.loggerService.debug('User authenticated successfully:');
        if (org == null) {
          this.router.navigate(['/']);
          return;
        }
        this.router.navigate([`/org/${org}/dashboard`]);
    }).catch(
      (err) => {
        this.loggerService.error(err);
        this.error = err;
    });
  }

}
