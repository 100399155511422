import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {AuthService} from '../../core/auth/service/auth.service';
import {GameService} from '../../core/services/game/game.service';
import {EMPTY, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {OrganizationService} from '../../core/services/organization/organization.service';
import {LoggerService} from '../../core/services/logger/logger.service';
import {unsubscribe} from '../../core/handler/subscription-handler';
import {catchError} from 'rxjs/operators';
import {Claims, PropertyClaim} from '@frogconnexion/core-common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit, OnInit, OnDestroy {

  private _blindingSubscription: Subscription;
  hasCurrentGame: boolean;
  error: string;
  orgForm = new UntypedFormGroup({
    organization: new UntypedFormControl('', [Validators.required])
  });

  constructor(private _authService: AuthService,
              private organizationService: OrganizationService,
              private _gameService: GameService,
              private _logger: LoggerService,
              private _router: Router, private _cdr: ChangeDetectorRef) {
    this._blindingSubscription = this.organizationService.organization().subscribe(b => {
      this.hasCurrentGame = b.getBlindingHasCurrentGame();
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    unsubscribe(this._blindingSubscription);
  }

  ngAfterViewInit() {
    this._cdr.detectChanges();
  }

  get organization() {
    return this.orgForm.get('organization');
  }

  onSubmit() {
    const org = this.organization.value.toLowerCase();
    this.organizationService.isValid(org)
      .pipe(catchError((err, caught) => {
        this._logger.error(err);
        this.error = err;
        return EMPTY;
      }))
      .subscribe(
        (result: boolean) => {
          if (result) {
            this._logger.debug(`Game PIN valid. Organization ${org} exists`);
            this._router.navigate([`/org/${org}/dashboard`]);
          } else {
            this._logger.warn('Wrong Game PIN. Organization doesn\'t exist');
            this.error = 'Wrong game PIN';
          }
        });
  }
}
