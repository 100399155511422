import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {ValidationDialogComponent} from '../../../core/components/dialog/validation-dialog/validation-dialog.component';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {ParseUtils} from '../../../core/utils/parse-utils';
import {AuthService} from '../../../core/auth/service/auth.service';
import {OrganizationService} from '../../../core/services/organization/organization.service';
import {BlindtestService} from '../../../core/services/blindtest/blindtest.service';
import {GameService} from '../../../core/services/game/game.service';
import {NavigationService} from '../../../core/services/navigation/navigation.service';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {Build} from '../../../core/models/build';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {AuthUser, Claims, Organization, RoleClaim} from '@frogconnexion/core-common';
import {PermalinkService} from '../../../core/services/permalink/permalink.service';
import {Blindtest, BlindtestImportDescriptor, GameControls, GameMetadata} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-blindtest-table',
    templateUrl: './blindtest-table.component.html',
    styleUrls: ['./blindtest-table.component.scss']
})
export class BlindtestTableComponent implements OnInit, OnChanges, OnDestroy {

    @Input() blindtestList: Blindtest[];
    @Input() currentGameMetadata: GameMetadata;
    @Input() user: AuthUser;
    @Output() refreshDatabase = new EventEmitter<boolean>();
    @Input() currentGameState: GameControls;
    @Input() organization: Organization;
    build: Build;
    env = environment;
    hasActiveGame: boolean;
    canRead = true;
    canWrite = false;
    private createGameSubscription: Subscription;
    private activateDialogSubscription: Subscription;
    private disableDialogSubscription: Subscription;
    private unsetGameSubscription: Subscription;
    private blindingSubscription: Subscription;
    private currentGameStateSubscription: Subscription;
    private currentBlindtestSubscription: Subscription;
    private removeDialogSubscription: Subscription;
    private allBlindtestsSubscription: Subscription;
    private blindtestSubscription: Subscription;
    dataSource: MatTableDataSource<Blindtest>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    displayedColumns = ['name', 'set', 'description', 'status', 'actions'];
    private showtimeBaseUrl: string;
    showArchived = false;

    constructor(private authService: AuthService,
                private organizationService: OrganizationService,
                private permalinkService: PermalinkService,
                private blindtestService: BlindtestService,
                private gameService: GameService,
                private navigationService: NavigationService,
                private dialog: MatDialog,
                private toastr: ToastrService,
                private router: Router) {
        this.showtimeBaseUrl = environment.services.showtimeBaseUrl;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateDatasource();
        this.canRead = this.user?.hasAnyRole([
            RoleClaim.parse(Claims.User.BLINDING_GLOBAL_ROLE_ADMIN),
            RoleClaim.parse(Claims.User.BLINDING_GLOBAL_ROLE_OPERATOR),
            RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_DEMO, this.organization?.organization),
            RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_MANAGER, this.organization?.organization),
            RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_LIMITED, this.organization?.organization)
        ]);
        this.canWrite = this.user?.hasAnyRole([
            RoleClaim.parse(Claims.User.BLINDING_GLOBAL_ROLE_ADMIN),
            RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_MANAGER, this.organization?.organization),
            RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_LIMITED, this.organization?.organization)
        ]);
        this.hasActiveGame = !!this.currentGameState;
    }

    ngOnInit(): void {

    }


    ngOnDestroy() {
        unsubscribe(
            this.currentGameStateSubscription,
            this.blindingSubscription,
            this.currentBlindtestSubscription,
            this.removeDialogSubscription,
            this.blindtestSubscription,
            this.disableDialogSubscription,
            this.createGameSubscription,
            this.unsetGameSubscription,
            this.allBlindtestsSubscription);
    }

    updateDatasource(): void {
        this.dataSource = new MatTableDataSource(this.blindtestList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    launchExport(id: string) {
        this.blindtestService.exportOne(id).subscribe((data) => {
            this.downloadBlindtestFile(data);
        });
    }

    downloadBlindtestFile(data: BlindtestImportDescriptor) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data)));
        element.setAttribute('download', `export-${this.organization}-quiz-${ParseUtils.normalizeLowercaseNoSpace(data.name)}.json`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    onDeleteClicked(blindTestId: string) {
        unsubscribe(this.removeDialogSubscription);
        const dialogRef = this.dialog.open(ValidationDialogComponent, {
            width: '300px',
            data: {action: 'delete-media'}
        });
        this.removeDialogSubscription = dialogRef.afterClosed().subscribe(activate => {
            if (activate) {
                this.blindtestService.delete(blindTestId).subscribe(() => {
                    this.refreshDatabase.next(this.showArchived);
                });
            }
        });
    }

    isCurrent(bt: Blindtest) {
        return this.currentGameMetadata && (bt.id === this.currentGameMetadata.blindtestId);
    }

    isFinished() {
        return this.currentGameState && (this.currentGameState.isFinished());
    }

    duplicateBlindtest(blindtest: Blindtest) {
        this.blindtestService.duplicate(blindtest.id).subscribe(() => {
                this.toastr.success('Blindtest dupliqué avec succès !');
                this.refreshDatabase.next(this.showArchived);
            },
            () => {
                this.toastr.error('Erreur lors de la duplication du Blindtest !');
            }
        );
    }

    activateBlindtest(bt: Blindtest): void {
        if (this.currentGameMetadata) {
            const dialogRef = this.dialog.open(ValidationDialogComponent, {
                width: '300px',
                data: {subtitle: 'Vous allez arrêter le blind-test en cours'}
            });
            this.disableDialogSubscription = dialogRef.afterClosed().subscribe(disable => {
                if (disable) {
                    this.activateNow(bt);
                }
            });
        } else {
            this.activateNow(bt);
        }
    }

    activateNow(bt: Blindtest): void {
        this.router.navigate([`/org/${this.organization.organization}/blindtests/activate/${bt.id}`]);
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    openDisableDialog(): void {
        const dialogRef = this.dialog.open(ValidationDialogComponent, {
            width: '300px',
            data: {subtitle: 'Vous allez arrêter définitivement le blind-test pour tous les utilisateurs'}
        });

        this.disableDialogSubscription = dialogRef.afterClosed().subscribe(disable => {
            if (disable) {
                this.unsetGameSubscription = this.gameService.unsetCurrentGame().subscribe();
            }
        });
    }

    isDemoUser() {
        return this.user?.hasAnyRole([RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_DEMO, this.organization.organization)]);
    }

    goDetails(id: string) {
        this.router.navigate([`../blindtests/`], {queryParams: {catalogId: id}});
    }

    archiver(blindtest: Blindtest) {
        blindtest.archived = !blindtest.archived;
        this.blindtestService.save(blindtest).subscribe(() => {
                this.toastr.success('Blindtest updated !');
                this.refreshDatabase.next(this.showArchived);
            },
            () => {
                this.toastr.error('Erreur lors de la duplication du blindTEst !');
            }
        );
    }

    onToggle(event: MatSlideToggleChange) {
        this.showArchived = event.checked;
        this.refreshDatabase.next(this.showArchived);
    }
}
