import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminAuthGuard} from '../../core/auth/service/guards/admin-auth.guard';
import {RouterModule} from '@angular/router';
import {CoreModule} from '../../core/core.module';
import {MaterialModule} from '../../core/material/material.module';
import {BlindTestDetailsComponent} from './blindtest-details/blindtest-details.component';
import {AboutPageComponent} from './about-page/about-page.component';

const routes = [
  {path: 'org/:organization/blindtest/:id', component: BlindTestDetailsComponent, canActivate: [AdminAuthGuard]},
  {path: 'org/:organization/about', component: AboutPageComponent, canActivate: [AdminAuthGuard]},
];

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    CoreModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    BlindTestDetailsComponent,
    AboutPageComponent
  ]
})
export class AdminModule {
}
