import {Component, Input, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {Song} from '@frogconnexion/blinding-common';

@Component({
  selector: 'bt-item-widget',
  templateUrl: './item-widget.component.html',
  styleUrls: ['./item-widget.component.scss']
})
export class ItemWidgetComponent implements OnInit {

  constructor(private _router: Router, ) { }

  @Input()
  item: Song;
  @Input()
  selectable = false;
  @Input()
  showImage = true;
  @Input()
  compact = false;
  @Input()
  onItemSelected: Function = () => {}

  ngOnInit() {
  }

}
