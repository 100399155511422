import {Component, Input, OnInit} from '@angular/core';
import {LeaderboardEntry} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-leaderboard-entry',
  templateUrl: './player-widget.component.html',
  styleUrls: ['./player-widget.component.scss']
})
export class PlayerWidgetComponent implements OnInit {

  @Input() entry: LeaderboardEntry;
  @Input() ranking: number;
  constructor() { }

  ngOnInit() {
  }

}
