<nav>
  <div fxLayout.gt-sm='row wrap' fxLayoutAlign.gt-sm='center center' fxLayout='column'
       fxLayoutGap='10px' *ngIf="blindtest">
    <mat-card>
        <mat-card-title>{{blindtest.name}}</mat-card-title>
        <mat-card-subtitle>
          <mat-chip-list *ngIf="isCurrentGame">
            <mat-chip *ngIf="currentGameState.blindtestControl.status === 0" i18n="@@blindtestDetails-gameReady">Prêt</mat-chip>
            <mat-chip *ngIf="currentGameState.blindtestControl.status === 1" i18n="@@blindtestDetails-gameInProgress">En cours de jeu</mat-chip>
            <mat-chip *ngIf="currentGameState.blindtestControl.status === 2" i18n="@@blindtestDetails-gamePaused">En pause</mat-chip>
            <mat-chip *ngIf="currentGameState.blindtestControl.status === 3" i18n="@@blindtestDetails-gameFinished">Terminé</mat-chip>
          </mat-chip-list>
        </mat-card-subtitle>
      </mat-card>
    <div *ngFor='let bs of blindSets; let i = index;'>
      <mat-card>
        <mat-card-title><span i18n="@@blindtestDetails-titleSetNumber">Manche</span> {{i + 1}} - {{bs.songs.length}} <span i18n="@@blindtestDetails-titleSongs">chansons</span></mat-card-title>
        <mat-card-subtitle>
          <mat-chip-list *ngIf='isCurrentGame'>
            <mat-chip color="accent" i18n="@@blindtestDetails-setInProgress" *ngIf='currentGameState.blindtestControl.current === i'>Jeu en cours</mat-chip>
            <mat-chip color="primary" i18n="@@blindtestDetails-setReady" *ngIf='currentGameState.blindtestControl.current != i'>Prêt</mat-chip>
            <mat-chip color="accent" *ngIf='currentGameState.blindtestControl.current === i'><span i18n="@@blindtestDetails-setCountSong">Chanson</span>
              {{currentGameState.setControl.current + 1}} / {{currentGameState.setControl.songCount}}
            </mat-chip>
          </mat-chip-list>
        </mat-card-subtitle>
        <mat-card-content>
          <h3 i18n="@@blindtestDetails-title">Sections: </h3>
          <div *ngFor="let section of bs.sections">
            <p><b>{{section.label}} - {{section.endIndex - section.startIndex}} <span  i18n="@@blindtestDetails-songs">chansons</span></b></p>
            <ul>
              <li *ngFor='let i of section.indexes()'>
                <span *ngIf='section.type === 0'>{{bs.songs[i].artist}} - {{bs.songs[i].title}}</span>
                <span *ngIf='section.type === 1'>{{bs.songs[i].lyrics}}</span>
              </li>
            </ul>
          </div>


        </mat-card-content>
      </mat-card>
    </div>
  </div>
</nav>
