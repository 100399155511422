<nav *ngIf="hasCurrentGame" fxLayout.gt-sm='row wrap' fxLayoutAlign.gt-sm='center center' fxLayout='column'
     fxLayoutGap='10px'>

  <!-- Otherwise show admin section -->
  <div>
    <mat-card>
      <mat-card-title>{{currentGameMetadata.name}}</mat-card-title>
      <mat-card-subtitle>
        <!-- Game Status -->
        <mat-chip-list>
          <mat-chip>PIN:{{currentGameMetadata.pin.toUpperCase()}}</mat-chip>
          <mat-chip *ngIf='currentGameControls.blindtestControl.status === 0' i18n="@@videoControl-gameStatusReady">Prêt</mat-chip>
          <mat-chip *ngIf="!playerCount"><span i18n="@@videoControl-noPlayers">0 Joueurs</span></mat-chip>
          <mat-chip *ngIf="playerCount && playerCount != 1">{{playerCount}} <span i18n="@@videoControl-playerCount">Joueurs</span></mat-chip>
          <mat-chip *ngIf="playerCount === 1" i18n="@@videoControl-onePlayer">1 Joueur</mat-chip>
          <mat-chip color="primary" *ngIf='currentGameControls.blindtestControl.status === 1' i18n="@@videoControl-gameStatusOnGoing">En cours</mat-chip>
          <mat-chip color="accent" *ngIf='currentGameControls.blindtestControl.status === 2' i18n="@@videoControl-gameStatusOnPaused">En pause</mat-chip>
          <mat-chip color="accent" *ngIf='currentGameControls.blindtestControl.isRunning()'>
            <span i18n="@@videoControl-gameIsRunning">Set N°</span>
            {{currentGameControls.blindtestControl.current + 1}} / {{currentGameControls.blindtestControl.setCount}}
          </mat-chip>
          <mat-chip *ngIf='currentGameControls.blindtestControl.status === 3' i18n="@@videoControl-gameStatusFinish">Terminé</mat-chip>
        </mat-chip-list>
      </mat-card-subtitle>
      <mat-card-content>
        <!-- Game Controls when game is OnGoing -->
        <button mat-raised-button color="primary" *ngIf='currentGameControls.blindtestControl.isRunning()'
                routerLink="../game/bonus" i18n="@@videoControl-gameBonus">Bonus
        </button>
        <button mat-raised-button color="primary" *ngIf='currentGameControls.blindtestControl.isRunning()'
                routerLink="../game/malus" i18n="@@videoControl-gameMalus">Malus
        </button>
      </mat-card-content>
    </mat-card>
    <app-showtime-controls *ngIf="currentShowtimeSettings"
                           [currentGameMetadata]="currentGameMetadata"
                           [currentGameControls]="currentGameControls"
                           [currentShowtimeSettings]="currentShowtimeSettings"></app-showtime-controls>
  </div>
</nav>
