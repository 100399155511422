<div class="main-container">
    <img class="avatar" src="{{player.avatar}}" alt="{{player.name}}">
    <div class="container">
        <div class="header-container">
            <h4 class="name">{{player.name}}</h4>
            <div class="mention" *ngIf="mention === 'fastest'" i18n="@@playerMentionWidget-playerMentionFastest">Le plus rapide!
            </div>
            <div class="mention" *ngIf="mention === 'slowest'" i18n="@@playerMentionWidget-playerMentionSlowest">Le... plus lent!
            </div>
            <div class="mention" *ngIf="mention === 'winner'" i18n="@@playerMentionWidget-playerMentionSoleWinner">Le seul gagnant !
            </div>
            <div class="mention" *ngIf="mention === 'loser'" i18n="@@playerMentionWidget-playerMentionSoleLoser">Seul... perdant !
            </div>
        </div>
        <div class="comment" *ngIf="mention === 'winner'" i18n="@@playerMentionWidget-playerMentionSoleWinnerComment">était le seul qui a bien compris
        </div>
        <div class="comment" *ngIf="mention === 'loser'" i18n="@@playerMentionWidget-playerMentionSoleLoserComment">était le seul à avoir tout faux
        </div>
        <div class="comment" *ngIf="mention === 'fastest' && isCorrect()"
             i18n="@@playerMentionWidget-playerMentionFastestRightComment"> respect : le plus rapide ET 100 % correct
        </div>
        <div class="comment" *ngIf="mention === 'fastest' && isPartiallyCorrect()"
             i18n="@@playerMentionWidget-playerMentionFastestAlmostRightComment">...Et seulement la moitié des points pour avoir foiré !
        </div>
        <div class="comment" *ngIf="mention === 'fastest' && isIncorrect()"
             i18n="@@playerMentionWidget-playerMentionFastestIncorrectComment">...Et le plus rapide à échouer !
        </div>
        <div class="comment" *ngIf="mention === 'slowest' && isCorrect()"
             i18n="@@playerMentionWidget-playerMentionSlowestRightComment">Être lent rapporte : 100% correct
        </div>
        <div class="comment" *ngIf="mention === 'slowest' && isPartiallyCorrect()"
             i18n="@@playerMentionWidget-playerMentionSlowestAlmostRightComment">... WoOps!
        </div>
        <div class="comment" *ngIf="mention === 'slowest' && isIncorrect()"
             i18n="@@playerMentionWidget-playerMentionSlowestIncorrectComment">... Et s'est toujours trompé !
        </div>
    </div>
</div>
