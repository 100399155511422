import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';

import {distinctUntilChanged, map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

import {OrganizationService} from '../../../core/services/organization/organization.service';
import {GameService} from '../../../core/services/game/game.service';
import {ErrorHandler} from '../../../core/handler/error-handler';
import {LoggerService} from '../../../core/services/logger/logger.service';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {ShowtimeSettings} from '@frogconnexion/blinding-common';

const SHOWTIME_KEY = 'showtime_settings';

@Injectable({
    providedIn: 'root'
})
export class ShowtimeService {
    private org: string;
    private showtimeSettingsSubscription: Subscription;
    private showtimeSettingsSubject: Subject<ShowtimeSettings>;

    constructor(private fb: AngularFireDatabase,
                private organizationService: OrganizationService,
                private gameService: GameService,
                private errorHandler: ErrorHandler,
                private logger: LoggerService,
                private http: HttpClient) {

        this.showtimeSettingsSubject = new ReplaySubject(1);

        this.organizationService.organizationTag().subscribe(o => {
            this.org = o;
            if (o) {
                unsubscribe(this.showtimeSettingsSubscription);
                this.showtimeSettingsSubscription = this.fb.object<ShowtimeSettings>(`/blinding/showtime/${o}`).valueChanges()
                    .subscribe(ss => {
                        this.showtimeSettingsSubject.next(ss);
                    });
            }
        });


    }

    launchScreen(screen: string): Observable<boolean> {
        return this.http.post<boolean>(`/showtime/org/${this.org}/screen/${screen}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }


    setTimeOfShow(date: Date): Observable<boolean> {
        return this.http.post<boolean>(`/showtime/org/${this.org}/timeofshow/${date.toISOString()}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    backtrackVolume(): Observable<number> {
        return this.showtimeSettingsSubject.pipe(map((ss) => ss?.controls?.backtrackVolume), distinctUntilChanged());
    }

    backtrackMuted(): Observable<boolean> {
        return this.showtimeSettingsSubject.pipe(map((ss) => ss?.controls?.backtrackMuted), distinctUntilChanged());
    }

    showtimeSettings(): Observable<ShowtimeSettings> {
        return this.showtimeSettingsSubject.pipe(distinctUntilChanged());
    }

    showtime(): Observable<string> {
        return this.showtimeSettingsSubject.pipe(map((ss) => ss?.controls?.showtime), distinctUntilChanged());
    }

    currentlyOnScreen(): Observable<string> {
        return this.showtimeSettingsSubject.pipe(map((ss) => ss?.controls?.currentlyOnScreen), distinctUntilChanged());
    }

    setBacktrackVolume(volume: number): Observable<number> {
        return this.http.post<number>(`/showtime/org/${this.org}/backtrack/volume/${volume}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    setBacktrackMuted(muted: boolean): Observable<boolean> {
        return this.http.post<boolean>(`/showtime/org/${this.org}/backtrack/muted/${muted}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

}
