import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from '../../../core/auth/service/auth.service';

import {BlindtestService} from '../../../core/services/blindtest/blindtest.service';

import {MatDialog} from '@angular/material/dialog';
import {ValidationDialogComponent} from '../../../core/components/dialog/validation-dialog/validation-dialog.component';
import {GameService} from '../../../core/services/game/game.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {ScoreService} from '../../../core/services/score/score.service';
import {OrganizationService} from '../../../core/services/organization/organization.service';

import {environment} from '../../../../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {AuthUser, Claims, Organization, RoleClaim} from '@frogconnexion/core-common';
import {PermalinkService} from '../../../core/services/permalink/permalink.service';
import {Blindtest, Scoresheet, GameControls, GameMetadata, Gameplay} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-blindtest-widget',
    templateUrl: './blindtest-widget.component.html',
    styleUrls: ['./blindtest-widget.component.scss']
})
export class BlindTestWidgetComponent implements OnChanges, OnDestroy {
    @Input() user: AuthUser;
    @Input() organization: Organization;
    @Input() blindtest: Blindtest;
    @Input() currentGameState: GameControls;
    @Input() currentGameMetadata: GameMetadata;

    isCurrent: boolean;
    isFinished: boolean;
    error: any;
    showtimeBaseUrl: string;
    isDisable: boolean;

    private activateDialogSubscription: Subscription;
    private disableDialogSubscription: Subscription;
    private createGameSubscription: Subscription;
    private unsetGameSubscription: Subscription;
    private removeDialogSubscription: Subscription;

    constructor(
        private organizationService: OrganizationService,
        private permalinkService: PermalinkService,
        private authService: AuthService, private _blindTestService: BlindtestService,
        private gameService: GameService, private _scoreService: ScoreService,
        private toastr: ToastrService,
        private router: Router, private _dialog: MatDialog) {
        this.showtimeBaseUrl = environment.services.showtimeBaseUrl;
    }



    ngOnChanges() {
        this.isCurrent = this.currentGameMetadata && (this.blindtest.id === this.currentGameMetadata.blindtestId);
        this.isFinished = this.currentGameState && (this.currentGameState.isFinished());
    }

    ngOnDestroy() {
        unsubscribe(
            this.activateDialogSubscription,
            this.disableDialogSubscription,
            this.createGameSubscription,
            this.unsetGameSubscription,
            this.removeDialogSubscription);
    }

    activateNow(): void {
        this.router.navigate([`/org/${this.organization.organization}/blindtests/activate/${this.blindtest.id}`]);
    }

    onDeleteClicked() {
        unsubscribe(this.removeDialogSubscription);
        const dialogRef = this._dialog.open(ValidationDialogComponent, {
            width: '300px',
            data: {action: 'delete-media'}
        });
        this.removeDialogSubscription = dialogRef.afterClosed().subscribe(activate => {
            if (activate) {
                this._blindTestService.delete(this.blindtest.id).subscribe(() => {
                    window.location.reload();
                });
            }
        });
    }

    getGameplay() {
        return Gameplay.name(this.currentGameMetadata.scoreStrategy);
    }

    getSetCount() {
        if (!this.blindtest || !this.blindtest.sets) {
            return 0;
        }
        return this.blindtest.sets.length;
    }

    getItemsCount() {
        if (!this.blindtest || !this.blindtest.sets) {
            return 0;
        }
        let count = 0;
        this.blindtest.sets.forEach(s => {
            if (!s.sections) {
                return;
            }
            s.sections.forEach(sc => count += sc.endIndex - sc.startIndex);
        });
        return count;
    }

    exportScoresheet() {
        this._scoreService.export().subscribe((scores) => {
            // tslint:disable-next-line:forin
            for (const i in scores) {
                this.downloadFile(scores[i]);
            }
        });
    }

    downloadFile(data: Scoresheet) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data.payload));
        element.setAttribute('download', `Leblindtest.fr - ${this.blindtest.name} - ${data.prefix} - set ${data.set + 1}.csv`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    isDemoUser(): boolean {
        return this.user?.hasAnyRole([RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_DEMO, this.organization.organization)]);
    }

    duplicate() {
        this._blindTestService.duplicate(this.blindtest.id).subscribe(() => {
                this.toastr.success('Blindtest dupliqué avec succès !');
                window.location.reload();
            },
            () => {
                this.toastr.error('Erreur lors de la duplication du Blindtest !');
            }
        );
    }
}
