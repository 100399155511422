import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

import {LoggerService} from '../../../core/services/logger/logger.service';
import {ReviewService} from '../../../core/services/review/review.service';
import {MatchResult, SongPendingReview} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-submit-review-widget',
  templateUrl: './submit-review-widget.component.html',
  styleUrls: ['./submit-review-widget.component.scss']
})
export class SubmitReviewWidgetComponent implements OnInit, OnChanges {

  constructor(private _reviewService: ReviewService,
              private _logger: LoggerService) {
  }

  @Input() pendingReview: SongPendingReview;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }


  submitReview() {
    this.pendingReview.review = this.pendingReview.review.map(v => {
      if (v === MatchResult.Unsolved) {
        return MatchResult.Incorrect;
      }
      return v;
    });
    this._reviewService.solvePendingReview(this.pendingReview).subscribe();
  }

}
