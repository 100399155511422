import {Injectable} from '@angular/core';

import {AngularFireDatabase} from '@angular/fire/compat/database';

import {Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {BlindtestService} from '../blindtest/blindtest.service';
import {GameService} from '../game/game.service';
import {HttpClient} from '@angular/common/http';
import {ErrorHandler} from '../../handler/error-handler';
import {OrganizationService} from '../organization/organization.service';
import {GameControls, Song} from '@frogconnexion/blinding-common';

@Injectable({
  providedIn: 'root'
})
export class GamePublicControlsService {

  private _currentGameStateObservable: Observable<GameControls>;
  private _currentGameState: GameControls;
  private _currentSongObservable: Observable<Song>;
  private _currentSong: Song;
  private _organization: string;

  constructor(private _fb: AngularFireDatabase,
              private _blindingService: OrganizationService,
              private _blindtestService: BlindtestService,
              private _http: HttpClient,
              private _gameService: GameService,
              private _errorHandler: ErrorHandler) {

    this._blindingService.organizationTag().subscribe(o => {
      this._organization = o;
    });
    // Current Game State observable
    this._currentGameStateObservable = this._gameService.currentGameControls();
    this._currentGameStateObservable.subscribe(g => {
      // Replay all game-specific observables
      this._currentGameState = g;
    });
    // Current Song observable
    this._currentSongObservable = this._gameService.currentSong();
    this._currentSongObservable.subscribe(s => {
      this._currentSong = s;
    });

  }

  // Observables

  previewLeaderboard(): Observable<boolean> {
    return this._currentGameStateObservable.pipe(map(gs => gs ? gs.blindtestControl.showLeaderboardPreview : false)).pipe(distinctUntilChanged());
  }

  revealLeaderboard(): Observable<boolean> {
    return this._currentGameStateObservable.pipe(map(gs => gs ? gs.blindtestControl.showLeaderboard : false)).pipe(distinctUntilChanged());
  }

  enableJokers(): Observable<boolean> {
    return this._currentGameStateObservable.pipe(map(gs => gs ? gs.blindtestControl.enableJokers : false)).pipe(distinctUntilChanged());
  }

  lockPlayers(): Observable<boolean> {
    return this._currentGameStateObservable.pipe(map(gs => gs ? gs.blindtestControl.lockPlayers : false)).pipe(distinctUntilChanged());
  }

}
