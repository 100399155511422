<mat-list>
    <cdk-virtual-scroll-viewport itemSize="100" class="example-viewport">
        <mat-form-field class="full-width">
            <input matInput class="search" spellcheck="false" autocomplete="off" i18n-placeholder="@@playerSelector-searchNameLabel" placeholder="Nom de la recherche" [(ngModel)]="search" (ngModelChange)="searchByName(search)" value=""/>
        </mat-form-field>
        <mat-divider></mat-divider>

        <span *cdkVirtualFor="let i = index; let player of currentDs">
          <mat-list-item *ngIf="player" (click)="onPlayerSelected(player)">
            <img matListAvatar src="{{player.avatar}}" alt="{{player.name}}">
            <h4 matLine="player">{{player.name}}</h4>
          </mat-list-item>
          <mat-list-item *ngIf="!player">
            <h4 matLine class="player">--</h4>
          </mat-list-item>
        </span>
    </cdk-virtual-scroll-viewport>
    <mat-divider></mat-divider>
</mat-list>
