import {Router} from '@angular/router';
import {Injectable, OnDestroy, Optional} from '@angular/core';

import {
    Auth,
    authState,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup, signOut,
    User as FirebaseUser,
    UserCredential,
    FacebookAuthProvider, GoogleAuthProvider,
} from '@angular/fire/auth';

import {LoggerService} from '../../services/logger/logger.service';
import {from, Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {AvatarService} from './avatar.service';
import {startWith, switchMap, tap} from 'rxjs/operators';
import {unsubscribe} from '../../handler/subscription-handler';
import {environment} from '../../../../../environments/environment';
import {AuthUser, AuthUserState} from '@frogconnexion/core-common';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {

    private latestAuthState: FirebaseUser | null = null;
    private readonly idTokenSubject: Subject<string>;
    private readonly currentUserSubject: Subject<AuthUser>;
    private readonly subscription: Subscription;


    ngOnDestroy(): void {
        unsubscribe(this.subscription);
    }

    private getNotAuthenticatedDummyUser(state: AuthUserState) {
        const user = AuthUser.anonymousUnauthenticated();
        user.state = state;
        return user;
    }

    constructor(@Optional() private auth: Auth,
                private router: Router,
                private logger: LoggerService,
                private avatarService: AvatarService) {
        this.logger.info('Creating new Auth Service');
        this.currentUserSubject = new ReplaySubject<AuthUser>(1);
        this.idTokenSubject = new ReplaySubject<string>(1);

        // Everytime firebase has a new auth state, it will trigger this action here.
        // Does it handle disconnects? Maybe let's see later
        this.subscription = authState(this.auth)
            .pipe(switchMap((as: FirebaseUser) => {
                this.logger.debug('Received user from authState', as);
                this.latestAuthState = as;
                if (!as) {
                    this.logger.debug('User from authState is null. Set to anonymous');
                    // We have checked and there are no logged in users for now. Set to anonymous
                    const user = {user: this.getNotAuthenticatedDummyUser(AuthUserState.COMPLETE), idToken: null};
                    return of(user);
                }
                this.logger.debug('User from authState is not null. Refreshing token');
                // There is one logged in user. Fetch his metadata and his idtoken
                return this.refresh(as, false);
            }))
            .pipe(startWith((JSON.parse(localStorage.getItem(`${environment.globalNamespace}:user`)) || {
                user: this.getNotAuthenticatedDummyUser(AuthUserState.COMPLETE),
                idToken: null
            })))
            .subscribe((result) => {
                this.publishAuth(result);
            });

    }

    currentUserObservable(): Observable<AuthUser> {
        return this.currentUserSubject;
    }

    currentIdToken(): Observable<string> {
        return this.idTokenSubject;
    }

    refreshFromAuthState() {
        return this.refresh(this.latestAuthState);
    }

    refresh(as: FirebaseUser, publishAfterRefresh: boolean = true): Observable<any> {
        return from(this.refreshUserDetails(as))
            .pipe(tap((result) => {
                localStorage.setItem(`${environment.globalNamespace}:user`, JSON.stringify(result));
                if (publishAfterRefresh) {
                    this.publishAuth(result);
                }
            }));
    }


    private publishAuth(result: { user: AuthUser, idToken: string }) {
        if (result) {
            this.logger.info('Auth Published');
            this.logger.info(result);
            this.idTokenSubject.next(result.idToken);
            this.currentUserSubject.next(AuthUser.fromObject(result.user));
        } else {
            this.logger.info('Null Auth Published');
            this.idTokenSubject.next(null);
            this.currentUserSubject.next(null);
        }
    }

    async refreshUserDetails(as: FirebaseUser): Promise<{ user: AuthUser, idToken: string }> {
        const idToken = await as.getIdTokenResult(true);
        const user = AuthUser.fromIam(as, idToken.claims);
        this.idTokenSubject.next(idToken.token);
        return {user: user, idToken: idToken.token};
    }

    registerUser(email: string, password: string): Promise<UserCredential> {
        return createUserWithEmailAndPassword(this.auth, email, password)
            .then((result) => {
                this.logger.debug('User created in IAM');
                return result;
            });
    }

    googleSignIn(): Promise<UserCredential> {
        return signInWithPopup(this.auth, new GoogleAuthProvider());
    }

    facebookSignIn(): Promise<UserCredential> {
        return signInWithPopup(this.auth, new FacebookAuthProvider());
    }

    emailSignIn(email: string, password: string): Promise<UserCredential> {
        return signInWithEmailAndPassword(this.auth, email, password)
            .catch(error => {
                this.logger.error(error);
                throw error;
            });
    }

    signOut(): Promise<any> {
        localStorage.removeItem(`${environment.globalNamespace}:user`);
        return signOut(this.auth)
            .then(() => {
                this.idTokenSubject.next(null);
                this.currentUserSubject.next(this.getNotAuthenticatedDummyUser(AuthUserState.NEEDS_REFRESH));
            });
    }
}
