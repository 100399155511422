import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from '../service/auth.service';
import {Subscription} from 'rxjs';
import {OrganizationService} from '../../services/organization/organization.service';
import {Claims, RoleClaim} from '@frogconnexion/core-common';


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
    error: any;
    private authenticated: boolean;
    private _currentUserSubscription: Subscription;
    private authorizedUser: boolean;
    organizations: string[] = [];

    constructor(private _authService: AuthService,
                private _blindingService: OrganizationService,
                private _router: Router) {
    }

    get isLogged(): boolean {
        return this.authenticated;
    }

    get isAdmin(): boolean {
        return this.authorizedUser;
    }

    ngOnInit() {
        this._currentUserSubscription = this._authService.currentUserObservable()
            .subscribe(user => {
                this.authenticated = user && !user.anonymous;
                this.authorizedUser = user && user.hasAnyRole([
                        RoleClaim.parse(Claims.User.BLINDING_GLOBAL_ROLE_ADMIN),
                        RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_DEMO),
                        RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_LIMITED),
                        RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_MANAGER)
                    ]
                );
                this.organizations = [...(user?.getRolesForScope(Claims.Apps.BLINDING).filter(r => !r.isGlobal()).map(r => r.organization) || [])];
            });
    }

    ngOnDestroy() {
        if (this._currentUserSubscription) {
            this._currentUserSubscription.unsubscribe();
        }
    }

    registerFb() {
        this._authService.facebookSignIn()
            .catch(err => {
                this.error = err;
            });

    }

    registerGoogle() {
        this._authService.googleSignIn()
            .catch(err => {
                this.error = err;
            });
    }

    refreshAuth() {
        this._authService.refreshFromAuthState().subscribe();
    }
}
