<div class="header-container">
    <div *ngIf="currentBlindtest">
        <app-blindtest-active-widget [blindtest]="currentBlindtest" [user]="currentUser" [currentGameState]='currentGameControls'
                              [currentGameMetadata]="currentGameMetadata"
                              [organization]="organization">
        </app-blindtest-active-widget>
    </div>
    <button class="hide-mobile" routerLink="../blindtests/create" mat-raised-button color="accent"
            [disabled]="isDemoUser()"
            style="float: right">
        <mat-icon>library_add</mat-icon>
        <ng-container i18n="@@dashboard-newBlindtest">Créer un Blindtest</ng-container>
    </button>
    <app-import-blindtest class="hide-mobile" style="float: right" (events)="onImportUpdate($event)"></app-import-blindtest>
    <button class="hide-mobile" routerLink="../scoresheets/search" mat-raised-button color="primary"
            [disabled]="isDemoUser()"
            style="margin-right: 4px; float: right">
        <mat-icon>search</mat-icon>
        <ng-container i18n="@@dashboard-newBlindtest">Recherche Scores</ng-container>
    </button>
</div>
<div class="container dark">
    <div class="show-mobile card-list-container">
        <mat-card>
            <button class="show-mobile" routerLink="../blindtests/create" mat-raised-button color="accent"
                    [disabled]="isDemoUser()">
                <mat-icon>library_add</mat-icon>
                <ng-container i18n="@@dashboard-newBlindtest">Créer un Blindtest</ng-container>
            </button>
        </mat-card>

        <app-blindtest-card-list [currentGameMetadata]="currentGameMetadata"
                                 [currentGameControls]="currentGameControls"
                                 [user]="currentUser"
                                 [organization]="organization"
                                 [blindtestList]="blindtests" (refreshDatabase)="reloadBlindtests($event)"></app-blindtest-card-list>
    </div>
    <div class="hide-mobile">
        <app-blindtest-table [currentGameMetadata]="currentGameMetadata"
                             [user]="currentUser"
                             [organization]="organization"
                             [blindtestList]="blindtests" (refreshDatabase)="reloadBlindtests($event)"></app-blindtest-table>
    </div>

</div>



