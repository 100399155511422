<mat-toolbar>
  <div class="container">
    <!-- Avatar -->
    <img class="logo-bubble" src="/assets/imgs/logo.png" />
    <!-- Title -->
    <div class="title">
      <span *ngIf="header === 'quiz.edit'" i18n="@@navbar-titleQuizEdit">Éditer un Quiz</span>
      <span *ngIf="header === 'quiz.create'" i18n="@@navbar-titleQuizCreate">Créer un Quiz</span>
      <span *ngIf="header === 'dashboard'" i18n="@@navbar-titleDashboard">Dashboard</span>
      <span *ngIf="header === 'quiz.activate'" i18n="@@navbar-titleQuizActivate">Activer une Partie</span>
      <span *ngIf="header === 'library'" i18n="@@navbar-titleLibrary">Bibliothèque</span>
      <span *ngIf="!(header)" i18n="@@navbar-title">Blinding - Manager</span>
    </div>
    <span class="fill-remaining-space"></span>

    <div fxLayout="row" fxShow="false" fxShow.gt-sm>
      <button *ngIf="!authenticated" routerLink="/" mat-button i18n="@@navbar-portal">Portail</button>
      <button *ngIf="authorizedUser && organization" [routerLink]="'/org/' + organization + '/dashboard'" mat-button i18n="@@navbar-dashboard">Tableau de bord</button>
      <button *ngIf="authorizedUser && organization" [routerLink]="'/org/' + organization + '/library'" mat-button i18n="@@navbar-library">Bibliothèque</button>
      <button *ngIf="authenticated" (click)="logout()" mat-button>Logout</button>
    </div>
    <button mat-icon-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</mat-toolbar>

<mat-menu class="sub-menu" fxLayout="column" x-position="before" #menu="matMenu" [overlapTrigger]="false">
  <button [routerLink]="'/org/' + organization + '/dashboard'" mat-menu-item i18n="@@navbar-reception">Accueil</button>
  <button *ngIf="authorizedUser" [routerLink]="'/org/' + organization + '/library'" mat-menu-item i18n="@@navbar-library">Bibliothèque</button>
  <button *ngIf="authenticated" (click)="logout()" mat-menu-item i18n="@@navbar-logout">Se déconnecter</button>
</mat-menu>
