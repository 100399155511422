<div class="container">
  <div class="meta-container">
    <h3>{{bt.name}}</h3>
    <p>{{bt.description}}</p>
  </div>
  <div class="set-container" *ngFor="let set of bt.sets; let i = index;">
    <div class="set-title"><span i18n="@@reviewWidget-setSongCount">Manche </span>{{i + 1}}</div>
    <div class="set-songcount">
      {{getSongCount(set)}} <span i18n="@@reviewWidget-setSongCountItems">éléments</span>
    </div>
    <hr/>
    <div class="set-content">
      <div *ngFor="let section of set.sections">
        <bt-section-review-widget [section]="section"></bt-section-review-widget>
        <br/>
      </div>
    </div>
    <br/><br/>
  </div>
</div>
