import {Component, Input, OnInit} from '@angular/core';
import {Blindtest} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-blindtest-details-sets',
  templateUrl: './blindtest-details-sets.component.html',
  styleUrls: ['./blindtest-details-sets.component.scss']
})
export class BlindtestDetailsSetsComponent implements OnInit {

  expanded = false;
  @Input() blindtest: Blindtest;

  constructor() { }

  ngOnInit(): void {
  }

  toggleWrap() {
    return this.expanded = !this.expanded;
  }

}
