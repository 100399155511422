import {Injectable} from '@angular/core';

import {AngularFireDatabase} from '@angular/fire/compat/database';

import {Observable, ReplaySubject, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from '../logger/logger.service';
import {OrganizationService} from '../organization/organization.service';
import {ErrorHandler} from '../../handler/error-handler';
import {Build} from '../../models/build';

import {Claims, PropertyClaim} from '@frogconnexion/core-common';
import {Blindtest, BlindtestCreationDescriptor, BlindtestImportDescriptor} from '@frogconnexion/blinding-common';

@Injectable({
  providedIn: 'root'
})
export class BlindtestService {

  private _blindtestSubject: Subject<Blindtest>;
  private _currentBlindTest: Blindtest;
  private _organization: string;

  constructor(private _fb: AngularFireDatabase,
              private _blindingService: OrganizationService,
              private _http: HttpClient,
              private _logger: LoggerService,
              private _errorHandler: ErrorHandler) {

    this._blindtestSubject = new ReplaySubject(null);
    this._blindingService.organization().subscribe(o => {
      this._organization = o?.organization;
      // If blinding has changed, compute and emit next blind test value
      const claim = PropertyClaim.parse(Claims.Organization.BLINDING_GLOBAL_PROP_HAS_CURRENT_GAME);
      const shouldLoadBlindtest: boolean = o?.properties.find(p => p.claimKey === claim.claimKey)?.value === true;
      if (shouldLoadBlindtest) {
        this._loadCurrent().then(bt => this._updateCurrent(bt));
      } else {
        this._updateCurrent(null);
      }
    });
  }

  private _updateCurrent(value: Blindtest) {
    this._currentBlindTest = value;
    this._blindtestSubject.next(value);
  }

  private _loadCurrent(): Promise<Blindtest> {
    // tslint:disable-next-line:max-line-length
    return this._fb.object<Blindtest>(`blinding/games/${this._organization}/game/blindtest`).query.once('value').then(v => v ? v.val() : null);
  }

  // Async methods

  currentBlindtest(): Observable<Blindtest> {
    return this._blindtestSubject;
  }

  get(id: string): Observable<Blindtest> {
    const organization = this._blindingService.getOrganizationTagSnapshot();
    return this._http.get<Blindtest>(`/admin/org/${organization}/blindtests/${id}`)
      .pipe(map(bt => Blindtest.fromDto(bt)))
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  findAll(showArchived: boolean = false): Observable<Blindtest[]> {
    const organization = this._blindingService.getOrganizationTagSnapshot();
    return this._http.get<Blindtest[]>(`/admin/org/${organization}/blindtests?show-archived=${showArchived}`)
      .pipe(map(bts => bts.map(bt => Blindtest.fromDto(bt))))
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  build(): Observable<Build> {
    return this._http.get<Build>('/public/build')
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  save(blindtest: Blindtest): Observable<Blindtest> {
    const organization = this._blindingService.getOrganizationTagSnapshot();
    return this._http.post<Blindtest>(`/admin/org/${organization}/blindtests`, blindtest)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  delete(id: string): Observable<any> {
    const organization = this._blindingService.getOrganizationTagSnapshot();
    return this._http.delete<any>(`/admin/org/${organization}/blindtests/${id}`)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  import(descriptor: BlindtestImportDescriptor): Observable<Blindtest> {
    const organization = this._blindingService.getOrganizationTagSnapshot();
    return this._http.post<Blindtest>(`/admin/org/${organization}/blindtests/import`, descriptor)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  export(): Observable<BlindtestImportDescriptor[]> {
    const organization = this._blindingService.getOrganizationTagSnapshot();
    return this._http.get<BlindtestImportDescriptor[]>(`/admin/org/${organization}/blindtests/export`)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  exportOne(id: string): Observable<BlindtestImportDescriptor> {
    const organization = this._blindingService.getOrganizationTagSnapshot();
    return this._http.get<BlindtestImportDescriptor>(`/admin/org/${organization}/blindtests/export/${id}`)
        .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  duplicate(id: string): Observable<Blindtest> {
    const organization = this._blindingService.getOrganizationTagSnapshot();
    return this._http.post<Blindtest>(`/admin/org/${organization}/blindtests/duplicate/${id}`, null)
        .pipe(this._errorHandler.retryThreeTimesOrError());
  }
}
