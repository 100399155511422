<div class="header-container">
    <mat-form-field>
        <mat-label i18n="@@dashboardScoresheetTable-searchLabel">Rechercher une partie</mat-label>
        <input matInput (keyup)="applyFilter($event)" i18n-placeholder="@@dashboardScoresheetTable-searchPlaceholder" placeholder="Ex. 'Blindtest Apple'" #input>
    </mat-form-field>
</div>

<div class="table-container">
    <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@dashboardScoresheetTable-fieldName">Nom</th>
            <td mat-cell *matCellDef="let row" class="hover-pointer">{{row.sessionName}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell class="col-content" *matHeaderCellDef mat-sort-header i18n="@@dashboardScoresheetTable-fieldContent">Stats</th>
            <td mat-cell *matCellDef="let row" class="header-description">
                Date : {{row.createdAt | date:'medium' }} - {{row.playerCount}} players
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row" class="header-icons">
                <button [disabled]="!row.hasScoresheet" mat-icon-button i18n-matTooltip="@@dashboardScoresheetTable-tooltipExport" matTooltip="Exporter les scores" (click)="exportScoresheets(row.id, row.sessionName)">
                    <mat-icon [ngClass]="{'light-icon': true}"> vertical_align_bottom</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container *ngIf="dataSource?.data?.length > 0">
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </ng-container>
    </table>
    <ng-container *ngIf="dataSource?.data?.length === 0 || dataSource?.filteredData?.length === 0">
        <div class="empty-table" i18n="@@dashboardScoresheetTable-noGame">Vous n'avez jamais fait de partie</div>
    </ng-container>
    <mat-paginator [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
</div>
