import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import 'hammerjs';

import {environment} from '../../environments/environment';
import {AppComponent} from './app.component';

import {CoreModule} from './core/core.module';
import {ToastrModule} from 'ngx-toastr';

import {AdminModule} from './modules/admin/admin.module';
import {LibraryRoutingModule} from './modules/library/library-routing.module';
import {DashboardModule} from './modules/dashboard/dashboard.module';
import {GameModule} from './modules/game/game.module';
import {AppRoutingModule} from './app-routing.module';
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {HomeModule} from './modules/home/home.module';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {connectAuthEmulator, getAuth, provideAuth} from '@angular/fire/auth';
import {connectDatabaseEmulator, getDatabase, provideDatabase} from '@angular/fire/database';
import {connectStorageEmulator, getStorage, provideStorage} from '@angular/fire/storage';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        CoreModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => {
            const auth = getAuth();
            if (environment.useEmulators) {
                connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
            }
            return auth;
        }),
        provideDatabase(() => {
            const database = getDatabase();
            if (environment.useEmulators) {
                connectDatabaseEmulator(database, 'localhost', 9000);
            }
            return database;
        }),
        provideStorage(() => {
            const storage = getStorage();
            if (environment.useEmulators) {
                connectStorageEmulator(storage, 'localhost', 9199);
            }
            return storage;
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),

        AppRoutingModule,
        LibraryRoutingModule,
        // Modules
        AdminModule,
        HomeModule,
        // Routing
        DashboardModule,
        GameModule,
    ],
    providers: [
        {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
