import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {BlindtestService} from '../../services/blindtest/blindtest.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  version: string;
  apiVersion: string;
  apiLastDeploy: string;
  constructor(private btService: BlindtestService) {
    this.version = environment.version;
  }

  ngOnInit() {
    this.btService.build().subscribe(b => {
      this.apiVersion = b.version;
      this.apiLastDeploy = b.time;
    });
  }

}
