import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {PlayerService} from './player.service';
import {ErrorHandler} from '../../handler/error-handler';
import {PlayerMetadata} from '@frogconnexion/blinding-common';

export class PlayerSearchDatasource extends DataSource<PlayerMetadata | undefined> {
  private _pageSize = 20;
  private _cachedDataFirebase: PlayerMetadata[] = [];
  private _fetchedKeys = new Set<string>();
  private _dataStream = new BehaviorSubject<(PlayerMetadata | undefined)[]>(this._cachedDataFirebase);
  private _subscription = new Subscription();
  private _exclusions: Set<string> = new Set<string>();
  private searchEnabled: boolean;
  private searchSubscription: Subscription;
  private _cachedPlayers: PlayerMetadata[];


  constructor(private _playerService: PlayerService, private _errorHandler: ErrorHandler) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<(PlayerMetadata | undefined)[]> {
    this._subscription.add(collectionViewer.viewChange.subscribe(range => {
      if (this._cachedDataFirebase.length > range.end) {
        return;
      }
      this._fetchPage(this._cachedDataFirebase[this._cachedDataFirebase.length - 1]?.creationDate, this._pageSize);

    }));
    this._fetchPage(null, 20);
    return this._dataStream;
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  search(search: string) {
    if (!search || search.length === 0) {
      this.searchEnabled = false;
      this._dataStream.next(this._cachedDataFirebase);
    } else {
      this.searchSubscription?.unsubscribe();
      this.searchSubscription = this._playerService.playersByName(search).subscribe(players => {
        this.searchEnabled = true;
        this._cachedPlayers = players;
        this._dataStream.next(players);
      });
    }
  }

  private _fetchPage(startCreationDate: string, limit: number) {
    if (this.searchEnabled) {
      return;
    }
    console.log(`Fetching ${limit} items starting ${startCreationDate}`);
    this._playerService.fetchPlayerPage(startCreationDate, limit).then((items) => {
      this._cachedDataFirebase.push(...items.filter(t => !this._fetchedKeys.has(t.id) && !this._exclusions.has(t.id)));
      items.forEach(i => this._fetchedKeys.add(i.id));
      this._dataStream.next(this._cachedDataFirebase);
    }, (err) => {
      this._errorHandler.handleErrorAndLog(err);
    });
  }

}
