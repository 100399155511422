import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {AuthService} from '../service/auth.service';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

  private idToken: String;
  private backendBaseUrl: string;

  constructor(private authService: AuthService) {
    authService.currentIdToken().subscribe(idToken => {
      this.idToken = idToken;
    });
    this.backendBaseUrl = this.sanitizePrefix(environment.services.backendBaseUrl);
  }

  private sanitizePrefix(prefix: string): string {
    if (prefix.endsWith('/')) {
      return prefix.substr(0, prefix.length - 1);
    }
    return prefix;
  }

  private sanitizeSuffix(suffix: string): string {
    if (!suffix.startsWith('/')) {
      return '/' + suffix;
    }
    return suffix;
  }

  /**
   * Url can be of two things:
   * - Call to third-party API (http://xxx https://yyy)
   * - Call to internal backend API (/endpoint or endpoint)
   * @param url
   * @private
   */
  private computeUrl(url: string): string {
    if (url.startsWith('http')) {
      return url;
    }
    return this.backendBaseUrl + this.sanitizeSuffix(url);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = req.headers;
    if (this.idToken) {
      headers = req.headers.set('Authorization', 'Bearer ' + this.idToken);
      headers = headers.set('X-Frog-Client', 'blinding-admin');
    }
    return next.handle(req.clone({
      headers: headers,
      url: this.computeUrl(req.url)
    }));
  }
}
