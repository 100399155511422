<mat-card>
  <mat-card-title i18n="@@bonus-title">Donner un Bonus</mat-card-title>
  <mat-card-subtitle i18n="@@bonus-subtitle">Sélectionner le joueur</mat-card-subtitle>
  <mat-card-content>
    <mat-radio-group class="radio-group" id="bonusSize" [(ngModel)]="bonusSize">
      <mat-radio-button class="radio-button" value="small" i18n="@@bonus-smallBonus">Mini</mat-radio-button>
      <mat-radio-button class="radio-button" value="medium" i18n="@@bonus-normalBonus">Normal</mat-radio-button>
      <mat-radio-button class="radio-button" value="big" i18n="@@bonus-bigBonus">Grand</mat-radio-button>
    </mat-radio-group>
    <app-player-selector [onPlayerSelected]="onPlayerSelected"></app-player-selector>
    <div class="button-container">
      <button mat-raised-button color="primary" (click)="back()" i18n="@@bonus-back">
        Retour
      </button>
    </div>
  </mat-card-content>
</mat-card>
