<div cdkDropList class="list" (cdkDropListDropped)="drop($event)" cdkDropListLockAxis="y">
  <div class="item-container" *ngFor="let song of songs" cdkDrag>
    <div class="placeholder" *cdkDragPlaceholder cdkD></div>
    <bt-item-widget
      class="widget"
      [item]="song"
      [showImage]="false"
      [compact]="true">
    </bt-item-widget>
    <div class="handle-move" cdkDragHandle>
      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
        <path
          d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
        <path d="M0 0h24v24H0z" fill="none"></path>
      </svg>
    </div>
    <mat-icon class="delete-btn" (click)="removeSong(song)">clear</mat-icon>
  </div>
</div>
