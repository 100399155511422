import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private _navigationSubject: ReplaySubject<string>;

  constructor() {
    this._navigationSubject = new ReplaySubject<string>(1);
  }

  updateNavHeader(name: string) {
    this._navigationSubject.next(name);
  }

  navHeader(): Observable<string> {
    return this._navigationSubject;
  }


}
