<div class="header">

</div>
<div *ngFor="let bt of blindtestList">
    <app-blindtest-widget *ngIf="!isCurrent(bt)" [blindtest]="bt"
                          [currentGameMetadata]="currentGameMetadata"
                          [currentGameState]="currentGameControls"
                          [organization]="organization"
                          [user]="user"></app-blindtest-widget>
</div>
