import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {PlayerService} from '../../services/player/player.service';
import {ErrorHandler} from '../../handler/error-handler';
import {PlayerSearchDatasource} from '../../services/player/player-search.datasource';

@Component({
    selector: 'app-player-selector',
    templateUrl: './player-selector.component.html',
    styleUrls: ['./player-selector.component.scss']
})
export class PlayerSelectorComponent implements OnInit {
    @Input() searchEnabled = false;
    @Input() exclude: string[];
    @Input() onPlayerSelected: Function;
    currentDs: PlayerSearchDatasource;
    search: string;

    constructor(
        private playerService: PlayerService,
        private errorHandler: ErrorHandler,
        private changeDetectorRefs: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.currentDs = new PlayerSearchDatasource(this.playerService, this.errorHandler);

    }

    searchByName(search: string) {
        this.currentDs.search(search);
        this.changeDetectorRefs.detectChanges();
    }
}
