import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../core/auth/service/auth.service';
import {BlindtestService} from '../../core/services/blindtest/blindtest.service';
import {GameService} from '../../core/services/game/game.service';
import {unsubscribe} from '../../core/handler/subscription-handler';
import {NavigationService} from '../../core/services/navigation/navigation.service';
import {Build} from '../../core/models/build';
import {environment} from '../../../../environments/environment';
import {OrganizationService} from '../../core/services/organization/organization.service';
import {ScoreService} from '../../core/services/score/score.service';
import {ImportListener} from './import-blindtest/models/import-listener';
import {BlindtestImportStatusDialogComponent} from './import-blindtest/blindtest-import-status-dialog/blindtest-import-status-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthUser, Claims, Organization, RoleClaim} from '@frogconnexion/core-common';
import {Blindtest, BlindtestImportDescriptor, GameControls, GameMetadata} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

    importListener: ImportListener;
    private importDialogSubscription: Subscription;
    blindtests: Blindtest[];
    private blindingSubscription: Subscription;
    private currentUserSubscription: Subscription;
    private currentGameStateSubscription: Subscription;

    constructor(private authService: AuthService,
                private organizationService: OrganizationService,
                private blindtestService: BlindtestService,
                private gameService: GameService,
                private scoreService: ScoreService,
                private navigationService: NavigationService, private dialog: MatDialog) {
        this.showtimeBaseUrl = environment.services.showtimeBaseUrl;
    }

    blindtest: BlindtestImportDescriptor;
    currentGameControls: GameControls;
    currentGameMetadata: GameMetadata;
    currentBlindtest: Blindtest;
    build: Build;
    organization: Organization;
    private currentBlindtestSubscription: Subscription;
    private currentGameMetadataSubscription: Subscription;

    currentUser: AuthUser;

    private showtimeBaseUrl: string;
    archivedViewEnabled: Boolean = false;

    ngOnInit() {
        this.currentGameMetadataSubscription = this.gameService.currentGameMetadata().subscribe(gm => {
            this.currentGameMetadata = gm;
        });

        this.currentBlindtestSubscription = this.blindtestService.currentBlindtest().subscribe(bt => {
            this.currentBlindtest = bt;
        });

        this.navigationService.updateNavHeader('dashboard');

        this.blindtestService.build().subscribe(build => {
            this.build = build;
        });

        this.blindingSubscription = this.organizationService.organization().subscribe(org => {
            this.organization = org;
            if (this.organization && !this.currentUserSubscription) {

                this.currentUserSubscription = this.authService.currentUserObservable().subscribe(user => {
                    this.currentUser = user;
                });
            }
        });

        this.currentGameStateSubscription = this.gameService.currentGameControls().subscribe(gs => {
            this.currentGameControls = gs;
        });

        this.reloadBlindtests();
    }

    ngOnDestroy() {
        unsubscribe(
            this.currentBlindtestSubscription,
            this.currentGameMetadataSubscription,
            this.importDialogSubscription,
            this.currentGameStateSubscription,
            this.blindingSubscription,
            this.currentUserSubscription
        );
    }

    isDemoUser() {
        return this.currentUser?.hasAnyRole([RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_DEMO, this.organization.organization)]);
    }

    onImportUpdate(importListener: ImportListener) {
        this.importListener = importListener;
        if (this.importListener.pending && !this.importDialogSubscription) {
            const dialogRef = this.dialog.open(BlindtestImportStatusDialogComponent, {
                data: {
                    l: importListener
                }
            });
            this.importDialogSubscription = dialogRef.afterClosed().subscribe(() => {
                this.importDialogSubscription.unsubscribe();
                this.importDialogSubscription = undefined;
                window.location.reload();
            });
        }
    }

    reloadBlindtests(showArchived: boolean = false) {
        this.blindtestService.findAll(showArchived).subscribe(blindtests => {
            this.blindtests = blindtests;
        });
    }
}
