<div [ngClass]="{container: true, compact: compact, selectable: selectable}" (click)="onItemSelected(item)">
  <img src='/assets/imgs/song-normal.png' class="media-type" alt='Media type' *ngIf="showImage && (!item || item.type === 0)"/>
  <img src='/assets/imgs/song-lyrics.png' class="media-type" alt='Media type' *ngIf="showImage && (item && item.type === 1)"/>
  <div class="meta-container">
    <span *ngIf="item" class="artist">{{ item.artist }}</span>
    <span *ngIf="!item" class="artist" i18n="@@itemWidget-loading">Chargement</span>
    <span *ngIf="item" class="title">{{item.title}}</span>
    <span *ngIf="!item" class="title" i18n="@@itemWidget-loading">Chargement</span>
    <span *ngIf="item && item.type === 1" class="lyrics">{{item.lyrics}}</span>
    <div class="space"></div>
  </div>

</div>
