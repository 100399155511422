<div class="full-width ranger-slider">
    <mat-slider
            [disabled]="disabled"
            (change)="valueChange()"
            (input)="minValueInput($event)"
            [(ngModel)]="minValue"
            [color]="minColor"
            [displayWith]="formatLabel"
            [max]="max"
            [min]="min"
            [thumbLabel]="thumbLabel"
            class="full-width cdk-focused slider-over slider-min-value"
            step="5">
    </mat-slider>
    <mat-slider
            [disabled]="disabled"
            (change)="valueChange()"
            (input)="maxValueInput($event)"
            [(ngModel)]="maxValue"
            [color]="maxColor"
            [displayWith]="formatLabel"
            [max]="max"
            [min]="min"
            [thumbLabel]="thumbLabel"
            class="full-width cdk-focused slider-over slider-max-value"
            step="5">
    </mat-slider>
    <div class="full-width slider-over slider-line-cover-wrapper">
        <mat-divider [class]="isMinValueInit? 'slider-line-cover-init' : 'slider-line-cover'"></mat-divider>
        <mat-divider [class]="isMaxValueInit? 'slider-line-cover-init' : 'slider-line-cover'"></mat-divider>
    </div>

    <div *ngIf="showRuler" class="outer">
        <div class="middle">
            <div *ngFor="let key of rulerArray" class="inner">|</div>
        </div>
        <div class="middle">
            <div *ngFor="let key of rulerArray" class="inner"><span>{{key}}</span></div>
        </div>
    </div>
</div>
