import {Component, OnDestroy} from '@angular/core';
import {OrganizationService} from '../../../core/services/organization/organization.service';
import {ParseUtils} from '../../../core/utils/parse-utils';
import {LoggerService} from '../../../core/services/logger/logger.service';
import {Claims, RoleClaim} from '@frogconnexion/core-common';
import {PermalinkService} from '../../../core/services/permalink/permalink.service';
import {AbstractControl, UntypedFormBuilder, Validators} from '@angular/forms';
import {BlindtestService} from '../../../core/services/blindtest/blindtest.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../core/auth/service/auth.service';
import {NavigationService} from '../../../core/services/navigation/navigation.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {Location} from '@angular/common';
import {GameService} from '../../../core/services/game/game.service';
import {
    AttachedFile,
    Blindtest,
    BrandingDescriptor,
    GameDescriptor,
    Gameplay,
    Joker,
    PinAvailabilityResult
} from '@frogconnexion/blinding-common';
import {catchError} from 'rxjs/operators';

@Component({
    selector: 'app-activate-blindtest',
    templateUrl: 'activate-blindtest.component.html',
    styleUrls: ['./activate-blindtest.component.scss'],
})
export class ActivateBlindtestComponent implements OnDestroy {

    isShowingSuggestions = false;
    slugSuggestionList: string[] = [];
    slugAvailable: boolean;
    blindtest: Blindtest;
    serviceError: string;
    brandingLogoError: string;
    previousSlug: string;
    pinAvailability: PinAvailabilityResult;

    firstFormGroup = this.formBuilder.group({
        managedExternally: [false, Validators.required],
        pin: [this.organizationService.getOrganizationTagSnapshot(), Validators.required],
        test: [false, Validators.required],
        lang: ['fr', Validators.required],
        playerNamingStrategy: ['playerName', Validators.required],
        servicesEnabled: [false, Validators.required],
        services: [undefined],
        ageRange: [[{start: 25, end: 55}]],
    });
    secondFormGroup = this.formBuilder.group({
        gameplay: [Gameplay.FasterStrongerPerSet, Validators.required],
        jokerDoubleOrNothing: [true, Validators.required],
        jokerSpy: [true, Validators.required],
        jokerMindReader: [true, Validators.required],
    });
    thirdFormGroup = this.formBuilder.group({
        permalinkEnabled: [false, Validators.required],
        slug: [{value: null, disabled: true}, Validators.required],
        feedbackFormEnabled: [true, Validators.required],
        brandingEnabled: [false, Validators.required],
        branding: [{value: null, disabled: true}, Validators.required],
    });
    limited: boolean;
    gameplays = Gameplay.values();
    private subs: Subscription[] = [];
    submitting = false;
    organization: string;

    constructor(private formBuilder: UntypedFormBuilder,
                private logger: LoggerService,
                private organizationService: OrganizationService,
                private authService: AuthService,
                private navService: NavigationService,
                private permalinkService: PermalinkService,
                private blindtestService: BlindtestService,
                private gameService: GameService,
                private route: ActivatedRoute,
                private router: Router,
                private location: Location,
    ) {
        this.navService.updateNavHeader('quiz.activate');
        this.organization = this.organizationService.getOrganizationTagSnapshot();
        const blindtestId = this.route.snapshot.paramMap.get('id');
        this.blindtestService.get(blindtestId).subscribe((b) => {
            this.blindtest = b;
        });
        this.subs.push(this.authService.currentUserObservable().subscribe((u) => {
            this.limited = u.hasAnyRole([
                RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_LIMITED, this.organizationService.getOrganizationTagSnapshot()),
                RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_DEMO, this.organizationService.getOrganizationTagSnapshot())]);
        }));

        this.subs.push(this.formManagedExternally.valueChanges.subscribe((managedExternally) => {
            if (managedExternally) {
                this.formPin.disable();
                this.formServices.disable();
                this.formAgeRange.disable();
                this.formLang.disable();
                this.formTest.disable();
                this.formPlayerNamingStrategy.disable();
            } else {
                this.formPin.enable();
                this.formServices.enable();
                this.formAgeRange.enable();
                this.formLang.enable();
                this.formTest.enable();
                this.formPlayerNamingStrategy.enable();
            }
        }));
        this.subs.push(this.formPin.valueChanges.subscribe((pin) => {
            if (!pin) {
                return;
            }
            const sanitized = this.toDashCase(pin.toLowerCase());
            if (sanitized !== pin) {
                this.formPin.setValue(sanitized);
            } else {
                this.checkPinAvailable(sanitized);
            }
        }));
        this.subs.push(this.formServicesEnabled.valueChanges.subscribe((enabled) => {
            if (!enabled) {
                this.formServices.setValue(undefined);
            } else {
                this.formServices.setValue([]);
            }
        }));
        this.subs.push(this.formPermalinkEnabled.valueChanges.subscribe((enabled) => {
            if (!enabled) {
                this.previousSlug = this.formSlug.value;
                this.formSlug.setValue(undefined);
                this.formSlug.disable();
            } else {
                this.formSlug.setValue(this.previousSlug || '');
                this.formSlug.enable();
            }
        }));
        this.subs.push(this.formSlug.valueChanges.subscribe((input) => {
            if (!input) {
                this.slugAvailable = false;
                this.slugSuggestionList = [];
                return;
            }
            const slug = this.toDashCase(input.toLowerCase());
            if (slug !== input) {
                this.formSlug.setValue(slug);
            } else {
                this.checkSlugAvailable(slug);
            }
        }));
        this.subs.push(this.formBrandingEnabled.valueChanges.subscribe((enabled) => {
            if (!enabled) {
                this.formBranding.setValue(undefined);
                this.formBranding.disable();
            } else {
                this.formBranding.setValue(new BrandingDescriptor());
                this.formBranding.enable();
            }
        }));
        this.checkSlugAvailable(this.formSlug.value);
        this.checkPinAvailable(this.formPin.value);
    }

    ngOnDestroy(): void {
        unsubscribe(...this.subs);
    }


    get formManagedExternally(): AbstractControl {
        return this.firstFormGroup.get('managedExternally');
    }

    get formPin(): AbstractControl {
        return this.firstFormGroup.get('pin');
    }

    get formLang(): AbstractControl {
        return this.firstFormGroup.get('lang');
    }

    get formTest(): AbstractControl {
        return this.firstFormGroup.get('test');
    }

    get formServicesEnabled(): AbstractControl {
        return this.firstFormGroup.get('servicesEnabled');
    }

    get formServices(): AbstractControl {
        return this.firstFormGroup.get('services');
    }

    get formPlayerNamingStrategy(): AbstractControl {
        return this.firstFormGroup.get('playerNamingStrategy');
    }

    get formAgeRange(): AbstractControl {
        return this.firstFormGroup.get('ageRange');
    }

    get formGameplay(): AbstractControl {
        return this.secondFormGroup.get('gameplay');
    }

    get formJokerDoubleOrNothing(): AbstractControl {
        return this.secondFormGroup.get('jokerDoubleOrNothing');
    }

    get formJokerSpy(): AbstractControl {
        return this.secondFormGroup.get('jokerSpy');
    }

    get formJokerMindReader(): AbstractControl {
        return this.secondFormGroup.get('jokerMindReader');
    }

    get formPermalinkEnabled(): AbstractControl {
        return this.thirdFormGroup.get('permalinkEnabled');
    }

    get formSlug(): AbstractControl {
        return this.thirdFormGroup.get('slug');
    }

    get formEnableFeedbackForm(): AbstractControl {
        return this.thirdFormGroup.get('feedbackFormEnabled');
    }

    get formBrandingEnabled(): AbstractControl {
        return this.thirdFormGroup.get('brandingEnabled');
    }

    get formBranding(): AbstractControl {
        return this.thirdFormGroup.get('branding');
    }

    get slugEntered(): boolean {
        return this.thirdFormGroup.get('slug').value?.trim()?.length > 0;
    }

    get pinEntered(): boolean {
        return this.firstFormGroup.get('pin').value?.trim()?.length > 0;
    }

    get pinLegit(): boolean {
        return this.pinAvailability && (this.pinAvailability.available || this.pinAvailability.organization === this.organization);
    }

    setSlug(slug: string) {
        const convert = this.toDashCase(slug.toLowerCase());
        this.formSlug.setValue(convert);
    }

    checkPinAvailable(input: string) {
        if (!input) {
            this.pinAvailability = new PinAvailabilityResult(true, undefined, undefined, undefined);
            return;
        }
        this.organizationService.isPinAvailable(input).subscribe(p => {
            this.pinAvailability = p;
        });
    }

    checkSlugAvailable(input: string) {
        if (!input) {
            this.slugAvailable = false;
            this.isShowingSuggestions = false;
            return;
        }
        this.permalinkService.getSlugSuggestions(this.organizationService.getOrganizationTagSnapshot(), input).subscribe(suggestions => {
            this.slugAvailable = suggestions.length === 1 && suggestions[0] === input;
            this.slugSuggestionList = suggestions;
            this.isShowingSuggestions = suggestions.length > 1;
        });
    }

    setAgeRange(range: { min: number, max: number }) {
        this.formAgeRange.setValue([{start: range.min, end: range.max}]);
    }

    private capitalizeFirstLetter(s: string) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }


    onFileInput(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            // Only support JSON files
            if (file.name.indexOf('.json') === -1) {
                this.serviceError = 'wrong-type';
                return;
            }
            // Will asynchronously load uploaded file
            reader.addEventListener('load', () => {
                // Try JSON parsing or fail
                try {
                    const services: string[] = JSON.parse(reader.result.toString()).map(s => this.capitalizeFirstLetter(s)).sort();
                    services.forEach(e => this.formServices.value.push(e));
                    this.serviceError = undefined;
                } catch (e) {
                    this.serviceError = 'parsing-error';
                    return;
                }
            });
            reader.readAsText(file, 'UTF-8');
        }
    }

    private readToAttachedFile(event) {
        return new Promise<AttachedFile>((resolve, reject) => {
            const reader = new FileReader();
            if (event.target.files && event.target.files.length > 0) {
                const image = event.target.files[0];
                // Will asynchronously load uploaded file
                reader.addEventListener('load', () => {
                    // Try JSON parsing or fail
                    try {
                        const blob = ParseUtils.base64ArrayBuffer(reader.result as ArrayBuffer);
                        const mimeType = image.type;
                        const filename = ParseUtils
                            .normalizeLowercaseNoSpace(
                                this.organizationService.getOrganizationTagSnapshot() +
                                '_' +
                                this.blindtest.name +
                                image.name.substr(image.name.lastIndexOf('.')));
                        const af = new AttachedFile(blob, mimeType, filename);
                        resolve(af);
                    } catch (e) {
                        this.logger.error(e);
                        reject(e);
                        return;
                    }
                });
                reader.readAsArrayBuffer(image);
            }
        });
    }


    onLogoInput(event) {
        this.readToAttachedFile(event)
            .then(af => {
                if (!this.formBranding.value) {
                    this.formBranding.setValue(new BrandingDescriptor());
                }
                this.formBranding.value.logo = af;
            }).catch(e => {
            this.logger.error(e);
            this.brandingLogoError = 'parsing-error';
            return;
        });
    }

    onLogoCornerInput(event) {
        this.readToAttachedFile(event)
            .then(af => {
                if (!this.formBranding.value) {
                    this.formBranding.setValue(new BrandingDescriptor());
                }
                this.formBranding.value.logoCorner = af;
            }).catch(e => {
            this.logger.error(e);
            this.brandingLogoError = 'parsing-error';
            return;
        });
    }

    toDashCase(str: string) {
        if (!str) {
            return;
        }
        return str
            .replace(/\s+/g, '-')
            .replace(/^([0-9]+).*$/g, '')
            .replace(/([^A-Za-z0-9-_])/g, '');
    }


    backToDashboard() {
        this.router.navigate(['/org/' + this.organizationService.getOrganizationTagSnapshot() + '/dashboard']);
    }

    assignGame() {
        this.submitting = true;
        const descriptor = new GameDescriptor(
            this.blindtest.id,
            this.formManagedExternally.value,
            this.blindtest.name,
            this.formManagedExternally.value ? undefined : false,
            this.formManagedExternally.value ? undefined : this.formTest.value,
            this.formManagedExternally.value ? undefined : this.formServices.value,
            this.formManagedExternally.value ? undefined : (this.formAgeRange.value ? this.formAgeRange.value : undefined),
            this.formManagedExternally.value ? undefined : this.formLang.value,
            this.formManagedExternally.value ? undefined : this.formPin.value,
            this.formManagedExternally.value ? undefined : this.formPlayerNamingStrategy.value,
            this.formGameplay.value,
            (this.formJokerSpy.value ? Joker.Spy : 0) +
            (this.formJokerDoubleOrNothing.value ? Joker.DoubleOrNothing : 0) +
            (this.formJokerMindReader.value ? Joker.MindReader : 0),
            this.formManagedExternally.value ? undefined : this.formSlug.value,
            this.formEnableFeedbackForm.value,
            this.formBranding.value,
        );
        this.gameService.createCurrentGame(descriptor)
            .pipe(catchError((err, caught) => {
                this.submitting = false;
                throw err;
            }))
            .subscribe(() => {
                this.submitting = false;
                this.router.navigate(['/org/' + this.organizationService.getOrganizationTagSnapshot() + '/dashboard']);
            });
    }
}
