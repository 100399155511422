import {Injectable} from '@angular/core';

import {AngularFireDatabase} from '@angular/fire/compat/database';

import {map, mergeMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {BlindtestService} from '../blindtest/blindtest.service';
import {GameService} from '../game/game.service';
import {LoggerService} from '../logger/logger.service';
import {AuthService} from '../../auth/service/auth.service';
import {HttpClient} from '@angular/common/http';
import {ErrorHandler} from '../../handler/error-handler';
import {OrganizationService} from '../organization/organization.service';
import {GameControls, GameMetadata, Player, PlayerMetadata} from '@frogconnexion/blinding-common';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  private _currentGameStateObservable: Observable<GameControls>;
  private _currentGameState: GameControls;
  private _currentGameMetadataObservable: Observable<GameMetadata>;
  private _currentGameMetadata: GameMetadata;
  private _organization: string;

  constructor(private _fb: AngularFireDatabase,
              private _blindingService: OrganizationService,
              private _blindTestService: BlindtestService,
              private _gameService: GameService,
              private _authService: AuthService,
              private _logger: LoggerService,
              private _http: HttpClient,
              private _errorHandler: ErrorHandler) {

    this._blindingService.organizationTag().subscribe(o => {
      this._organization = o;
    });
    // Current Game metadata observable
    this._currentGameMetadataObservable = this._gameService.currentGameMetadata();
    this._currentGameMetadataObservable.subscribe(g => {
      // Replay all game-specific observables
      this._currentGameMetadata = g;
    });
    // Current Game state observable
    this._currentGameStateObservable = this._gameService.currentGameControls();
    this._currentGameStateObservable.subscribe(g => {
      // Replay all game-specific observables
      this._currentGameState = g;
    });
  }

  // Async methods


  // Read-Only Observables

  player(id: string): Observable<Player> {
    return this._currentGameMetadataObservable.pipe(mergeMap(g => {
      if (g == null || !id) {
        this._logger.debug('No current game');
        return of(null);
      }
      return this._fb.object<Player>(`/blinding/games/${this._organization}/state/players/byId/${id}`).snapshotChanges()
        .pipe(map(c => (id && c && c.payload.val()) ? ({id: c.payload.key, ...c.payload.val()}) : null));
    }));
  }

  playersByName(search: string): Observable<PlayerMetadata[]> {
    return this._http.get<PlayerMetadata[]>(`/admin/org/${this._organization}/game/players/search/${search}`)
        .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  players(): Observable<Player[]> {
    return this._currentGameMetadataObservable.pipe(mergeMap(g => {
        if (g == null) {
          return of(null);
        }
        return this._fb.list<Player>(`/blinding/games/${this._organization}/state/players/byId`).snapshotChanges()
          .pipe(map(changes => changes ? changes.map(c => ({id: c.payload.key, ...c.payload.val()})) : null));
      }));
  }

  fetchPlayerPage(creationDate: string, limit: number = 20): Promise<PlayerMetadata[]> {
    let result = null;
    if (creationDate) {
      result = this._fb.list<PlayerMetadata>(`/blinding/games/${this._organization}/state/players/byId`, ref => ref.orderByChild('creationDate').endAt(creationDate).limitToLast(limit)).query.once('value');
    } else {
      result = this._fb.list<PlayerMetadata>(`/blinding/games/${this._organization}/state/players/byId`, ref => ref.orderByChild('creationDate').limitToLast(limit)).query.once('value');
    }
    return result.then((r) => {
      const players = r.val();
      return Object.keys(players).map(k => players[k]).sort((a, b) => a.creationDate > b.creationDate ? -1 : 1);
    });
  }

  playerCount(): Observable<number> {
    return this._currentGameMetadataObservable.pipe(mergeMap(g => {
      if (g == null) {
        return of(null);
      }
      return this._fb.object<number>(`/blinding/games/${this._organization}/state/players/count`).snapshotChanges()
          .pipe(map(c => {
            return c.payload.val();
          }));
    }));
  }

}
