
<div class="container">
  <mat-card>
    <mat-card-content>
      <div fxLayout="column"
           fxLayoutAlign="space-between center"
           fxLayoutGap="20px">
        <mat-icon>lock</mat-icon>
        <span  i18n="@@unthorized-accessRefused"><p>Désolé. Ce site est réservé aux administrateurs de <b>Blinding</b> pour <b>piloter</b> ton blind-test.</p></span>
      </div>
    </mat-card-content>

  </mat-card>
</div>
