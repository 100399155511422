import {Component, OnInit} from '@angular/core';
import {BlindtestService} from '../../../core/services/blindtest/blindtest.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BlindtestImportDescriptor} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-blindtest-details-page',
  templateUrl: './blindtest-details-page.component.html',
  styleUrls: ['./blindtest-details-page.component.scss']
})
export class BlindtestDetailsPageComponent implements OnInit {


  blindtest: BlindtestImportDescriptor;

  constructor(private _blindTestService: BlindtestService, private _router: Router, private _route: ActivatedRoute) {
  }

  ngOnInit() {
    this._blindTestService.get(this._route.snapshot.paramMap.get('id')).subscribe(bt => {
        this.blindtest = BlindtestImportDescriptor.fromBlindtest(bt);
      });
  }


}
