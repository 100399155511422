import {Component, Inject, OnInit} from '@angular/core';
import {ImportListener} from '../models/import-listener';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface DialogData {
  l: ImportListener;
}

@Component({
  selector: 'app-blindtest-import-status-dialog',
  templateUrl: './blindtest-import-status-dialog.component.html',
  styleUrls: ['./blindtest-import-status-dialog.component.css']
})
export class BlindtestImportStatusDialogComponent implements OnInit {
  il: ImportListener;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.il = data.l;
  }

  ngOnInit(): void {
  }

}
