import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {OrganizationService} from '../../organization/organization.service';
import {LoggerService} from '../../logger/logger.service';
import {Claims, PropertyClaim} from '@frogconnexion/core-common';

@Injectable({
    providedIn: 'root'
})
export class GameGuard implements CanActivate {
    private hasCurrentGameClaim = PropertyClaim.parse(Claims.Organization.BLINDING_GLOBAL_PROP_HAS_CURRENT_GAME);

    constructor(private _blindingService: OrganizationService,
                private _router: Router,
                private _logger: LoggerService) {
    }

    canActivate(): Observable<boolean> {
        return this._blindingService.organization()
            .pipe(map(o => {
                return o?.properties.find(p => p.claimKey === this.hasCurrentGameClaim.claimKey)?.value === true;
            }))
            .pipe(map(hasCurrentGame => {
                if (!hasCurrentGame) {
                    this._logger.debug('Game Guard Failed. Redirecting.');
                    this._router.navigate(['/dashboard']);
                    return false;
                }
                this._logger.debug('Game Guard Succeeded.');
                return true;
            }));
    }
}
