import {Injectable} from '@angular/core';
import {GameService} from '../game/game.service';
import {Observable, ReplaySubject} from 'rxjs';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {PlayerService} from '../player/player.service';
import {HttpClient} from '@angular/common/http';
import {ErrorHandler} from '../../handler/error-handler';
import {OrganizationService} from '../organization/organization.service';
import {GameControls, LeaderboardEntry, Player, Scoresheet, ServiceLeaderboardEntry} from '@frogconnexion/blinding-common';
import {GameShortRecap} from '../../../modules/dashboard/search-scoresheet/game-short-recap';

@Injectable({
    providedIn: 'root'
})
export class ScoreService {

    private _currentGameState: GameControls;
    private _currentGameStateObservable: ReplaySubject<GameControls>;
    private _playersMap: Map<string, Player>;
    private _organization: string;

    constructor(private _fb: AngularFireDatabase,
                private _blindingService: OrganizationService,
                private _gameService: GameService,
                private _playerService: PlayerService,
                private _http: HttpClient,
                private _errorHandler: ErrorHandler) {

        this._blindingService.organizationTag().subscribe(o => {
            this._organization = o;
        });
        // Current Game state observable
        this._currentGameStateObservable = new ReplaySubject<GameControls>(1);
        this._gameService.currentGameControls().subscribe(g => {
            // Replay all game-specific observables
            this._currentGameState = g;
            if (this._playersMap) {
                this._currentGameStateObservable.next(g);
            }
        });
    }

    // Asyncmethods

    giveBonus(playerId: string, bonus: number): Observable<void> {
        return this._http.post<void>(`/admin/org/${this._organization}/game/score/${playerId}/bonus/${bonus}`, null)
            .pipe(this._errorHandler.retryThreeTimesOrError());
    }

    // Observables

    fetchScores(offset: number, limit: number = 20): Promise<LeaderboardEntry[] | undefined> {
        return this._fb.list<LeaderboardEntry[]>(`/blinding/games/${this._organization}/state/scores/list`, ref => ref.orderByKey().startAt(`${offset}`).limitToFirst(limit))
            .query.once('value')
            .then((r) => {
                const result = r.val();
                if (Array.isArray(result)) {
                    return result.slice(offset);
                } else {
                    if (!result) {
                        return [];
                    }
                    return Object.keys(result).map(k => result[k]);
                }
            });
    }


    fetchServiceScores(offset: number, limit: number = 20): Promise<ServiceLeaderboardEntry[] | undefined> {
        return this._fb.list<ServiceLeaderboardEntry[]>
        (`/blinding/games/${this._organization}/state/scores/listByService`, ref => ref.orderByKey().startAt(`${offset}`)
            .limitToFirst(limit))
            .query.once('value')
            .then((r) => {
                const result = r.val();
                if (Array.isArray(result)) {
                    return result.slice(offset);
                } else {
                    if (!result) {
                        return [];
                    }
                    return Object.keys(result).map(k => result[k]);
                }
            });
    }

    export(): Observable<Scoresheet[]> {
        const organization = this._blindingService.getOrganizationTagSnapshot();
        return this._http.get<Scoresheet[]>(`/admin/org/${organization}/game/score/export`)
            .pipe(this._errorHandler.retryThreeTimesOrError());
    }

    listAllScoresForOrg(): Observable<GameShortRecap[]> {
        const organization = this._blindingService.getOrganizationTagSnapshot();
        return this._http.get<GameShortRecap[]>(`/admin/org/${organization}/global/search-scores`)
            .pipe(this._errorHandler.retryThreeTimesOrError());
    }

    exportPastScoresheet(id: string): Observable<Scoresheet[]> {
        const organization = this._blindingService.getOrganizationTagSnapshot();
        return this._http.get<Scoresheet[]>(`/admin/org/${organization}/global/export-scores/${id}`)
            .pipe(this._errorHandler.retryThreeTimesOrError());
    }
}
