import {Injectable} from '@angular/core';

import {AngularFireDatabase} from '@angular/fire/compat/database';

import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {OrganizationService} from '../organization/organization.service';
import {ErrorHandler} from '../../handler/error-handler';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from '../logger/logger.service';
import {BlindtestService} from '../blindtest/blindtest.service';
import {unsubscribe} from '../../handler/subscription-handler';
import {Claims, PropertyClaim} from '@frogconnexion/core-common';
import {Blindtest, Game, GameControls, GameDescriptor, GameMetadata, Song} from '@frogconnexion/blinding-common';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  private _blindtestSubscription: Subscription;
  private _currentBlindtest: Blindtest;
  private _gameStateSubject: Subject<GameControls>;
  private _gameStateSubscription: Subscription;
  private _currentGameState: GameControls;
  private _gameMetadataSubject: Subject<GameMetadata>;
  private _gameMetadataSubscription: Subscription;
  private _currentGameMetadata: GameMetadata;
  private _currentSongSubject: ReplaySubject<Song>;
  private _currentSong: Song;
  private _organization: string;

  constructor(private _fb: AngularFireDatabase,
              private _blindingService: OrganizationService,
              private _blindtestService: BlindtestService,
              private _http: HttpClient,
              private _errorHandler: ErrorHandler,
              private _logger: LoggerService) {

    this._gameStateSubject = new ReplaySubject(1);
    this._gameMetadataSubject = new ReplaySubject(1);
    this._currentSongSubject = new ReplaySubject<Song>(1);

    // When blinding object changes state
    this._blindingService.organization().subscribe(o => {
      this._organization = o?.organization;
      unsubscribe(this._gameStateSubscription, this._gameMetadataSubscription);

      // If blinding has changed, compute and emit next game state value
      const claim = PropertyClaim.parse(Claims.Organization.BLINDING_GLOBAL_PROP_HAS_CURRENT_GAME);
      const hasCurrentGame: boolean = o?.properties.find(p => p.claimKey === claim.claimKey)?.value === true;
      if (!hasCurrentGame) {
        this._updateCurrentGameState(null);
        this._updateCurrentMetadata(null);
        return;
      }
      this._gameStateSubscription = this._fb.object<GameControls>(`/blinding/games/${this._organization}/game/control`).valueChanges()
        .subscribe(gs => {
          this._updateCurrentGameState(gs);
        });
      this._gameMetadataSubscription = this._fb.object<GameMetadata>(`/blinding/games/${this._organization}/game/metadata`).valueChanges()
        .subscribe(gm => {
          this._updateCurrentMetadata(gm);
        });
      // Current Blindtest observable
      this._blindtestSubscription = this._blindtestService.currentBlindtest().subscribe(bt => {
        this._updateCurrentBlindtest(bt);
      });
    });
  }

  private _updateCurrentGameState(gs: GameControls) {
    this._currentGameState = GameControls.fromDto(gs);
    this._gameStateSubject.next(this._currentGameState);
    this._updateCurrentSong();
  }

  private _updateCurrentMetadata(gm: GameMetadata) {
    this._currentGameMetadata = gm;
    this._gameMetadataSubject.next(gm);
  }

  private _updateCurrentBlindtest(bt: Blindtest) {
    this._currentBlindtest = bt;
    this._updateCurrentSong();
  }

  private _updateCurrentSong() {
    let newSong: Song = null;
    // If both requirements to get song are here, get it
    if (!this._currentBlindtest || !this._currentGameState) {
      newSong = null;
    } else {
      newSong = this._currentBlindtest
          .sets[this._currentGameState.blindtestControl.current].songs[this._currentGameState.setControl.current];
    }
    if (this._currentSong !== newSong) {
      this._currentSong = newSong;
      this._currentSongSubject.next(this._currentSong);
    }
  }

  // Public Async methods

  startCurrentGame(): Observable<void> {
    if (!this._currentGameState) {
      throw new Error('Cannot start: No game is currently set.');
    }
    return this._http.post<void>(`/admin/org/${this._organization}/game/start`, null)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  finishCurrentGame(): Observable<void> {
    if (!this._currentGameState) {
      throw new Error('Cannot start: No game is currently set.');
    }
    return this._http.post<void>(`/admin/org/${this._organization}/game/finish`, null)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  unsetCurrentGame(): Observable<void> {
    if (!this._currentGameState) {
      throw new Error('Cannot start: No game is currently set.');
    }
    return this._http.delete<void>(`/admin/org/${this._organization}/game`)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  createCurrentGame(g: GameDescriptor): Observable<Game> {
    return this._http.post<Game>(`/admin/org/${this._organization}/game`, g)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  // Observables

  currentGameMetadata(): Observable<GameMetadata> {
    return this._gameMetadataSubject;
  }

  currentGameControls(): Observable<GameControls> {
    return this._gameStateSubject;
  }

  currentSong(): Observable<Song> {
    return this._currentSongSubject;
  }

}
