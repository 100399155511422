import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';

import {FooterComponent} from './components/footer/footer.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {ValidationDialogComponent} from './components/dialog/validation-dialog/validation-dialog.component';
import {LoggerConfig} from './services/logger/logger.config';
import {environment} from '../../../environments/environment';
import {AuthModule} from './auth/auth.module';
import {MaterialModule} from './material/material.module';
import {PlayerHeaderComponent} from './components/player-header/player-header.component';
import {LeaderboardComponent} from './components/leaderboard/leaderboard.component';
import {PlayerWidgetComponent} from './components/leaderboard/player-widget/player-widget.component';
import {PlayerSelectorComponent} from './components/player-selector/player-selector.component';
import {LoadingComponent} from './components/loading/loading.component';
import {parse as ParseLogLevel} from './services/logger/loglevel';
import {LeaderboardServiceComponent} from './components/leaderboard-service/leaderboard-service.component';
import {ServiceWidgetComponent} from './components/leaderboard-service/service-widget/service-widget.component';
import {RangeSliderComponent} from './components/slider/range-slider.component';

@NgModule({
    declarations: [
        FooterComponent,
        NavbarComponent,
        ValidationDialogComponent,
        PlayerHeaderComponent,
        LeaderboardComponent,
        LeaderboardServiceComponent,
        PlayerWidgetComponent,
        ServiceWidgetComponent,
        RangeSliderComponent,
        PlayerSelectorComponent,
        LoadingComponent
    ],
    exports: [
        PlayerHeaderComponent,
        PlayerSelectorComponent,
        LeaderboardComponent,
        LeaderboardServiceComponent,
        FooterComponent,
        NavbarComponent,
        MaterialModule,
        RangeSliderComponent,
        ValidationDialogComponent,
        LoadingComponent,
        PlayerWidgetComponent,
        ServiceWidgetComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        AuthModule,
        RouterModule,
        HttpClientModule,
    ],
    providers: [
        { provide: LoggerConfig, useValue: ParseLogLevel(environment.loggerConfig) }
    ]
})
export class CoreModule {
}
