import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';
import {ReviewService} from '../../../core/services/review/review.service';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {Song, SongPendingReview} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-review-widget',
  templateUrl: './review-widget.component.html',
  styleUrls: ['./review-widget.component.scss']
})
export class ReviewWidgetComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentSong: Song;
  pendingReviews: SongPendingReview[];
  private _solvedPendingReviews: SongPendingReview[];
  private _pendingReviewsSubscription: Subscription;
  private _solvedPendingReviewsSubscription: Subscription;

  constructor(private _reviewService: ReviewService) {

  }

  ngOnInit() {

  }

  ngOnChanges() {
    if (!this.currentSong) {
      return;
    }
    unsubscribe(this._pendingReviewsSubscription, this._solvedPendingReviewsSubscription);
    this._pendingReviewsSubscription = this._reviewService.currentPendingReviewsState().subscribe((reviews) => {
      this.pendingReviews = reviews ? reviews : [];
    });
    this._solvedPendingReviewsSubscription = this._reviewService.currentSolvedPendingReviewsState().subscribe((reviews) => {
      this._solvedPendingReviews = reviews ? reviews : [];
    });
  }

  ngOnDestroy() {
    unsubscribe(this._pendingReviewsSubscription, this._solvedPendingReviewsSubscription);
  }

  hasBeenReviewed(key: string) {
    return this._solvedPendingReviews?.filter(s => s.key === key).length > 0;
  }

}
