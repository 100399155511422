import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";

import {InputErrorStateMatcher} from "../blindtest-edit-page.component";

@Component({
  selector: 'bt-input-widget',
  templateUrl: './input-widget.component.html',
  styleUrls: ['./input-widget.component.scss']
})
export class InputWidgetComponent implements OnInit, OnDestroy {

  @Input()
  label: string;
  @Input()
  controlName: string;
  @Input()
  placeholder: string;
  @Input()
  initialValue?: string;
  matcher = new InputErrorStateMatcher();

  validationMessages = [
      {type: 'required', message: 'Champ requis.'},
      {type: 'minlength', message: 'Merci d\'entrer une valeur valide (plus de 3 lettres)'}
    ];
  @Input()
  form: UntypedFormGroup;

  constructor() { }

  ngOnInit() {
    this.form.registerControl(this.controlName, new UntypedFormControl(this.initialValue, [Validators.minLength(3)]));
  }

  ngOnDestroy(): void {
    this.form.removeControl(this.controlName);
  }

}
