<mat-card class="leaderboard-card">
  <mat-card-title *ngIf="!message">
    <button mat-icon-button color="primary" *ngIf="hide" (click)="hideLeaderboardPreview()">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <div class="title" i18n="@@leaderboardService-title">Scores</div>
    <button mat-raised-button color="primary" *ngIf="!revealed" (click)="showLeaderboard()" i18n="@@leaderboardService-revealToEveryone">Révéler à tous</button>
    <button mat-raised-button color="primary" *ngIf="hide && revealed" (click)="hideLeaderboard()" i18n="@@leaderboardService-hideForEveryone">Cacher à tous</button>
  </mat-card-title>
  <mat-card-title *ngIf="message">{{message}}&nbsp;
    <button mat-raised-button color="primary" *ngIf="!revealed" (click)="showLeaderboard()" i18n="@@leaderboardService-revealToEveryone">Révéler à tous</button>
    <button mat-raised-button color="primary" *ngIf="hide && revealed" (click)="hideLeaderboard()" i18n="@@leaderboardService-hideForEveryone">Cacher à tous</button>
  </mat-card-title>
  <mat-card-subtitle i18n="@@leaderboard-subtitleTeams">{playerCount, plural, =0 {Pas de joueur} =1 { 1 joueur} other {{{playerCount}} joueurs}}
  </mat-card-subtitle>
  <mat-card-content class="content">
    <mat-list>
      <mat-list-item class="header">
        <div class="header-position">#</div>
        <div class="header-player" i18n="@@leaderboard-teamHeader">Joueur</div>
        <div class="header-score" i18n="@@leaderboard-scoreHeader">Score</div>
      </mat-list-item>
      <cdk-virtual-scroll-viewport itemSize="110" class="example-viewport">
        <app-leaderboard-entry class="example-item" *cdkVirtualFor="let i = index; let item of ds"
                               [entry]="item" [ranking]="i"></app-leaderboard-entry>
      </cdk-virtual-scroll-viewport>
      <mat-divider></mat-divider>
    </mat-list>
  </mat-card-content>
</mat-card>
