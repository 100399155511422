import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface DialogData {
    action: string;
    label?: string;
}

@Component({
    selector: 'validation-dialog',
    templateUrl: 'validation-dialog.component.html'
})
export class ValidationDialogComponent {

    i18nLabel: string;
    label: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.i18nLabel = this.data.action;
        this.label = this.data.label;
    }

}
