import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {SignupComponent} from './signup/signup.component';
import {LoginComponent} from './login/login.component';
import {EmailComponent} from './email/email.component';
import {AuthGuard} from './service/guards/auth.guard';
import {AdminAuthGuard} from './service/guards/admin-auth.guard';
import {MaterialModule} from '../material/material.module';
import {GameGuard} from '../services/game/guards/game.guard';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthHeaderInterceptor} from './interceptors/http-interceptor';
import {AvatarSelectorComponent} from './avatar-selector/avatar-selector.component';
import {AvatarService} from './service/avatar.service';
import {OrganizationGuard} from '../services/organization/guards/organization.guard';
import {RegisterComponent} from './register/register.component';

const routes = [
    {path: 'org/:organization/login', component: LoginComponent, canActivate: [OrganizationGuard]},
    {path: 'register', component: RegisterComponent},
    {path: 'register/by-email', component: SignupComponent},
    {path: 'org/:organization/login-email', component: EmailComponent, canActivate: [OrganizationGuard]}
];

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        SignupComponent,
        RegisterComponent,
        LoginComponent,
        EmailComponent,
        AvatarSelectorComponent
    ],
    exports: [
        SignupComponent,
        LoginComponent,
        EmailComponent,
        AvatarSelectorComponent
    ],
    providers: [
        AuthGuard,
        AdminAuthGuard,
        GameGuard,
        AvatarService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true}
    ]
})
export class AuthModule {
}
