<div class="container">
  <div class="content-1">
    <br/>
    <div class="container">
      <mat-card>
        <h2 i18n="@@home-title">Pilotage du Blindtest</h2>
        <form (ngSubmit)="onSubmit()" [formGroup]="orgForm" class="full-width">
          <mat-form-field class="mat-headline">
            <input matInput placeholder="Code Équipe" i18n-placeholder="@@home-playerCodePlaceholder" formControlName="organization" class="capitalize" required/>
            <mat-error *ngIf="organization.hasError('required')" i18n="@@home-enterPlayerCode">
              Merci d'entrer votre Code équipe
            </mat-error>
          </mat-form-field>
          <br/>
          <div class="centered">
            <button mat-raised-button color="primary" type="submit" [disabled]="!orgForm.valid" i18n="@@home-goToDashboard">Aller vers mon interface
            </button>
          </div>
          <div [hidden]="!error" class="alert alert-danger">
            <br/>
            <span i18n="@@home-errorUnknown">Une erreur s'est produite.</span>
            <br/>
            <span i18n="@@home-errorIsTeamValid">Votre code équipe est-il valide ?</span>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
