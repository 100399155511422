import {Component, OnDestroy, OnInit} from '@angular/core';
import {PlayerService} from '../../../core/services/player/player.service';
import {ScoreService} from '../../../core/services/score/score.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {OrganizationService} from '../../../core/services/organization/organization.service';
import {mergeMap, take} from 'rxjs/operators';
import {GameService} from '../../../core/services/game/game.service';
import {ErrorHandler} from '../../../core/handler/error-handler';
import {Gameplay, Player} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-bonus',
    templateUrl: './bonus.component.html',
    styleUrls: ['./bonus.component.scss']
})
export class BonusComponent implements OnInit, OnDestroy {
    private _playerCountSubscription: Subscription;
    bonusSize = 'medium';
    constructor(
        private _blindingService: OrganizationService,
        private _playerService: PlayerService,
        private _scoreService: ScoreService,
        private _gameService: GameService,
        private _location: Location,
        private _router: Router,
        private _errorHandler: ErrorHandler) {
    }

    ngOnInit() {
        this.onPlayerSelected = this.onPlayerSelected.bind(this);
    }

    ngOnDestroy() {
        if (this._playerCountSubscription) {
            this._playerCountSubscription.unsubscribe();
        }
    }

    onPlayerSelected(player: Player) {
        this._gameService.currentGameMetadata()
            .pipe(take(1))
            .pipe(mergeMap(meta => {
                let bonus = 1;
                if (this.bonusSize === 'medium') {
                    bonus = 2;
                }
                if (this.bonusSize === 'big') {
                    bonus = 3;
                }
                if (meta.scoreStrategy === Gameplay.FasterStrongerPerSet) {
                    bonus *= 25;
                    if (this.bonusSize === 'big') {
                        bonus = 100;
                    }
                }
                return this._scoreService.giveBonus(player.id, bonus);
            }))
            .subscribe(() => {
                this._router.navigate([`/org/${this._blindingService.getOrganizationTagSnapshot()}/game`]);
            });
    }

    back() {
        this._location.back();
    }

}
