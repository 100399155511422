import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ImportFileProcess} from './models/import-file-process';
import {BlindtestService} from '../../../core/services/blindtest/blindtest.service';
import {LoggerService} from '../../../core/services/logger/logger.service';
import {Location} from '@angular/common';
import {MatTableDataSource} from '@angular/material/table';

import {ImportListener} from './models/import-listener';
import {Blindtest, BlindtestImportDescriptor} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-import-blindtest',
    templateUrl: './import-blindtest.component.html',
    styleUrls: ['./import-blindtest.component.scss']
})
export class ImportBlindtestComponent implements OnInit {

    importListener: ImportListener;
    dataSource: MatTableDataSource<Blindtest>;
    @Output()
    events = new EventEmitter<ImportListener>();

    constructor(private router: Router, private location: Location,
                private http: HttpClient, private blindtestService: BlindtestService, private logger: LoggerService) {
    }

    ngOnInit() {
    }

    async onFileInput(event) {
        if (event.target.files && event.target.files.length > 0) {
            this.importListener = new ImportListener();
            this.importListener.processes = [...event.target.files].map((f, i) => new ImportFileProcess(f, i))
                .filter(p => p.file.name.indexOf('.json') > -1);

            const promises = this.importListener.processes.map(ip => {
                const reader = new FileReader();
                return new Promise((resolve, reject) => {
                    // Will asynchronously load uploaded file
                    reader.addEventListener('load', () => {
                        ip.loading = false;
                        this.events.next(this.importListener);
                        // Try JSON parsing or fail
                        let results = null;
                        try {
                            if (typeof reader.result === 'string') {
                                results = JSON.parse(reader.result);
                            } else {
                                ip.errors = {wrongFileType: true};
                                reject({wrongFileType: true});
                            }
                        } catch (e) {
                            this.logger.warn(e);
                            ip.errors = {wrongSchema: true};
                            reject({wrongSchema: true});
                            return;
                        }
                        // Try recognize schema
                        if (results === null || typeof results !== 'object' || !results.sets || !Array.isArray(results.sets)) {
                            ip.errors = {wrongSchema: true};
                            reject({wrongSchema: true});
                            return;
                        }
                        // Parse wrapper
                        let wrapper: BlindtestImportDescriptor = null;
                        try {
                            wrapper = BlindtestImportDescriptor.fromDto(results);
                        } catch (e) {
                            console.log(e);
                            ip.errors = {wrongSchema: true};
                            reject({wrongSchema: true});
                            return;
                        }
                        // ALL GOOD! Just need to upload now
                        ip.valid = true;
                        ip.uploading = true;
                        this.events.next(this.importListener);
                        return this.blindtestService.import(wrapper).toPromise().then(() => {
                            ip.uploading = false;
                            ip.done = true;
                            resolve(ip);
                        }, (err) => {
                            ip.uploading = false;
                            ip.errors = {apiError: err};
                            reject({apiError: err});
                        });
                    });
                    ip.loading = true;
                    reader.readAsText(ip.file, 'UTF-8');
                }).catch(e => {
                    if (!e.wrongFileType && !e.wrongSchema || !e.apiError) {
                        ip.errors = {unknownError: e};
                    }
                });
            });
            this.importListener.pending = true;
            this.events.next(this.importListener);
            for (const p of promises) {
                await p;
                this.events.next(this.importListener);
            }
            this.importListener.pending = false;
            this.importListener.completed = true;
            this.events.next(this.importListener);
        }
    }
    goBack() {
        this.location.back();
    }
}
