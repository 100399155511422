import {Injectable} from '@angular/core';

import {AngularFireDatabase} from '@angular/fire/compat/database';

import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, distinctUntilChanged, map, take} from 'rxjs/operators';
import {unsubscribe} from '../../handler/subscription-handler';
import {ErrorHandler} from '../../handler/error-handler';
import {LoggerService} from '../logger/logger.service';
import {OrganizationHttp, OrganizationFirebase, Organization} from '@frogconnexion/core-common';
import {PinAvailabilityResult} from '@frogconnexion/blinding-common';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {
    private _organizationSubject: Subject<Organization>;
    private _organizationTagSubject: Subject<string>;
    private _organizationSubscription: Subscription;
    private _organizationTagSnapshot: string;
    private _organizationSnapshot: Organization;

    constructor(private _fb: AngularFireDatabase,
                private http: HttpClient,
                private _errorHandler: ErrorHandler,
                private _logger: LoggerService) {
        this._organizationSubject = new ReplaySubject<Organization>(1);
        this._organizationTagSubject = new ReplaySubject<string>(1);
        this._organizationTagSubject.subscribe((organization: string) => {
            this._organizationTagSnapshot = organization;
            unsubscribe(this._organizationSubscription);
            this._organizationSubscription =
                this._fb.object<OrganizationFirebase>(`/organizations/${organization}`).valueChanges().subscribe(o => {
                    const org = Organization.fromFirebase(o);
                    this._organizationSnapshot = org;
                    this._organizationSubject.next(org);
                });
        });
    }

    updateOrganization(organization: string) {
        this._organizationTagSubject.next(organization);
    }

    // Observables
    organization(): Observable<Organization> {
        return this._organizationSubject.pipe(distinctUntilChanged());
    }

    organizationTag(): Observable<string> {
        return this._organizationTagSubject;
    }

    getOrganizationTagSnapshot(): string {
        return this._organizationTagSnapshot;
    }

    getOrganizationSnapshot(): Organization {
        return this._organizationSnapshot;
    }

    isValid(organization: string): Observable<boolean> {
        return this.http.get<OrganizationHttp>(`/public/org/${organization}/appstate`)
            .pipe(this._errorHandler.retryThreeTimesOrError())
            .pipe(map((o: OrganizationHttp) => {
                return !!o.organization;
            }));
    }

    isPinAvailable(pin: string): Observable<PinAvailabilityResult> {
        return this.http.get<PinAvailabilityResult>(`/public/pin?pin=${pin}`)
            .pipe(this._errorHandler.retryThreeTimesOrError());
    }
}
