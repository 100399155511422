import {Component, Input, OnInit} from '@angular/core';
import {SectionImportDescriptor} from '@frogconnexion/blinding-common';

@Component({
  selector: 'bt-section-review-widget',
  templateUrl: './section-review-widget.component.html',
  styleUrls: ['./section-review-widget.component.scss']
})
export class SectionReviewWidgetComponent implements OnInit {

  @Input()
  section: SectionImportDescriptor;

  constructor() { }

  ngOnInit() {
  }

}
