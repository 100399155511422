import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ErrorHandler,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {ScoreService} from '../../services/score/score.service';
import {Subscription} from 'rxjs';
import {ScoreDataSource} from '../../services/score/score.datasource';
import {PlayerService} from '../../services/player/player.service';
import {unsubscribe} from '../../handler/subscription-handler';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaderboardComponent implements OnInit, OnDestroy {

  playerCount: number;
  ds: ScoreDataSource;
  hide: boolean;

  @Input() message: string;
  @Input() revealed: boolean;
  @Input() hideLeaderboardPreview: Function;
  @Input() showLeaderboard: Function;
  @Input() hideLeaderboard: Function;
  @Input() limit = -1;
  @Input() offset = 0;
  @Input() showZeros = false;

  private _playerCountSubscription: Subscription;


  constructor(private _playerService: PlayerService,
              private _scoreService: ScoreService,
              private _errorHandler: ErrorHandler,
              private _changeDetectorRef: ChangeDetectorRef) {
    this.playerCount = 2;
  }

  ngOnInit() {
    this.ds = new ScoreDataSource(this.limit, this._scoreService, this._errorHandler);
    this._playerCountSubscription = this._playerService.playerCount().subscribe((playerCount) => {
      this.playerCount = playerCount;
      this._changeDetectorRef.detectChanges();
    });
    if (this.hideLeaderboard) {
      this.hide = true;
    }
  }

  ngOnDestroy() {
    unsubscribe(this._playerCountSubscription);
  }

}
