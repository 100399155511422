import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PlayerService} from '../../core/services/player/player.service';
import {BlindtestService} from '../../core/services/blindtest/blindtest.service';
import {GameService} from '../../core/services/game/game.service';
import {GameControlsService} from '../../core/services/gamecontrols/gamecontrols.service';
import {GamePublicControlsService} from '../../core/services/gamepubliccontrols/gamepubliccontrols.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ScoreService} from '../../core/services/score/score.service';
import {ReviewService} from '../../core/services/review/review.service';
import {MatDialog} from '@angular/material/dialog';
import {unsubscribe} from '../../core/handler/subscription-handler';
import {ValidationDialogComponent} from '../../core/components/dialog/validation-dialog/validation-dialog.component';
import {OrganizationService} from '../../core/services/organization/organization.service';
import {mergeMap} from 'rxjs/operators';
import {ShowtimeService} from '../../core/services/showtime/showtime.service';
import {
    Blindtest,
    GameControls,
    GameMetadata, Section,
    SetOfBlindtest,
    ShowtimeSettings,
    Song,
    SongPendingReview,
    Scoresheet,
} from '@frogconnexion/blinding-common';


@Component({
    selector: 'app-game',
    templateUrl: './game.component.html',
    styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit, OnDestroy {

    private _currentBlindtestSubscription: Subscription;
    private _currentGameMetadataSubscription: Subscription;
    private _currentGameStateSubscription: Subscription;
    private _currentAdminSongSubscription: Subscription;
    private _playersSubscription: Subscription;
    private _pendingReviewsSubscription: Subscription;
    private _solvedPendingReviewsSubscription: Subscription;
    private _submissionCountSubscription: Subscription;
    private _finishDialogSubscription: Subscription;
    private _showLeaderboardSubscription: Subscription;
    private _enableJokersSubscription: Subscription;
    private _lockPlayersSubscription: Subscription;
    private _reviewCountSubscription: Subscription;
    private areYouSureSubscription: Subscription;
    private currentShowtimeSettingsSubscription: Subscription;
    private _previewLeaderboardSubscription: Subscription;

    currentShowtimeSettings: ShowtimeSettings;
    currentGameMetadata: GameMetadata;
    currentGameState: GameControls;
    currentSong: Song;
    hasCurrentGame: boolean;
    playerCount: number;
    submissionCount: number;
    reviewCount: number;
    blindtest: Blindtest;
    blindSets: SetOfBlindtest[];
    pendingReviews: SongPendingReview[];
    solvedPendingReviews: SongPendingReview[];
    previewLeaderboard: boolean;
    leaderboardRevealed: boolean;
    hasJokersInGame: boolean;
    jokersEnabled: boolean;
    lockPlayers: boolean;
    controlsEnabled = false;

    constructor(private _blindingService: OrganizationService,
                private _playerService: PlayerService,
                private _showtimeService: ShowtimeService,
                private _blindtestService: BlindtestService,
                private _gameService: GameService,
                private _gameControlsService: GameControlsService,
                private _gamePublicControlsService: GamePublicControlsService,
                private _router: Router,
                private _route: ActivatedRoute,
                private _scoreService: ScoreService,
                private _reviewService: ReviewService,
                private _dialog: MatDialog) {
        this.toggleLeaderboardPreview = this.toggleLeaderboardPreview.bind(this);
        this.hideLeaderboard = this.hideLeaderboard.bind(this);
        this.revealLeaderboard = this.revealLeaderboard.bind(this);
    }

    _updateHasCurrentGame() {
        this.hasCurrentGame = !!(this.currentGameMetadata && this.currentGameState && this.blindtest);
    }

    ngOnInit() {
        // Get players
        this._playersSubscription = this._playerService.playerCount()
            .subscribe(playerCount => {
                this.playerCount = playerCount;
            });

        // Get current blind test
        this._currentBlindtestSubscription = this._blindtestService.currentBlindtest()
            .subscribe(bt => {
                this.blindtest = bt;
                this.blindSets = bt ? bt.sets : [];
                this._updateHasCurrentGame();
            });

        // Get current metadata
        this._currentGameMetadataSubscription = this._gameService.currentGameMetadata()
            .subscribe(gm => {
                this.currentGameMetadata = gm;
                if (!gm) {
                    this._router.navigate([`/org/${this._blindingService.getOrganizationTagSnapshot()}/dashboard`]);
                    return;
                }
                this.hasJokersInGame = gm.jokers > 0;
                this._updateHasCurrentGame();
            });

        // Get current state of game
        this._currentGameStateSubscription = this._gameService.currentGameControls()
            .subscribe(state => {
                this.currentGameState = state;
                this._updateHasCurrentGame();
            });
        // Current submissions and reviews
        this._submissionCountSubscription = this._reviewService.songSubmissionCount().subscribe(submissionCount => {
            this.submissionCount = submissionCount;
        });
        this._reviewCountSubscription = this._reviewService.songReviewsCount().subscribe(reviewCount => {
            this.reviewCount = reviewCount;
        });

        this._pendingReviewsSubscription = this._reviewService.currentPendingReviewsState().subscribe(pendingReviews => {
            this.pendingReviews = pendingReviews;
        });
        this._solvedPendingReviewsSubscription = this._reviewService.currentSolvedPendingReviewsState().subscribe(pendingReviews => {
            this.solvedPendingReviews = pendingReviews;
        });
        // Get current song
        this._currentAdminSongSubscription = this._gameService.currentSong()
            .subscribe(song => {
                this.currentSong = song;
            });

        this.currentShowtimeSettingsSubscription = this._showtimeService.showtimeSettings().subscribe(ss => {
            this.currentShowtimeSettings = ss;
        });

        this._previewLeaderboardSubscription = this._gamePublicControlsService.previewLeaderboard().subscribe(show => {
            this.previewLeaderboard = show;
        });
        this._showLeaderboardSubscription = this._gamePublicControlsService.revealLeaderboard().subscribe(show => {
            this.leaderboardRevealed = show;
        });
        this._enableJokersSubscription = this._gamePublicControlsService.enableJokers().subscribe(show => {
            this.jokersEnabled = show;
        });
        this._lockPlayersSubscription = this._gamePublicControlsService.lockPlayers().subscribe(lockPlayers => {
            this.lockPlayers = lockPlayers;
        });
    }

    ngOnDestroy() {
        unsubscribe(
            this._currentBlindtestSubscription,
            this._currentGameMetadataSubscription,
            this._currentGameStateSubscription,
            this._playersSubscription,
            this._pendingReviewsSubscription,
            this._solvedPendingReviewsSubscription,
            this._submissionCountSubscription,
            this._reviewCountSubscription,
            this._showLeaderboardSubscription,
            this._previewLeaderboardSubscription,
            this._enableJokersSubscription,
            this.currentShowtimeSettingsSubscription,
            this._lockPlayersSubscription);
    }


    get hasReviewedEverything() {
        return this.getUnsolvedPendingReviewCount() === 0;
    }

    get getRemainingItemsToReview() {
        return this.submissionCount - this.reviewCount;
    }


    startGame() {
        this._gameService.startCurrentGame().subscribe(() => {
        });
    }

    unsetGame(): void {
        const dialogRef = this._dialog.open(ValidationDialogComponent, {
            width: '300px',
            data: {action: 'cancel-game'}
        });
        unsubscribe(this._finishDialogSubscription);
        this._finishDialogSubscription = dialogRef.afterClosed().subscribe(activate => {
            if (activate) {
                const org = this._blindingService.getOrganizationTagSnapshot();
                this._gameService.unsetCurrentGame().subscribe(() => {
                    this._router.navigate([`/org/${org}/dashboard`]);
                });
            }
        });
    }


    finishGame(): void {
        const dialogRef = this._dialog.open(ValidationDialogComponent, {
            width: '300px',
            data: {action: 'finish-game'}
        });
        unsubscribe(this._finishDialogSubscription);
        this._finishDialogSubscription = dialogRef.afterClosed().subscribe(activate => {
            if (activate) {
                if (this.isLastSongOfGame()) {
                    this._gameControlsService.nextSong().pipe(mergeMap(() => this._gameService.finishCurrentGame()))
                        .subscribe(() => {
                            this._router.navigate([`/org/${this._blindingService.getOrganizationTagSnapshot()}/dashboard`]);
                        });
                } else {
                    this._gameService.finishCurrentGame().subscribe(() => {
                        this._router.navigate([`/org/${this._blindingService.getOrganizationTagSnapshot()}/dashboard`]);
                    });
                }
            }
        });
    }

    startSet(index: number) {
        this._gameControlsService.startSet(index).subscribe(() => {
        });
    }

    previousSong() {
        this._gameControlsService.previousSong().subscribe(() => {
        });
    }

    lockSong() {
        if (!this.hasReviewedEverything) {
            const dialogRef = this._dialog.open(ValidationDialogComponent, {
                width: '300px',
                data: {action: 'force-lock'}
            });
            unsubscribe(this.areYouSureSubscription);
            this.areYouSureSubscription = dialogRef.afterClosed().subscribe(activate => {
                if (activate) {
                    this._gameControlsService.lockSong().subscribe();
                }
            });
        } else {
            this._gameControlsService.lockSong().subscribe();
        }
    }

    revealSong() {
        this._gameControlsService.revealSong().subscribe(() => {
        });
    }

    nextSong() {
        this._gameControlsService.nextSong().subscribe(() => {
        });
    }

    resetSong() {
        const dialogRef = this._dialog.open(ValidationDialogComponent, {
            width: '300px',
            data: {action: 'force-reset'}
        });
        unsubscribe(this._finishDialogSubscription);
        this._finishDialogSubscription = dialogRef.afterClosed().subscribe(activate => {
            if (activate) {
                this._gameControlsService.resetSong().subscribe(() => {
                });
            }
        });

    }


    getCurrentSection(): Section {
        if (!this.currentGameState || !this.blindtest) {
            throw new Error('Cannot find section if blindtest, state of set, or state of section are null');
        }
        return this.blindtest
            .sets[this.currentGameState.blindtestControl.current]
            .sections[this.currentGameState.sectionControl.current];
    }

    toggleLeaderboardPreview() {
        this._gameControlsService.setPreviewLeaderboard(!this.previewLeaderboard).subscribe();
    }

    hideLeaderboard() {
        this._gameControlsService.revealLeaderboard(false).subscribe();
    }

    revealLeaderboard() {
        this._gameControlsService.revealLeaderboard(true).subscribe();
    }

    toggleJokers() {
        this._gameControlsService.setEnableJokers(!this.jokersEnabled).subscribe();
    }

    shouldShowControls(): boolean {
        return this.currentGameState.setControl.isRunning();
    }

    shouldDisableLock(): boolean {
        return this.currentGameState.songControl.locked;
    }

    shouldDisableReveal(): boolean {
        return !this.currentGameState.songControl.locked || this.currentGameState.songControl.revealed;
    }

    shouldDisplayStats(): boolean {
        return this.currentGameState.songControl.locked && !this.currentGameState.songControl.revealed;
    }

    shouldDisableNext(): boolean {
        return !this.currentGameState.songControl.revealed || !this.currentGameState.songControl.locked;
    }

    toggleLockPlayers() {
        this._gameControlsService.setLockPlayers(!this.lockPlayers).subscribe();
    }

    isGameFinished() {
        return this.currentGameState?.isFinished();
    }


    exportScoresheet() {
        this._scoreService.export().subscribe((scores) => {
            // tslint:disable-next-line:forin
            for (const i in scores) {
                this.downloadFile(scores[i]);
            }
        });
    }


    downloadFile(data: Scoresheet) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data.payload));
        element.setAttribute('download', `Leblindtest.fr - ${this.blindtest.name} - ${data.prefix} - set ${data.set + 1}.csv`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    isLastSongOfSection() {
        return this.currentGameState?.sectionControl?.size === this.currentGameState?.sectionControl?.position + 1;
    }

    isLastSongOfSet() {
        return this.isLastSongOfSection()
            && (this.currentGameState?.sectionControl?.sectionCount === this.currentGameState?.sectionControl?.current + 1);
    }

    isLastSongOfGame() {
        return this.isLastSongOfSet() &&
            (this.currentGameState?.blindtestControl?.setCount === this.currentGameState?.blindtestControl?.current + 1);
    }

    getSubmissionCount() {
        return this.submissionCount;
    }

    getUnsolvedPendingReviewCount() {
        return this.pendingReviews?.map(pr => pr.key)
            .filter(prId => this.solvedPendingReviews?.map(pr => pr.key).indexOf(prId) === -1).length || 0;
    }

    toggleShowtimeControl() {
        this.controlsEnabled = !this.controlsEnabled;
    }

    hasFeedbackForm() {
        return this.currentGameMetadata?.feedbackFormEnabled;
    }
}
