import {Component, OnInit} from '@angular/core';
import {Build} from "../../../core/models/build";
import {environment} from "../../../../../environments/environment";
import {BlindtestService} from "../../../core/services/blindtest/blindtest.service";

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

  build: Build;
  env = environment;

  constructor(private _blindtestService: BlindtestService) {

  }

  ngOnInit() {
    this._blindtestService.build().subscribe(build => {
      this.build = build;
    });
  }

}
