<div class="container">
  <mat-vertical-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="metaFormGroup" label="Général" i18n-label="@@blindtestEditPage-titleLabel">
      <form (ngSubmit)="onSubmit()" class="container form">
        <div [formGroup]="metaFormGroup">
          <mat-form-field class="blindtest-name-input" appearance="outline">
            <mat-label i18n="@@blindtestEditPage-nameOfBlindtest">Nom du blindtest</mat-label>
            <input #name formControlName="name" matInput [placeholder]="defaultBlindtestName" [errorStateMatcher]="matcher"/>
            <mat-error *ngFor="let validation of validationMessages.name">
              <mat-error class="error-message" *ngIf="formName.hasError(validation.type)">
                <mat-error class="error-message" *ngIf="validation.message === 'nom.required'"
                           i18n="@@blindtestEditPage-nameRequired">Nom requis.</mat-error>
                <mat-error class="error-message" *ngIf="validation.message === 'nom.invalide'"
                           i18n="@@blindtestEditPage-nameInvalid">Merci d\'entrer un nom valide (plus de 3 lettres)</mat-error>
              </mat-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="blindtest-setcount-input" appearance="outline">
            <mat-label i18n="@@blindtestEditPage-numberOfSets">Nombre de Sets</mat-label>
            <input type="number" [min]="1" [max]="10" [value]="blindtest.sets?.length" matInput
                   (change)="onSetCountChanged($event)"/>
          </mat-form-field>
          <br/>
          <mat-form-field class="blindtest-description-input" appearance="outline">
            <mat-label i18n="@@blindtestEditPage-descriptionOfBlindtest">Description du blindtest</mat-label>
            <textarea formControlName="description" matInput placeholder="Décrire votre blindtest" i18n-placeholder="@@blindtestEditPage-DescriptionOfBlindtestPlaceholder" [errorStateMatcher]="matcher"></textarea>
            <mat-error *ngFor="let validation of validationMessages.description">
              <mat-error class="error-message" *ngIf="formDescription.hasError(validation.type)">
             <mat-error class="error-message" *ngIf="validation.message === 'description.invalide'"
                           i18n="@@blindtestEditPage-descriptionInvalid">Merci d\'entrer une description valide (plus de 3 lettres)</mat-error>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <button mat-raised-button matStepperNext style="margin-top: 17px" color="primary" i18n="@@blindtestEditPage-nextStep">Suivant</button>
        </div>
      </form>
    </mat-step>
    <mat-step
      *ngFor="let set of blindtest.sets; let i = index;"
      [stepControl]="setFormGroups[i]"
      [label]="'Set N°' + (i + 1)">
      <app-set-edit-widget
      [setFormGroup]="setFormGroups[i]"
      [set]="set"
      [index]="i">
      </app-set-edit-widget>
    </mat-step>
    <mat-step label="Fin">
      <bt-review-widget [bt]="blindtest"></bt-review-widget>
      <div>
        <button mat-raised-button routerLink="../../../dashboard" i18n="@@blindtestEditPage-backToDashboard">Retour</button>
        &nbsp;
        <button mat-raised-button color="primary" (click)="saveBlindtest()" i18n="@@blindtestEditPage-saveBlindtest">Enregistrer</button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
  <div class="actions">
    <button mat-raised-button color="warn" *ngIf="edit" (click)="onDeleteClicked()" i18n="@@blindtestEditPage-deleteBlindtest">Supprimer</button>
  </div>
</div>
