import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ErrorHandler} from '../../handler/error-handler';
import {LoggerService} from '../logger/logger.service';

@Injectable({
    providedIn: 'root'
})
export class PermalinkService {

    constructor(private http: HttpClient,
                private errorHandler: ErrorHandler,
                private logger: LoggerService) {
    }

    getSlugSuggestions(organization: string, slug: string): Observable<string[]> {
        return this.http.get<string[]>(`/admin/org/${organization}/game/link/slug?search=${slug}`)
            .pipe(this.errorHandler.retryThreeTimesOrError())
            .pipe(map((o: string[]) => {
                return o;
            }));
    }
}
