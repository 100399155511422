<div mat-dialog-content>
  <mat-stepper orientation="vertical" [linear]="true" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel i18n="@@activateBlindtest-FirstFormTitle">Général</ng-template>
        <!--
        MANAGED BY FROG LAB
        <mat-checkbox formControlName="managedExternally" class="checkbox">
          <ng-container i18n="@@activateBlindtestDialog-managedExternally">Géré par FrogLab</ng-container>
        </mat-checkbox>
        <br/><br/>
        -->
        <!-- TEST OR REAL -->
        <label for="test" class="gameplay-label" i18n="@@activateBlindtestDialog-partyType">Type de Partie</label>
        <br/>
        <mat-radio-group class="radio-group-row" id="test" formControlName="test">
          <mat-radio-button class="radio-button" [value]="false" i18n="@@activateBlindtestDialog-realGame">Jeu réel</mat-radio-button>
          <mat-radio-button class="radio-button" [value]="true" i18n="@@activateBlindtestDialog-test">Test</mat-radio-button>
        </mat-radio-group>
        <!-- LANG -->
        <br/><br/>
        <label for="lang" class="gameplay-label" i18n="@@activateBlindtestDialog-animationlanguage">Langue de l'animation</label>
        <br/>
        <mat-radio-group class="radio-group-row" id="lang" formControlName="lang">
          <mat-radio-button class="radio-button" value="fr" i18n="@@activateBlindtestDialog-langFr">Fr</mat-radio-button>
          <mat-radio-button class="radio-button" value="en" i18n="@@activateBlindtestDialog-langEn">En</mat-radio-button>
        </mat-radio-group>
        <!-- USER NAMING STRATEGY-->
        <br/><br/>
        <label for="playerStrategy" class="gameplay-label" i18n="@@activateBlindtestDialog-playerCreation">Création de joueur</label>
        <br/>
        <mat-radio-group class="radio-group" id="playerStrategy" formControlName="playerNamingStrategy">
          <mat-radio-button class="radio-button" value="playerName" i18n="@@activateBlindtestDialog-playerNickname">Pseudo</mat-radio-button>
          <mat-radio-button class="radio-button" value="fullName" i18n="@@activateBlindtestDialog-fullName">Pseudo + Nom Prénom</mat-radio-button>
          <mat-radio-button class="radio-button" value="email" i18n="@@activateBlindtestDialog-playerNicknameAndEmail">Pseudo + Email</mat-radio-button>
        </mat-radio-group>
        <br/><br/>
        <section class="radio-group">
          <mat-checkbox [disabled]="limited" [checked]="false" class="radio-button" formControlName="servicesEnabled">
            <span *ngIf="!(formServices.value?.length > 0)" i18n="@@activateBlindtestDialog-byTeam">Par équipes</span>
            <span *ngIf="formServices.value?.length > 0">{{formServices.value.length}}<span i18n="@@activateBlindtestDialog-configuredTeams"> Équipes configurées</span></span>
          </mat-checkbox>
        </section>

        <section class="services" *ngIf="formServicesEnabled.value">
          <br/>
          <button mat-raised-button color="accent" *ngIf="formServices.value?.length === 0" (click)="serviceImport.click()" i18n="@@activateBlindtestDialog-import">Importer</button>
          <input #serviceImport type="file" (change)="onFileInput($event)" style="display:none;" accept=".json">
          <div *ngIf="serviceError" i18n="@@activateBlindtestDialog-serviceError">Erreur</div>
          <div *ngIf="formServices.value?.length > 0">
            <mat-list class="configured-services" dense>
              <mat-list-item *ngFor="let x of formServices.value">{{x}}</mat-list-item>
            </mat-list>
          </div>
        </section>
        <br/><br/>
        <label for="ageRange" class="gameplay-label" i18n="@@activateBlindtestDialog-ageRangeAudience">Age de l'audience</label>
        <div class="range-container"><app-range-slider id="ageRange" class="mat-slider" [disabled]="formAgeRange.disabled" [min]="15" [max]="70" [value]="{min: 25, max: 55}" (output)="setAgeRange($event)"></app-range-slider></div>
        <br/>
        <hr/>
        <br/>
        <!-- PIN -->
        <mat-form-field class="full-width" *ngIf="formPin.enabled">
          <input matInput class="pin"
                 [disabled]="limited"
                 spellcheck="false"
                 autocomplete="off"
                 placeholder="Game PIN"
                 i18n-placeholder="@@activateBlindtestDialog-gamePinPlaceholder"
                 formControlName="pin"/>
          <mat-hint *ngIf="pinEntered && pinAvailability?.available" i18n="@@activateBlindtestDialog-pinAvailable">Ce PIN est disponible</mat-hint>
          <mat-hint class="error" *ngIf="(pinEntered && !(pinAvailability && pinAvailability?.available))" i18n="@@activateBlindtestDialog-pinAlreadyTakenErrorWithDetails">Ce PIN est déjà pris par l'organisation {{pinAvailability?.organization}} pour l'événement {{pinAvailability?.eventName}}</mat-hint>
          <mat-hint class="error" *ngIf="!pinEntered" i18n="@@activateBlindtestDialog-pinMandatory">Merci d'entrer un PIN</mat-hint>
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="formPin.disabled">
          <input matInput class="pin"
                 placeholder="Game PIN"
                 [disabled]="true"
                 i18n-placeholder="@@activateBlindtestDialog-gamePinPlaceholder"
                 value="-"
          />
        </mat-form-field>
        <br/>
        <br/>
        <br/>
        <div>
          <button mat-button matStepperNext color="primary">Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel i18n="@@activateBlindtestDialog-secondStepLabel">Jeu & Gameplay</ng-template>
        <!-- GAMEPLAY -->
        <label for="gameplay" class="gameplay-label" i18n="@@activateBlindtestDialog-gamePlayLabel">Gameplay</label>
        <br/>
        <mat-radio-group class="radio-group" id="gameplay" formControlName="gameplay" [disabled]="true">
          <mat-radio-button class="radio-button" *ngFor="let x of gameplays" [value]="x.value">
            {{ x.name }}
          </mat-radio-button>
        </mat-radio-group>
        <br/><br/>
        <!-- Jokers -->
        <label class="jokers-label" i18n="@@activateBlindtestDialog-jokerLabel">Jokers</label>
        <br/>
        <mat-checkbox formControlName="jokerDoubleOrNothing" class="checkbox">
          <ng-container i18n="@@activateBlindtestDialog-jokerDoubleOrNothing">Quitte ou double</ng-container>
        </mat-checkbox>
        <br/>
        <mat-checkbox formControlName="jokerSpy" class="checkbox">
          <ng-container i18n="@@activateBlindtestDialog-jokerSpy">Espion</ng-container>
        </mat-checkbox>
        <br/>
        <mat-checkbox formControlName="jokerMindReader" class="checkbox">
          <ng-container i18n="@@activateBlindtestDialog-joker5050">50-50</ng-container>
        </mat-checkbox>
        <br/><br/>
        <div>
          <button mat-button matStepperNext color="primary">Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup">
      <form [formGroup]="thirdFormGroup">
        <ng-template matStepLabel i18n="@@activateBlindtestDialog-secondStepLabel">Avancé</ng-template>
        <!-- FEEDBACK FORM -->
        <section class="radio-group" id="enableFeedbackForm">
          <mat-checkbox [disabled]="limited" class="radio-button" formControlName="feedbackFormEnabled">
            <ng-container i18n="@@activateBlindtestDialog-enableFeedbackForm">Questionnaire de satisfaction</ng-container>
          </mat-checkbox>
        </section>

        <br/>
        <br/>
        <!-- PERMALINK -->
        <section class="radio-group" id="enablePermalink">
          <mat-checkbox [disabled]="limited || formManagedExternally.value" class="radio-button" formControlName="permalinkEnabled">
            <span i18n="@@activateBlindtestDialog-enablePermalink">Activer Permalien</span>
          </mat-checkbox>
        </section>
        <ng-container *ngIf="formPermalinkEnabled.value">
          <mat-form-field class="full-width">
            <input matInput
                   spellcheck="false" autocomplete="off"
                   placeholder="Nom du Permalien*"
                   i18n-placeholder="@@activateBlindtestDialog-permalinkPlaceholder"
                   formControlName="slug"
            >
            <mat-hint *ngIf="slugEntered && !isShowingSuggestions && slugAvailable" i18n="@@activateBlindtestDialog-slugAvailable">Ce nom est disponible</mat-hint>
            <mat-hint class="error" *ngIf="!slugEntered" i18n="@@activateBlindtestDialog-enterSlugMessage">Merci d'entrer un slug</mat-hint>
            <mat-hint class="error" *ngIf="slugEntered && !isShowingSuggestions && !slugAvailable" i18n="@@activateBlindtestDialog-slugAlreadyTakenError">Nom déjà pris.</mat-hint>
            <mat-hint class="error" *ngIf="isShowingSuggestions" i18n="@@activateBlindtestDialog-slugAlreadyTakenSuggestionsError">Nom déjà pris. Suggestions</mat-hint>
          </mat-form-field>
          <br/>
          <mat-form-field *ngIf="isShowingSuggestions" class="suggestion-tags">
            <mat-chip-list>
              <mat-chip (click)="setSlug(slug)" *ngFor="let slug of slugSuggestionList">{{slug}}</mat-chip>
            </mat-chip-list>
          </mat-form-field>
        </ng-container>
        <br/>
        <br/>

        <!-- BRANDING -->
        <section class="radio-group" id="enableBranding">
          <mat-checkbox [disabled]="limited" class="radio-button" formControlName="brandingEnabled">
            <ng-container i18n="@@activateBlindtestDialog-enableBranding">Activer Branding</ng-container>
          </mat-checkbox>
        </section>
        <br/>
        <section class="branding" *ngIf="formBrandingEnabled.value">
          <div class="branding-item">
            <button mat-raised-button color="accent" *ngIf="!formBranding.value?.logo" (click)="brandingLogoImport.click()" i18n="@@activateBlindtestDialog-logoCountdown">
              Logo Principal
            </button>
            <input #brandingLogoImport type="file" (change)="onLogoInput($event)" style="display:none;" accept=".jpg,.jpeg,.png,.svg">
            <div *ngIf="brandingLogoError" i18n="@@activateBlindtestDialog-brandingLogoError">Erreur</div>
            <div *ngIf="formBranding.value?.logo">
              <img [src]="'data:' + formBranding.value.logo.mimeType + ';base64,' + formBranding.value.logo.blob" alt="Branded logo" class="branding-logo"/>
            </div>
          </div>
          <div class="branding-item">
            <button mat-raised-button color="accent" *ngIf="!formBranding.value?.logoCorner" (click)="brandingLogoCornerImport.click()" i18n="@@activateBlindtestDialog-CornerLogo">Logo d'angle (carré)</button>
            <input #brandingLogoCornerImport type="file" (change)="onLogoCornerInput($event)" style="display:none;" accept=".png,.svg">
            <div *ngIf="brandingLogoError" i18n="@@activateBlindtestDialog-brandingLogoError">Erreur</div>
            <div *ngIf="formBranding.value?.logoCorner">
              <img [src]="'data:' + formBranding.value.logoCorner.mimeType + ';base64,' + formBranding.value.logoCorner.blob" alt="Branded logo corner" class="branding-logo"/>
            </div>
          </div>
        </section>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Go !</ng-template>
      <p i18n="@@activateBlindtestDialog-ready">C'est tout bon ! On y va ?</p>
      <ng-container *ngIf="pinEntered && !pinAvailability?.available">
        <br/>
        <mat-error class="mat-error" i18n="@@activateBlindtestDialog-pinAlreadyTakenError">Ce PIN est déjà pris</mat-error>
      </ng-container>
      <ng-container *ngIf="!pinEntered">
        <br/>
        <mat-error class="mat-error" i18n="@@activateBlindtestDialog-pinMandatory">Merci d'entrer un PIN</mat-error>
      </ng-container>
      <ng-container *ngIf="!slugAvailable && slugEntered">
        <br/>
        <mat-error class="mat-error" i18n="@@activateBlindtestDialog-slugAlreadyTakenError">Ce Permalien est déjà pris</mat-error>
      </ng-container>
      <ng-container *ngIf="!slugEntered && formPermalinkEnabled.value">
        <br/>
        <mat-error class="mat-error" i18n="@@activateBlindtestDialog-slugMandatory">Merci d'entrer un permalien</mat-error>
      </ng-container>
      <div *ngIf="!submitting">
        <button mat-button (click)="backToDashboard()" i18n="@@activateBlindtestDialog-back">Retour</button>
        <button mat-raised-button color="primary" [disabled]="!formManagedExternally.value && (!pinLegit || !pinEntered || (!slugAvailable  && formPermalinkEnabled.value))" i18n="@@activateBlindtestDialog-go" (click)="assignGame()">Allez !</button>
      </div>
      <div *ngIf="submitting">
        <dot-spinner></dot-spinner>
      </div>

    </mat-step>
  </mat-stepper>
  <br/>
</div>

