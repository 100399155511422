import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Page} from "../models/page";
import {HttpClient} from "@angular/common/http";
import {ErrorHandler} from "../../../core/handler/error-handler";
import {LibraryExportWrapper} from "../import-export/models/library-export-wrapper";
import {ArtistSuggestion} from "../models/artist-suggestion";
import {LibraryFilter} from "./library-datasource";
import {OrganizationService} from "../../../core/services/organization/organization.service";
import {Song} from '@frogconnexion/blinding-common';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  private _organization: string;

  constructor(private _http: HttpClient,
              private _blindingService: OrganizationService,
              private _errorHandler: ErrorHandler) {
    this._blindingService.organizationTag().subscribe(o => {
      this._organization = o;
    });
  }

  save(song: Song): Observable<Song> {
    console.log(song);
    return this._http.post<Song>(`/admin/org/${this._organization}/library/`, song)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  getOne(id: string): Observable<Song> {
    return this._http.get<Song>(`/admin/org/${this._organization}/library/${id}`)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  getItems(filter: LibraryFilter, page: number, pageSize: number): Observable<Page> {
    return this._http.get<Page>(`/admin/org/${this._organization}/library?page=${page}&pageSize=${pageSize}${this._computeFilterQueryString(filter)}`)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  deleteItem(id: string): Observable<any> {
    return this._http.delete(`/admin/org/${this._organization}/library/${id}`)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  import(wrapper: LibraryExportWrapper): Observable<any> {
    return this._http.post(`/admin/org/${this._organization}/library/import`, wrapper)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  export(): Observable<any> {
    return this._http.get(`/admin/org/${this._organization}/library/export`)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  findArtistSuggestions(filter: string): Observable<ArtistSuggestion[]> {
    return this._http.get<ArtistSuggestion[]>(`/admin/org/${this._organization}/library/artists/autocomplete?search=${filter}`)
      .pipe(this._errorHandler.retryThreeTimesOrError());
  }

  private _computeFilterQueryString(filter: LibraryFilter): string {
    let result = "";
    if (filter && (filter.type != null) && filter.type >= 0) {
      result += "&type=" + filter.type;
    }
    if (filter && filter.search && filter.search.trim().length > 0) {
      result += "&search=" + filter.search;
    }
    return result;
  }

  removeDuplicate(): Observable<any> {
    console.log("deduplicate")
    return this._http.delete(`/admin/org/${this._organization}/library/clearDuplicateSongs`)
        .pipe(this._errorHandler.retryThreeTimesOrError());
  }
}
