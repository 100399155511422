import {AfterViewInit, Component, ElementRef, OnChanges, OnDestroy, SimpleChanges, ViewChild} from '@angular/core';
import {DoughnutController, ArcElement, Legend, Tooltip, Chart} from 'chart.js';
import {PlayerService} from '../../../core/services/player/player.service';
import {Observable, Subscription} from 'rxjs';
import {GameService} from '../../../core/services/game/game.service';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {StatsForSong} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-admin-stats-widget',
    templateUrl: './stats-widget.component.html',
    styleUrls: ['./stats-widget.component.scss']
})
export class AdminStatsWidgetComponent implements AfterViewInit, OnDestroy, OnChanges {

    stats: StatsForSong;
    @ViewChild('statsElement')
    statsRef: ElementRef<HTMLCanvasElement>;
    private chart: Chart;
    computingStats = true;
    computingResults = true;
    soleWinner: Observable<string>;
    soleLoser: Observable<string>;
    private _playersSubscription: Subscription;
    private _gameStateSubscription: Subscription;

    constructor(private _gameService: GameService, private _playerService: PlayerService) {
        Chart.register(DoughnutController, Legend, Tooltip, ArcElement);
        this._gameStateSubscription = this._gameService.currentGameControls().subscribe(gs => {
            if (!gs) {
                this.stats = null;
                this.computingStats = true;
                return;
            }
            this.stats = gs.songControl.stats;
            this.computingStats = gs.songControl.computingStats;
            this.computingResults = gs.songControl.computingResults;
            this.loadStats();
        });
    }

    noOneAnswered(): boolean {
        return this.stats?.responsePercent === 0;
    }

    noOneSucceededEvenPartially(): boolean {
        return !this.noOneAnswered() && ((this.stats.successPercent + this.stats.partialSuccessPercent) === 0);
    }

    fewSucceeded(): boolean {
        return !this.noOneAnswered() &&
            ((this.stats.successPercent + this.stats.partialSuccessPercent) > 0) &&
            ((this.stats.successPercent + this.stats.partialSuccessPercent) < 30);
    }

    allSucceeded(): boolean {
        return (this.stats?.failurePercent === 0) && (this.stats.answeredPlayers > 1) && !this.noOneAnswered();
    }

    ngOnDestroy() {
        unsubscribe(this._playersSubscription, this._gameStateSubscription);
    }

    private displayChart() {
        if (!this.statsRef?.nativeElement) {
            return;
        }
        const width = window.screen.width;
        let fontSize = 14;
        if (width > 1500) {
            fontSize = 24;
        }
        const responseRatio = this.stats.responsePercent / 100;
        if (this.chart) {
            this.chart.destroy();
        }
        // @ts-ignore
        this.chart = new Chart(this.statsRef.nativeElement, {
            type: 'doughnut',
            data: {
                labels: ['Abstention', 'Tout juste', 'En partie juste', 'Tout faux'],
                datasets: [{
                    type: 'doughnut',
                    label: '%',
                    data: [
                        Math.floor(100 - this.stats.responsePercent),
                        Math.floor(this.stats.successPercent * responseRatio),
                        Math.floor(this.stats.partialSuccessPercent * responseRatio),
                        Math.floor(this.stats.failurePercent * responseRatio)],
                    backgroundColor: [
                        'rgba(255, 206, 86, 0.8)',
                        'rgba(75, 192, 192, 0.8)',
                        'rgba(54, 162, 235, 0.8)',
                        'rgba(255, 99, 132, 0.8)',
                    ],
                    borderColor: [
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255,99,132,1)',
                    ],
                    borderWidth: 8
                }]
            }
        });
    }

    private loadStats() {
        if (this.statsRef?.nativeElement && !this.computingStats && this.stats) {
            this.displayChart();
        }
    }

    ngAfterViewInit(): void {
        this.loadStats();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.stats && !changes.stats.previousValue) {
            this.loadStats();
        }
    }

}
