import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminAuthGuard} from '../../core/auth/service/guards/admin-auth.guard';
import {MaterialModule} from '../../core/material/material.module';
import {CoreModule} from '../../core/core.module';
import {RouterModule} from '@angular/router';
import {BlindTestWidgetComponent} from './blindtest-widget/blindtest-widget.component';
import {ActivateBlindtestComponent} from './activate-blindtest/activate-blindtest.component';
import {DashboardComponent} from './dashboard.component';
import {BlindtestDetailsPageComponent} from './blindtest-details-page/blindtest-details-page.component';
import {BlindtestEditPageComponent} from './blindtest-edit-page/blindtest-edit-page.component';
import {InputWidgetComponent} from './blindtest-edit-page/input-widget/input-widget.component';
import {SectionEditWidgetComponent} from './blindtest-edit-page/section-edit-widget/section-edit-widget.component';
import {ItemWidgetComponent} from './item-widget/item-widget.component';
import {ItemSelectionDialogComponent} from './blindtest-edit-page/item-selection-dialog/item-selection-dialog.component';
import {ListItemsWidgetComponent} from './blindtest-edit-page/list-items-widget/list-items-widget.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {SetEditWidgetComponent} from './blindtest-edit-page/set-edit-widget/set-edit-widget.component';
import {ReviewWidgetComponent} from './review-widget/review-widget.component';
import {SectionReviewWidgetComponent} from './section-review-widget/section-review-widget.component';
import {ImportBlindtestComponent} from './import-blindtest/import-blindtest.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {OrganizationGuard} from '../../core/services/organization/guards/organization.guard';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {BlindTestDetailsComponent} from '../admin/blindtest-details/blindtest-details.component';
import {BlindtestDetailsSetsComponent} from './blindtest-details-sets/blindtest-details-sets.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {BlindtestTableComponent} from './blindtest-table/blindtest-table.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
    BlindtestImportStatusDialogComponent
} from './import-blindtest/blindtest-import-status-dialog/blindtest-import-status-dialog.component';
import {BlindtestActiveWidgetComponent} from './blindtest-active-widget/blindtest-widget.component';
import {BlindtestCardListComponent} from './blindtest-card-list/blindtest-card-list.component';
import {FrogEventAuthModule} from '@frogconnexion/core-common/angular';
import { SearchScoresheetComponent } from './search-scoresheet/search-scoresheet.component';

const routes = [
    {path: 'org/:organization/dashboard', component: DashboardComponent, canActivate: [OrganizationGuard, AdminAuthGuard]},
    {
        path: 'admin/blindtest/details',
        component: BlindTestDetailsComponent,
    },
    {path: 'org/:organization/unauthorized', component: UnauthorizedComponent, canActivate: [OrganizationGuard]},
    {path: 'org/:organization/scoresheets/search', component: SearchScoresheetComponent, canActivate: [OrganizationGuard]},
    {path: 'org/:organization/blindtests/create', component: BlindtestEditPageComponent, canActivate: [OrganizationGuard, AdminAuthGuard]},
    {
        path: 'org/:organization/blindtests/import-blindtest',
        component: ImportBlindtestComponent,
        canActivate: [OrganizationGuard, AdminAuthGuard]
    },
    {path: 'org/:organization/blindtests/:id', component: BlindtestDetailsPageComponent, canActivate: [OrganizationGuard, AdminAuthGuard]},
    {
        path: 'org/:organization/blindtests/activate/:id',
        component: ActivateBlindtestComponent,
        canActivate: [OrganizationGuard, AdminAuthGuard]
    },
    {
        path: 'org/:organization/blindtests/edit/:id',
        component: BlindtestEditPageComponent,
        canActivate: [OrganizationGuard, AdminAuthGuard]
    },
];


@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        CoreModule,
        FrogEventAuthModule,
        DragDropModule,
        RouterModule.forChild(routes),
        MatTableModule,
        MatTooltipModule,
        MatSortModule,
        MatPaginatorModule,
        MatSlideToggleModule,
    ],
    declarations: [
        BlindTestWidgetComponent,
        BlindtestActiveWidgetComponent,
        ActivateBlindtestComponent,
        DashboardComponent,
        BlindtestDetailsPageComponent,
        BlindtestEditPageComponent,
        InputWidgetComponent,
        SectionEditWidgetComponent,
        ItemWidgetComponent,
        ItemSelectionDialogComponent,
        ListItemsWidgetComponent,
        SetEditWidgetComponent,
        ReviewWidgetComponent,
        SectionReviewWidgetComponent,
        ImportBlindtestComponent,
        UnauthorizedComponent,
        BlindtestDetailsSetsComponent,
        BlindtestTableComponent,
        BlindtestImportStatusDialogComponent,
        BlindtestCardListComponent,
        SearchScoresheetComponent
    ]
})
export class DashboardModule {
}
