<h3 mat-dialog-title i18n="@@itemSelectionDialog-title">Ajouter un Média</h3>
<mat-dialog-content class="mat-typography">
  <nav>
    <div class="search">
      <mat-form-field class="search-box">
        <input type="text" matInput placeholder="Chercher un élément" i18n-placeholder="@@itemSelectionDialog-searchItem" [(ngModel)]="searchString" (ngModelChange)="onFilterChanged()" >
      </mat-form-field>
      <div class="result-message">
        <span *ngIf="ss.totalCount !== -1">{{ss.totalCount}} <span i18n="@@itemSelectionDialog-itemsFound">éléments trouvés.</span></span>
        <span *ngIf="ss.totalCount === -1" i18n="@@itemSelectionDialog-currentlyResearching">Recherche en cours</span>
      </div>
    </div>
  </nav>
  <div class="content">
    <cdk-virtual-scroll-viewport itemSize="50" class="content-viewport">
      <div *cdkVirtualFor="let item of ds" class="content-item">
        <bt-item-widget [item]="item" [onItemSelected]="onItemSelected"></bt-item-widget>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close i18n="@@itemSelectionDialog-cancel">Annuler</button>
</mat-dialog-actions>
