<div>
  <!-- Section -->
  <div *ngFor="let section of set.sections; let j = index;">
    <app-section-edit-widget
      [index]="j"
      [section]="section"
      [onSectionStateChange]="onSectionStateChange"
      [onSectionDelete]="onSectionDelete">
    </app-section-edit-widget>
    <hr/>
  </div>
  <div class="blindset-actions">
    <button mat-raised-button color="primary" (click)="newSection()" i18n="@@setEditWidget-addNewSection">Ajouter une Section</button>
    &nbsp;
    <button mat-raised-button color="primary" matStepperNext [disabled]="set.sections.length === 0 || !isReady" i18n="@@setEditWidget-saveSet">Enregistrer
      le set
    </button>
  </div>
</div>
