import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BlindtestImportDescriptor, SetOfBlindtestImportDescriptor} from '@frogconnexion/blinding-common';
import {BlindtestService} from '../../../core/services/blindtest/blindtest.service';

@Component({
  selector: 'bt-review-widget',
  templateUrl: './review-widget.component.html',
  styleUrls: ['./review-widget.component.scss']
})
export class ReviewWidgetComponent implements OnInit {

  @Input()
  bt: BlindtestImportDescriptor;

  constructor(private _blindtestService: BlindtestService,
              private _router: Router) { }

  ngOnInit() {
  }

  getSongCount(set: SetOfBlindtestImportDescriptor): number {
    return set.sections.map(s => s.songs.length).reduce((a, b) => a + b, 0);
  }
}
