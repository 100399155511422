<div *ngIf="!isLogged; else alreadyLoggedIn" class="container">
  <mat-card class="login">
    <mat-card-content>
      <div fxLayout="column"
           fxLayoutAlign="space-between center"
           fxLayoutGap="20px">
        <mat-icon>lock</mat-icon>
        <p i18n="@@register-title">Demande de création de compte administrateur <b>Le Blindtest</b> pour <b>piloter</b> l'application.</p>
        <button mat-raised-button (click)="registerFb()" id="fb" i18n="@@register-withFb">Avec Facebook</button>
        <button mat-raised-button (click)="registerGoogle()" id="google" i18n="@@register-withGoogle">Avec Google</button>
        <button mat-raised-button routerLink="/register/by-email" id="email" i18n="@@register-withEmail">Avec ton Email</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #alreadyLoggedIn>
  <div class="container">
    <mat-card>
      <mat-card-content>
        <div fxLayout="column"
             fxLayoutAlign="space-between center"
             fxLayoutGap="20px">
          <div *ngIf="!isAdmin" style="text-align:center">
            <h2 i18n="@@register-title">Félicitations. La demande a bien été créée !</h2>
            <span i18n="@@register-accountNotValidYet">Ton compte n'est pas encore activé. </span>
            <br/>
            <span i18n="@@register-contactLoicToActivateYourAccount">Contacte Loïc pour qu'il active ton compte ! </span>
            <br/><br/>
            <button mat-raised-button (click)="refreshAuth()" i18n="@@register-reload">Recharger</button>
          </div>
          <div *ngIf="isAdmin && organizations.length > 0">
            <h2 i18n="@@register-accountAdminActivate">Félicitations ! Ton compte administrateur est actif.</h2>
            <br/>
            <span i18n="@@register-accessOrganization">Tu as accès aux organisations suivantes : </span>
            <br/>
            <h3 *ngFor="let o of organizations" style="text-align:center">
              <a [routerLink]="'/org/' + o + '/dashboard'">{{o.toUpperCase()}}</a>
            </h3>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
