import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Blindtest, Scoresheet} from '@frogconnexion/blinding-common';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {GameShortRecap} from './game-short-recap';
import {ScoreService} from '../../../core/services/score/score.service';

@Component({
  selector: 'app-search-scoresheet',
  templateUrl: './search-scoresheet.component.html',
  styleUrls: ['./search-scoresheet.component.scss']
})
export class SearchScoresheetComponent implements OnInit {

  dataSource: MatTableDataSource<GameShortRecap>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  gameList: GameShortRecap[];
  displayedColumns = ['name', 'description', 'actions'];
  constructor(private scoreService: ScoreService) { }

  ngOnInit(): void {
    this.scoreService.listAllScoresForOrg().subscribe(s => {
      this.gameList = s;
      this.updateDatasource();
    });
  }

  updateDatasource(): void {
    this.dataSource = new MatTableDataSource(this.gameList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  exportScoresheets(id: string, name: string) {
    this.scoreService.exportPastScoresheet(id).subscribe((scores) => {
      // tslint:disable-next-line:forin
      for (const i in scores) {
        this.downloadFile(scores[i], name);
      }
    });
  }

  downloadFile(data: Scoresheet, name: string) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data.payload));
    element.setAttribute('download', `Leblindtest.fr - ${name} - ${data.prefix} - set ${data.set + 1}.csv`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
