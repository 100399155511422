import {Component, Input, OnInit} from '@angular/core';
import {ServiceLeaderboardEntry} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-service-leaderboard-entry',
  templateUrl: './service-widget.component.html',
  styleUrls: ['./service-widget.component.scss']
})
export class ServiceWidgetComponent implements OnInit {

  @Input() entry: ServiceLeaderboardEntry;
  @Input() ranking: number;
  constructor() { }

  ngOnInit() {
  }

}
